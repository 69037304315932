import React from 'react'
import { Button, Box, Breadcrumb, Select, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
// import { CloseIcon } from '@chakra-ui/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-red.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function FixedSubHeaderTop({ selectedValues, handleSelectedValuesChange }) {

    const history = useHistory()

    const { selectedApplications } = useSelector(state => state.infoReducer)

    const removeValues = (removableValue) => {
        let updatedValues = selectedValues.filter(item => item.globalRecordId != removableValue)
        handleSelectedValuesChange(updatedValues)
    }

    const handleRoute = () => {
        history.push(`/application/plaid`)
    }

    return (<>
        <Flex justify={'space-between'} width={'100%'} align={'center'}>
            {selectedValues?.length <= 0 ? <Flex>
                <Text as={'span'} className='label-select'>
                    Select any 3 Applications to View Details
                </Text>
            </Flex> :
                <Flex>
                    <Text as={'span'} className='label-select'>
                        Selected Applications
                    </Text>
                    <Flex wrap={'wrap'}>
                    {selectedApplications && selectedApplications?.map((selectedValue, index) => (
                        <Flex key={index} align={'center'}>
                            <Text as={'span'} px={'5px'} className="vertical-divider-line">&#124;</Text>
                            <Text pe={'5px'} as={'span'} className='list-selected' >
                                {selectedValue?.globalRecordId}
                            </Text>
                            <CloseIcon cursor={'pointer'} width={'15px'} height={'15px'} onClick={() => removeValues(selectedValue?.globalRecordId)} />
                        </Flex>))}
                    </Flex>
                </Flex>}
            <Button className='view-detail-btn' isDisabled={selectedApplications?.length <= 0} onClick={() => handleRoute()}>
                View Details
            </Button>
        </Flex>
    </>
    )
}
