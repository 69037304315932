import { Text, Box, Flex, Icon, SimpleGrid, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import {
    MdNavigateBefore, MdNavigateNext, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdCheckCircle, MdCancel, MdOutlineError, MdPending, MdSearch,MdDoNotDisturbOn, MdCreate, MdDoNotDisturb} from "react-icons/md";

export const mapDuplicationResToTable = (response) => {
    const tableData = [];

    // Phone Number
    tableData.push({
      rule: "Phone Number",
      values: response?.app_level_frauch_check?.phone_number || 'NA',
      db_hit_count: response?.app_level_frauch_check?.phone_db_hit_count || 'NA',
      matching_instances: response?.app_level_frauch_check?.phone_match_business_ids.length > 0 && response?.app_level_frauch_check?.phone_match_business_ids,
      status: response?.app_level_frauch_check?.is_phone_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });

    // Email ID
    tableData.push({
      rule: "Email ID",
      values: response?.app_level_frauch_check.email || 'NA',
      db_hit_count: response?.app_level_frauch_check?.email_db_hit_count || 'NA',
      matching_instances: response?.app_level_frauch_check?.email_match_person_ids.length > 0 && response?.app_level_frauch_check?.email_match_person_ids,
      status: response?.app_level_frauch_check?.is_email_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });

    // SSN
    tableData.push({
      rule: "SSN",
      values: response?.app_level_frauch_check.ssn || 'NA',
      db_hit_count: response?.app_level_frauch_check?.ssn_db_hit_count || 'NA',
      matching_instances: response?.app_level_frauch_check?.ssn_match_person_ids.length > 0 && response?.app_level_frauch_check?.ssn_match_person_ids,
      status: response?.app_level_frauch_check?.is_ssn_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });

    // EIN
    tableData.push({
      rule: "EIN",
      values: response?.app_level_frauch_check.ein || 'NA',
      db_hit_count: response?.app_level_frauch_check?.ein_db_hit_count || 'NA',
      matching_instances: response?.app_level_frauch_check?.ein_match_business_ids.length > 0 && response?.app_level_frauch_check?.ein_match_business_ids,
      status: response?.app_level_frauch_check?.is_ein_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });

    let matchingInstances = [];
    let dbHitCount = [];

    response?.all_bank_accs?.forEach(acc => {
      let matches = response?.matched_bank_accs?.filter(match => match.acc_number === acc.acc_number);

      if (matches.length > 0) {
        matchingInstances.push(...matches);
      } else {
        matchingInstances.push('NA');
      }

      let hitCount = 1 + matches.length;
      dbHitCount.push(hitCount);
    });

    tableData.push({
      rule: "Bank Account",
      values: response?.all_bank_accs || 'NA',
      db_hit_count: dbHitCount || 'NA',
      matching_instances: matchingInstances,
      status: response?.is_bank_acc_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });

    return tableData;
  };


  export const mapThresholdResToTable = (response) => {
    const tableData = [];
    tableData.push({
      category: "Bank Ownership",
      rules: 'US Bank Account having Ownership Type <> ‘organization’ in Teller to be flagged' || 'NA',
      variable_selected: 'Ownership Type' || 'NA',
      expected_value: 'Organisation' || 'NA',
      actual_value: response?.acc_ownership_type_mismatch?.length > 0 ? response?.acc_ownership_type_mismatch : (response?.ownership_type_fraud_check_failed_reason || 'NA'),
      status: response?.is_ownership_type_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });
    tableData.push({
      category: "Bank Account",
      rules: 'If the highest revenue account, # of transactions < 10 in the last 30 days from today-1, then flag it' || 'NA',
      variable_selected: '# of Transactions in last 30 days' || 'NA',
      expected_value: '>=10' || 'NA',
      actual_value: response?.last_30_days_trans_count || 'NA',
      status: response?.is_no_of_trans_fraud_check ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });
    tableData.push({
      category: "Bank Account",
      rules: 'If the QoQ drop of Aggregate Average Daily Balance > 50%, flag it' || 'NA',
      variable_selected: (<><Text>QoQ ratio for ADB</Text><Text fontStyle={"italic"}>[(Latest-Previous)/Previous]</Text></>),
      expected_value: '>50%' || 'NA',
      actual_value: response?.daily_balance_drop_percentage !== null ? `${response?.daily_balance_drop_percentage?.toFixed(2)} %` : response?.avg_daily_balance_drop_failure_reason,
      status: response?.is_avg_daily_balance_fraud_check_pass ? (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
            Passed
          </Text>

        </>) : (
        <>
          <Text display={"flex"} alignItems={"center"}>
            <Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />
            Failed
          </Text>

        </>)
    });
    // tableData.push({
    //   category: "Socure",
    //   rules: 'Socure Sigma Score >.985 then Flag' || 'NA',
    //   variable_selected: 'Sigma Score' || 'NA',
    //   expected_value: '<= 0.985' || 'NA',
    //   actual_value: '99%',
    //   status: response.is_socure_check_pass ? "Passed" : "Failed"
    // });
    return tableData;
  }
