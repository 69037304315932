import axios from 'axios'
import NProgress from 'nprogress'
import { apiendpoints } from './apiendpoints';
import { localStorageKeys } from './localStorageKeys';
import { routesConstants } from './routeconstants';

const service = axios.create({
  baseURL: process.env.REACT_APP_API
})


function refreshToken() {
  try {
    return axios.post(`${apiendpoints.REFRESH}`, { refresh: getLocalRefreshToken()}).catch((err) => {
    });
  }
  catch (err) {
  }
}

function getLocalAccessToken() {
  const accessToken = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
  return accessToken;
}

function getLocalRefreshToken() {
  const refreshToken = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
  return refreshToken;
}

service.interceptors.request.use(
  config => {
    let { url } = config;
    NProgress.start()
    NProgress.configure({ showSpinner: false });
    const authToken = getLocalAccessToken()
    if (authToken) {
      config.headers['Authorization'] = 'Bearer ' + authToken
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    NProgress.done()
    return response.data
  },
  async (error) => {
    const originalConfig = error.config;
    NProgress.done()
    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const isRefresh = getLocalRefreshToken()
          if (isRefresh) {
            const rs = await refreshToken();
            const { access_token } = rs?.data?.content;
            localStorage.setItem(localStorageKeys.ACCESS_TOKEN, access_token);
            return service(originalConfig);
          }
        } catch (_error) {

        }
      }
      if (error.response.status === 500) {

      }
      else if (error.response.status === 403) {

      }
      else if (error.response.status === 404) {

      }
      else if (error.response.status === 400) {

      }
      else if (error.response.status === 409) {

      }
      else if (error.response.status === 401) {
        localStorage.clear()
        window.location.pathname= routesConstants.SIGNIN
      }
      return Promise.reject(error)
    }
  }
)

export default service