import React from 'react'
import { Flex, Table, Progress, Icon, Tbody, Td, Text, Th, Thead,Tr, useColorModeValue, Checkbox, Skeleton, SkeletonCircle, SkeletonText} from "@chakra-ui/react";
import Card from "components/card/Card";


export default function LazyLoader({ view }) {

    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const arr = [1, 2, 3, 4, 5, 6,]

    return (<>
        {
            view == "table" ? (
                <Card
                    direction='column'
                    w='100%'
                    px='0px'
                    overflowX={{ sm: "scroll", lg: "auto" }}>
                    <Table>
                        <Thead>
                            <Tr>
                                {arr.map(() => {
                                    return <Th px='40px' borderColor={borderColor}>
                                        <Flex justify='space-between' align='center'>
                                        <Skeleton height='20px' width='100%' />
                                        </Flex>
                                    </Th>
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                arr.map(() => {
                                    return (
                                        <Tr>
                                            {
                                                arr.map(() => {
                                                    return (
                                                        <Td  py='8px' px='40px'>
                                                            <Skeleton height='20px' width='100%' />
                                                        </Td>
                                                    )
                                                })
                                            }

                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </Card>
            ) : view == "lazy-file-comment" ? (<>
                    <Flex direction={'column'} p={4} gap={'20px'}>
                        <Flex w={'100%'} gap={'20px'}  alignItems={'center'}>
                            <Skeleton height='40px' w={'40px'} />
                            <Skeleton height='20px' w={'50%'} />
                        </Flex>
                        <Skeleton height='40px' w={'100%'} />

                    </Flex>

                    </>) : ("")
        }
    </>)
}
