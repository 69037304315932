export const columnsDataComplex = [
  {
    Header: "BUSINESS ID",
    accessor: "business_id",
    isShow: true
  },
  {
    Header: "CUSTOMER NAME",
    accessor: "customer_name",
    isShow: true
  },
  {
    Header: "LEGAL NAME",
    accessor: "legal_name",
    isShow: true
  },
  {
    Header: "RELATIONSHIP TYPE",
    accessor: "relationship_type",
    isShow: true
  },
  {
    Header: "STREET ADDRESS 1",
    accessor: "street_address_1",
    isShow: true
  },
  {
    Header: "STREET ADDRESS 2",
    accessor: "street_address_2",
    isShow: true
  },
  {
    Header: "CITY",
    accessor: "city",
    isShow: true
  },
  {
    Header: "STATE/PROVINCE",
    accessor: "state_province",
    isShow: true
  },
  {
    Header: "COUNTRY",
    accessor: "country",
    isShow: true
  },
  {
    Header: "BUSINESS TAX ID",
    accessor: "business_tax_id",
    isShow: true
  },
  {
    Header: "ESTABLISHED DATE",
    accessor: "established_date",
    isShow: true
  },
  {
    Header: "DECLARED REVENUE",
    accessor: "declared_revenue",
    isShow: true
  },
  {
    Header: "RECORD ID/IDs",
    accessor: "applications",
    isShow: true
  },
  // {
  //   Header: "DETAILS",
  //   accessor: "details_icons",
  //   isShow: true
  // },
];
