import React from 'react';
import Select from 'react-select';

export default function  CustomSelect (props){
  const {
    options,
    value,
    onChange,
    placeholder,
    isSearchable,
    isClearable,
    customStyles,
    isMulti,
    isDisabled
  } = props;

  return (
    <Select
      options={options}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={isClearable}
      styles={customStyles}
      isDisabled={isDisabled}
      // menuIsOpen={true}
    />
  );
};


