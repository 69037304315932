import {Flex,Table,Icon,Tbody,Td,Text,Th,Thead,Tr,useColorModeValue,Checkbox,} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { TriangleUpIcon } from '@chakra-ui/icons'
import {useGlobalFilter,usePagination, useSortBy,useTable,} from "react-table";
import Card from "components/card/Card";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPending, MdDoNotDisturbOn, MdSearch, MdDoNotDisturb } from "react-icons/md";
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { useSelector, useDispatch, } from 'react-redux';

export default function MainTable(props) {

  const { selectedDecisionIds, columnsData, tableData, handleSelectedValuesChange,setSelectedDecisionIds, setSelectedValues, selectedValues, handleSortType } = props;

  const [isHovered, setIsHovered] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);

  const data = useMemo(() => tableData, [tableData]);

  const dispatch = useDispatch()

  const { selectedDecisions } = useSelector(state => state.infoReducer)





  const handleCheckboxChange = (e, value, rowData) => {
    if (!rowData || typeof rowData.record_id === 'undefined') {
      return;
    }

    const isChecked = e.target.checked;
      let prevSelectedValues = [...selectedDecisions]

      let updatedSelectedValues;

      if (isChecked) {
        const existingEntryIndex = prevSelectedValues.findIndex(
          (item) => item.application_id === rowData.record_id
        );

        if (existingEntryIndex !== -1) {
          updatedSelectedValues = prevSelectedValues.map((item, index) =>
            index === existingEntryIndex
              ? { ...item, decision_ids: [...item.decision_ids, value] }
              : item
          );
        } else {
          updatedSelectedValues = [
            ...prevSelectedValues,
            {
              application_id: rowData.record_id,
              decision_ids: [value],
              business_name: rowData.business_name,
              originalApp_id: rowData.application_id
            },
          ];
        }
      } else {
        updatedSelectedValues = prevSelectedValues.map((item) => {
          if (item.application_id === rowData.record_id) {
            const updatedDecisionIds = item.decision_ids.filter(
              (decisionId) => decisionId !== value
            );
            return {
              ...item,
              decision_ids: updatedDecisionIds,
            };
          }
          return item;
        });
        updatedSelectedValues = updatedSelectedValues.filter(
          (item) => item.decision_ids.length > 0
        );
      }

      if (updatedSelectedValues.length > 3) {
        return prevSelectedValues;
      }
      handleSelectedValuesChange(updatedSelectedValues);
      return updatedSelectedValues;
  };


  const tableInstance = useTable({columns,data},useGlobalFilter,useSortBy, usePagination);

  const {getTableProps, getTableBodyProps, headerGroups, page,prepareRow, initialState} = tableInstance;
  initialState.pageSize = 50;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    return () => {
      setSelectedDecisionIds([]);
    };
  }, []);


  return (<>
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflowX={{ sm: "scroll", lg: "auto" }}>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  className="table-header"
                  key={index}
                  borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <Flex className="table-header-text">{column.render("Header")}
                      {["","TRIGGERED BY"].includes(column.Header) ? null :
                        <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                          <Flex direction={'column'} pl={'0px'}>
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                          </Flex>
                        </span>}
                    </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const isLastCell = index === row.cells.length - 1;
                  if (cell.column.Header === "Decision ID") {
                    data = (
                      <Flex align='center'>
                        <Text>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "") {
                    data = (
                      <Checkbox
                        isChecked={selectedDecisionIds?.includes(cell.value) ? true : false}
                        colorScheme='teal'
                        me='10px'
                        onChange={(e) => handleCheckboxChange(e, cell.value, row.original)}
                        isDisabled={selectedDecisionIds.length >= 1 && !selectedDecisionIds.includes(cell.value)}
                      />
                    );
                  }
                  else if (cell.column.Header === "TRIGGERED BY") {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "Application ID") {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === "Person Name") {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === "Business Name") {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === "Last Execution Date Time") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY,  h:mm A")}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === "Decision Status") {
                    data = (
                      <Flex align='center'>
                        {cell.value ? (
                          <>
                            <Icon
                              w='24px'
                              h='24px'
                              me='5px'
                              p={cell.value === "Manual Review" ? '2px' : ""}
                              m={cell.value === "Manual Review"?'2px 7px 2px 2px' : ""}
                              width={cell.value === "Manual Review"? "20px": null}
                              height={cell.value === "Manual Review"? "20px": null}
                              bg={cell.value === "Manual Review" ? 'rgba(0, 162, 173, 1)' : null}
                              borderRadius={cell.value === "Manual Review" ? '50%' : null}
                              color={
                                cell.value === "Approved"
                                  ? "green.500"
                                  : cell.value === "Declined"
                                    ? "red.500"
                                    : cell.value === "In Progress"
                                      ? "orange.500"
                                      : cell.value === "None"
                                        ? "rgba(0, 0, 0, 0.2)"
                                        : cell.value === "Pending"
                                          ? "rgba(195, 183, 229, 1)"
                                          : cell.value === "Manual Review"
                                            ? "rgba(255,255,255)"
                                            : cell.value === "Failed"
                                            ? "rgba(224, 79, 79, 1)"
                                            : null
                              }
                              as={
                                cell.value === "Approved"
                                  ? MdCheckCircle
                                  : cell.value === "Declined"
                                    ? MdCancel
                                    : cell.value === "In Progress"
                                      ? MdOutlineError
                                      : cell.value === "Pending"
                                        ? MdPending
                                        : cell.value === "Manual Review"
                                          ? MdSearch
                                          : cell.value === "Failed"
                                          ? MdDoNotDisturb
                                          : cell.value === "None"
                                            ? MdDoNotDisturbOn
                                            : null
                              }
                            />
                            <Text  >
                              {cell.value}
                            </Text>
                          </>
                        ) : (
                          <Text  >
                            -
                          </Text>
                        )}
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text  >
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      className="table-cell"
                      maxH='30px !important'
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderRight={isLastCell ? "transparent" : "1px solid"}>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  </>);
}
