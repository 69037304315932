import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Box, Button,Checkbox,Flex,FormControl,FormLabel,Heading, Icon,Input,InputGroup,InputRightElement,Text, useColorModeValue,InputLeftElement,} from "@chakra-ui/react";
import { EmailIcon, LockIcon } from '@chakra-ui/icons'
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/sign-in-illustration.svg";
import { ReactComponent as RightArrowCircle } from "assets/icons/arrow-circle-right.svg"
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Footer from "components/footer/FooterAdmin";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch } from "react-redux";
import { mapUserInfo } from "redux/actions/userAction";

function SignIn() {

  const textColor = useColorModeValue("navy.700", "white");

  const textColorSecondary = "gray.400";

  const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.300" });

  const signinBg = useColorModeValue("white", "whiteAlpha.200")

  const history = useHistory()

  const [show, setShow] = React.useState(false);

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [rememberMe, setRememberMe] = useState(false);

  const [error, setError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleClick = () => setShow(!show);

  const dispatch = useDispatch()

  const handleLogin = () => {
    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMsg("Invalid email address");
      return;
    }
    let obj = { email: email, password: password};
    service.post(`${apiendpoints.LOGIN}`, obj).then((res) => {
      const { content: { access_token, profile, refresh_token } } = res
      localStorage.setItem(localStorageKeys.ACCESS_TOKEN, access_token)
      if (rememberMe) {
        localStorage.setItem(localStorageKeys.REFRESH_TOKEN, refresh_token);
      }
      history.push("/applications")
      dispatch(mapUserInfo(profile))
    }).catch((error) => {
      setError(true)
      setErrorMsg(error?.response?.data?.message)
    })
  }

  const handleInputBlur = () => {
    if (email.trim() === '' && password.trim() === '') {
      setError(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex direction={'column'}>
        <Flex  maxW={{ base: "100%", md: "max-content" }} bg={signinBg}  p='30px' w='100%' mx={{ base: "auto", lg: "0px" }} me='auto' alignItems='start' justifyContent='center' borderRadius='1.5rem' mb={{ base: "30px", md: "30px" }}  mt={{ base: "40px", md: "5vh" }} flexDirection='column'>
          <Box me='auto'> <Heading color={textColor} fontSize='32px' mb='24px'> Sign In </Heading></Box>
          <Flex zIndex='2'  direction='column' w={{ base: "100%", md: "420px" }} maxW='100%' background='transparent'  borderRadius='15px' mx={{ base: "auto", lg: "unset" }} me='auto' mb={{ base: "20px", md: "auto" }}>
            <FormControl>
              <InputGroup mb='24px'>
                <InputLeftElement justifyContent='flex-start' pointerEvents='none'>
                  <EmailIcon color={error? "red" :'gray.300' } />
                </InputLeftElement>
                <Input _focus={{ boxShadow: "none", borderColor: "black" }} fontSize='sm' pl='1.5rem' isRequired={true} type='email' variant='flushed' placeholder='Your Email..'
                  value={email}
                  onBlur={() => handleInputBlur()}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <InputGroup mb='24px'>
                <InputLeftElement justifyContent='flex-start' pointerEvents='none'>
                  <LockIcon color={error? "red" :'gray.300' }  />
                </InputLeftElement>
                <Input _focus={{ boxShadow: "none", borderColor: "black" }} fontSize='sm' pl='1.5rem' isRequired={true} type={show ? "text" : "password"} variant='flushed' placeholder='Your Password..'
                  value={password}
                  onBlur={() => handleInputBlur()}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <InputRightElement justifyContent='flex-end' display='flex' alignItems='center' >
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex>
            {error && <Text  mb='24px' color={'red'} fontSize='sm'>{errorMsg}</Text> }
            </Flex>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox id='remember-login'  colorScheme='teal' me='10px' isChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                  <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' color={textColor}  fontSize='sm'> Remember Me</FormLabel>
                </FormControl>
              </Flex>
              <Flex gap="15px" align='center' mt={'25px'}>
                <Button gap={'15px'} className="ecl-border-ui " rightIcon={<RightArrowCircle width={'22px'} height={'22px'}></RightArrowCircle>}
                  fontSize='sm'
                  variant='ghost'
                  fontWeight='500'
                  _hover={googleHover}
                  onClick={() => handleLogin()}
                >
                  Sign In
                </Button>
                <NavLink to='/auth/forgot-password'>
                  <Text color={'black'} fontSize='sm' fontWeight='500'> Forgot password?</Text>
                </NavLink>
              </Flex>
            </FormControl>
          </Flex>
        </Flex>
        {/* <Box bg={'#F6F6F6'} p={'0px 0px 20px 12px'}><Footer /></Box> */}
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
