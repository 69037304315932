import React from "react";
import {  Image, Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { routesConstants } from 'utils/routeconstants'
import { HSeparator } from "components/separator/Separator";
import MainBrandLogo from "assets/ECL-brand-logo.svg"
import { useDispatch } from "react-redux";
import { mapSelectedDecisions, mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";

export function SidebarBrand() {
  const dispatch = useDispatch();

  const handleNavLinkClick = () => {
    dispatch(mapSelectedApplications([]));
    dispatch(mapSelectedDecisions([]));
    localStorage.setItem(localStorageKeys.SELECTED_APPLICATIONS, JSON.stringify([]));
    localStorage.setItem(localStorageKeys.SELECTED_DECISIONS, JSON.stringify([]));
  };


  return (<>
    <Box p={2}  onClick={handleNavLinkClick}>
      <NavLink to={routesConstants.APPLICATIONS}><Image src={MainBrandLogo} alt='Ecl Brand Logo' /></NavLink>
    </Box>
    <HSeparator my='20px' />
  </>
  );
}

export default SidebarBrand;
