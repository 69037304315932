export const apiendpoints = {
    LOGIN : '/decisionengines/users/login',
    GOOGLE : '/decisionengines/users/google',
    REFRESH : '/decisionengines/users/token/refresh',
    GET_PROFILE : '/decisionengines/users/profile',

    GET_UNIQUE_TRANSACTION_CATEGORIES:'/decisionengines/applications/transactions/categories',
    GET_UNIQUE_BUSINESS_COUNTRIES:'/decisionengines/businesses/countries',
    GET_UNIQUE_LEGAL_NAMES:'/decisionengines/persons/legal-names',
    GET_UNIQUE_ID_TYPES:'/decisionengines/persons/id-types',
    GET_LIST_DECISION_IDS:'/decisionengines/applications/ECL-A-001-202310037123/decisions',
    GET_UNIQUE_BUSINESS_RELATIONSHIP_TYPES:'/decisionengines/businesses/relationships',
    GET_UNIQUE_ID_COUNTRIES:'/decisionengines/persons/id-countries',
    GET_UNIQUE_ID_NUMBERS:'/decisionengines/persons/id-numbers',

    APPLICATIONS :'/decisionengines/applications',
    PUT_APPLICATIONS_TRANSACTION_CATEGORY :'/decisionengines/applications/transactions/custom-categories',

    DECISIONS : '/decisionengines/decisions',
    DECISIONS_SUMMARY : '/decisionengines/decisions',
    DECISIONS_TRANSACTION : '/decisionengines/decisions',
    FINANCIAL_RANGE : '/decisionengines/decisions/financial-ranges',

    BUSINESS : '/decisionengines/business',
    PERSON : '/decisionengines/persons',


}