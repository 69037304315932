import React from "react";
import { Image, Text, Flex } from "@chakra-ui/react";
import ErrorState from 'assets/img/states/error.svg'

export default function Maintenance() {

    return (<>
        <Flex direction={'column'} alignItems={'center'} justify={'center'} bg={'white'} height={'calc(100vh - 100px)'} borderRadius={'15px'}>
            <Image src={ErrorState} w={'150px'} h={'100px'} />
            <Text fontSize={'1.5rem'} as={'strong'}>Nothing Went Wrong</Text>
            <Text fontSize={'1.5rem'}>This data source has not been integrated with the Risk Decision Engine, yet!</Text>
        </Flex>
    </>);
}
