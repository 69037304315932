import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Stack, Text, useColorModeValue, Divider } from "@chakra-ui/react";

export function SidebarLinks(props) {

  let location = useLocation();

  let activeColor = useColorModeValue(" rgba(0, 0, 0, 0.9)", "white");

  let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");

  let activeIcon = useColorModeValue("cbrand.500", "white");

  let textColor = useColorModeValue(" rgba(0, 0, 0, 0.4)", "white");

  let brandColor = useColorModeValue("cbrand.500", "brand.400");

  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const pageKey = location.pathname;

  const initialSubMenuStates = JSON.parse(localStorage.getItem(`subMenuStates-${pageKey}`)) || {
    Accounting: false,
  };
  const [subMenuStates, setSubMenuStates] = useState(initialSubMenuStates);

  useEffect(() => {
    localStorage.setItem(`subMenuStates-${pageKey}`, JSON.stringify(subMenuStates));
  }, [subMenuStates, pageKey]);

  const createLinks = (routes) => {
    let routeName;
    const handleToggle = (index) => {
      routeName = props.routes[index].name;
      if (routeName === "Accounting") {
        setSubMenuStates((prevStates) => ({
          ...prevStates,
          [routeName]: !prevStates[routeName],
        }));
      } else {
        setSubMenuStates((prevStates) => ({
          ...prevStates,
          Accounting: false,
        }));
      }
    };
    return routes.map((route, index) => {
      let showSubMenu = true;

      if (route.name === "Accounting") {
        showSubMenu = subMenuStates["Accounting"];
      }

      if (route.category) {
        return (
          <>
            <Text fontSize={"md"} color={activeColor} fontWeight='bold' mx='auto' ps={{ sm: "10px", xl: "16px" }} pt='18px' pb='12px' key={index}> {route.name} </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (route.layout === "/decision" || route.layout === "/application" || route.layout === "/auth" || route.layout === "" || route.layout === "/rtl") {
        return (
          <NavLink key={index} to={(route.name === "Accounting") ? "/application/accounting/balance-sheet" : (route.name === "Decision Summary") ? "/decision/summary" : route.layout + route.path} state={route.state}>
            {route.icon ? (
              <Box>
                <HStack onClick={() => handleToggle(index)} spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='0px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box color={activeRoute(route.layout + route.path) ? activeIcon : textColor} me='18px'> {route.icon}</Box>
                    <Text w="max-content" me='auto' color={activeRoute(route.layout + route.path) ? activeColor : textColor} fontWeight={"bold"}> {route.name} </Text>
                  </Flex>
                  <Box h='30px' w='4px' bg={activeRoute(route.layout + route.path) ? brandColor : "transparent"} borderRadius='5px' />
                </HStack>
                {showSubMenu && route?.items && route?.items?.length > 0 ? (
                  <Stack pl={"38px"} mt={1} mb={4} spacing={1}>
                    {route?.items.map((submenu, subIndex) => (
                      <NavLink key={subIndex} to={route.layout + submenu.path}>
                        <Flex borderBottom={activeRoute(route.layout + submenu.path) ? `1px solid rgba(0, 162, 173, 1)` : `1px solid ${textColor}`} alignItems="center" mr="30px">
                          <Box color={activeRoute(route.layout + submenu.path) ? activeIcon : textColor} me='5px'> {submenu.icon}</Box>
                          <Text me="auto" py={"8px"} fontSize={'16px'} color={activeRoute(route.layout + submenu.path) ? activeIcon : textColor} fontWeight={activeRoute(route.layout + submenu.path) ? "700" : "600"} > {submenu.name}
                          </Text>
                        </Flex>
                      </NavLink>
                    ))}
                  </Stack>
                ) : null}

              </Box>
            ) : (
              <Box>
                <HStack spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} py='5px' ps='0px'>
                  <Text me='auto' color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor} fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}> {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  return createLinks(routes);
}
export default SidebarLinks;
