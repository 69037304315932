import React, { useEffect, useState } from 'react'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, FormControl, FormLabel, Input, HStack, Box, useColorModeValue, Text, Flex, Icon,
} from '@chakra-ui/react'
import CustomSelect from 'components/customSelect/CustomSelect'
import { MdCheckCircleOutline, MdAddCircle } from "react-icons/md";
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles'

export default function CustomRevenuePopup({
    setInputValueIndicator,
    calculation,
    setSelectedCustomRevenuePopupOptionsIndicator,
    customRevenueEditedValue,
    minPercentage,
    maxPercentage,
    setSelectedCustomRevenuePopupOptions,
    inputValue,
    setInputValue,
    updatedValue,
    setUpdatedValue,
    processValue,
    isOpen,
    onClose,
    customRevenuePopupOptions,
    selectedCustomRevenuePopupOptions,
    handleCustomRevenuePopupOption,
    setCustomRevenueEditedValue,
    customRevenueDetails,
    setCustomRevenueDetails
}) {


    const initialRef = React.useRef(null)

    const finalRef = React.useRef(null)

    const textColor = useColorModeValue("#00A2AD", "#00A2AD")

    const [disabled, setDisabled] = useState(true)


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        if (event.target.value !== "") {
            document.getElementById('tick-icon').style.color = '#00A2AD';
            document.getElementById('tick-icon').style.cursor = 'pointer';
            document.getElementById('tick-icon').style.pointerEvents = 'auto';

        } else {
            setUpdatedValue('')
            document.getElementById('tick-icon').style.color = 'rgba(0, 0, 0, 0.3)';
            document.getElementById('tick-icon').style.cursor = 'not-allowed';
            document.getElementById('tick-icon').style.pointerEvents = 'none';
        }

    }

    const onCancel = () => {
        setSelectedCustomRevenuePopupOptions({ label: 'Select', value: 'custom' })
        setInputValue('');
        onClose();

    }

    const onSubmit = () => {
        if (inputValue !== "") {
            setCustomRevenueEditedValue(updatedValue);
            // setSelectedCustomRevenuePopupOptions({label: 'Select', value: 'custom'})
            setSelectedCustomRevenuePopupOptionsIndicator(selectedCustomRevenuePopupOptions.label)
            setInputValueIndicator(inputValue)
            // setInputValue('');
            onClose();
            setCustomRevenueDetails({
                revenueVariable : selectedCustomRevenuePopupOptions?.label.substring(0, selectedCustomRevenuePopupOptions?.label.lastIndexOf(" |")).trim() || null,
                revenuePercent : inputValue,
                totalRevenueEstimate : updatedValue,
                revenueCurrentValue : selectedCustomRevenuePopupOptions?.value
            })
        }
        else {
            setDisabled(true)
        }
    }

    const mapValues = () => {
        setSelectedCustomRevenuePopupOptions( customRevenueDetails?.revenueVariable 
            ? {label: customRevenueDetails?.revenueVariable + " " + "|" + " " + processValue(customRevenueDetails?.revenueCurrentValue), value : customRevenueDetails?.revenueCurrentValue} 
            : {label: 'Select', value : 'custom'}
        )
        setUpdatedValue(customRevenueDetails?.totalRevenueEstimate || '')

        setInputValue(customRevenueDetails?.revenuePercent)
    }

    useEffect(() => {
        if (updatedValue) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [updatedValue])

    useEffect(() => {
        if((customRevenueDetails?.revenueVariable + " " + "|" + " " + processValue(customRevenueDetails?.revenueCurrentValue)) != selectedCustomRevenuePopupOptions?.label){
            setUpdatedValue('')
        }
      }, [selectedCustomRevenuePopupOptions])


    useEffect(() => {
        if(customRevenueDetails?.revenueCurrentValue){
            mapValues()
        }
    },[customRevenueDetails,isOpen])

    return (<>
        <Modal closeOnOverlayClick={false} initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'xl'} >
            <ModalOverlay />
            <ModalContent m={'auto'}>
                <ModalHeader color={textColor}>Set a custom revenue estimate value</ModalHeader>
                <ModalBody pb={6}>
                    <Box>
                        <CustomSelect
                            options={customRevenuePopupOptions}
                            value={selectedCustomRevenuePopupOptions}
                            onChange={handleCustomRevenuePopupOption}
                            placeholder={"Select an option"}
                            isSearchable={false}
                            isClearable={false}
                            customStyles={customStyleSelected}
                        />
                    </Box>
                    <Box mt={5} border={'1px solid rgba(0, 0, 0, 0.07)'}>
                        <Flex>
                            <Flex direction={"column"} w={'35%'}>
                                <Box flex={3} p={4} borderBottom={'1px solid rgba(0, 0, 0, 0.07)'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}>Current Value</Box>
                                <Box flex={3} p={4} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}>Updated Value</Box>
                            </Flex>
                            <Flex direction={"column"} w={'65%'} >

                                <Flex borderBottom={'1px solid rgba(0, 0, 0, 0.07)'} justifyContent={"space-between"} alignItems={"center"}>

                                    <Box color={selectedCustomRevenuePopupOptions?.value === 'custom' ? 'rgba(0, 0, 0, 0.3)' : ''} position={'relative'} flex={3} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}>
                                        {selectedCustomRevenuePopupOptions?.value === 'custom' ? 'Unselected' : ('$ ' + Math.round(parseInt(selectedCustomRevenuePopupOptions?.value)).toLocaleString("en-US"))}
                                        <Icon top={'30%'} right={'-10.5px'} bg={'#ffffff'} position={'absolute'} as={MdAddCircle} h={'20px'} w={'20px'} color={'rgba(204, 204, 204, 1)'} />
                                    </Box>

                                    <Box flex={3} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}>
                                        <FormControl isRequired w={'100%'} display='flex' gap={2} alignItems={'center'}>
                                            <Input isDisabled={selectedCustomRevenuePopupOptions?.value === 'custom' ? true : false} min={'0'} max={'25'} w={'75%'} height={'32px'} type='number' name='number' className='ecl-border-ui' value={inputValue} placeholder='%' onChange={handleInputChange} />
                                            <FormLabel htmlFor='number' m={0} color={textColor}>%</FormLabel>
                                        </FormControl>
                                    </Box>

                                    <Flex justifyContent={"center"} alignItems={"center"} flex={1} p={3}><Icon id='tick-icon' onClick={calculation} as={MdCheckCircleOutline} h={'25px'} w={'25px'}
                                    // color={selectedCustomRevenuePopupOptions?.value === 'custom' || inputValue === ""? 'rgba(0, 0, 0, 0.3)' : '#00A2AD' }

                                    color={
                                        selectedCustomRevenuePopupOptions?.value === 'custom' ||  inputValue === '' ?
                                        'rgba(0, 0, 0, 0.3)' :
                                        (updatedValue && inputValue ?
                                            'rgba(0, 0, 0, 0.3)' :
                                            '#00A2AD')
                                }

                                    />
                                    </Flex>


                                </Flex>

                                <Flex>
                                    <Box flex={2} p={3}>{updatedValue ? '$ ' + Math.round(updatedValue).toLocaleString("en-US") : ''}</Box>
                                    <Box flex={2} p={3} ></Box>
                                    <Flex flex={1} p={3}></Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Box>
                    <Flex alignItems={'center'} justifyContent={'flex-end'} mt={5} gap={3}>
                        <Button px={10} className='red-border-btn' onClick={onCancel}>Cancel</Button>
                        <Button px={10} className='green-btn' type='submit' id='submit' isDisabled={disabled} onClick={onSubmit} >Submit</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}