import React, { useState} from "react";
import { IconButton, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

export function SearchBar(props) {

  const { variant, background, children, placeholder, search, setSearch, borderRadius, ...rest } = props;

  const [searchValue, setSearchValue] = useState('');

  const handleSearch = () => {
    setSearch(searchValue.trim());
  };

  const handleSearchClick = () => {
    setSearch(searchValue.trim());
  };
  const handleCloseSearch = () => {
    setSearchValue("");
    setSearch("");
  };

  return (
    <InputGroup w={'100%'} {...rest} className='ecl-border-ui'>
      <InputRightElement
        children={
          <IconButton bg='inherit' borderRadius='inherit' _hover='none' _active={{ bg: "inherit", transform: "none", borderColor: "transparent" }} _focus={{ boxShadow: "none", }}
            icon={search?.length <= 0 ? <SearchIcon onClick={handleSearchClick} color={'#00A2AD'} w='15px' h='15px' /> : <CloseIcon onClick={handleCloseSearch} color={'#E04F4F'} w='12px' h='12px' />}></IconButton>
        }
      />
      <Input
        variant='outline'
        fontSize='13px'
        fontFamily={`'Urbanist', sans-serif`}
        bg={background ? background : 'rgba(255, 255, 255, 1)'}
        color={'rgba(0, 0, 0, 1)'}
        fontWeight='600'
        _placeholder={{ color: "rgba(0, 0, 0, 0.40);", fontSize: "13px" }}
        borderRadius={borderRadius ? borderRadius : "0.4375rem"}
        placeholder={placeholder ? placeholder : "Search..."}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        _focus={{ boxShadow:'0 0 0 1px #00a2ad' }}
        _hover={{ border: '1px solid #00a2ad', }}
        onKeyPress={(e) => {  if (e.key === "Enter") { handleSearch()}}}
      />
    </InputGroup>
  );
}
