export const TestData = {

  getTreeTableNodesData(){
    
      return( [
        {
          "message": "Balance sheet Data fetched successfully.",
          "success": true,
          "content": [
            {
              "key": 1,
              "data": {
                "name": "Assets",
                "values": [
                  {
                    "date": "2024-01",
                    "value": 522350.09
                  },
                  {
                    "date": "2023-12",
                    "value": 485957.2
                  },
                  {
                    "date": "2023-11",
                    "value": 492587.74
                  },
                  {
                    "date": "2023-10",
                    "value": 495789.69
                  },
                  {
                    "date": "2023-09",
                    "value": 503606.48
                  },
                  {
                    "date": "2023-08",
                    "value": 392206.73
                  },
                  {
                    "date": "2023-07",
                    "value": 362084.19
                  },
                  {
                    "date": "2023-06",
                    "value": 390048.31
                  },
                  {
                    "date": "2023-05",
                    "value": 378060.14
                  },
                  {
                    "date": "2023-04",
                    "value": 371830.28
                  },
                  {
                    "date": "2023-03",
                    "value": 382234.99
                  },
                  {
                    "date": "2023-02",
                    "value": 402479.11
                  },
                  {
                    "date": "2023-01",
                    "value": 394909.76
                  },
                  {
                    "date": "2022-12",
                    "value": 582599.43
                  },
                  {
                    "date": "2022-11",
                    "value": 518563.69
                  },
                  {
                    "date": "2022-10",
                    "value": 370024.69
                  },
                  {
                    "date": "2022-09",
                    "value": 375807.47
                  },
                  {
                    "date": "2022-08",
                    "value": 377451.67
                  },
                  {
                    "date": "2022-07",
                    "value": 384943.48
                  },
                  {
                    "date": "2022-06",
                    "value": 438407.85
                  },
                  {
                    "date": "2022-05",
                    "value": 410310.36
                  },
                  {
                    "date": "2022-04",
                    "value": 459866.54
                  },
                  {
                    "date": "2022-03",
                    "value": 486449.23
                  },
                  {
                    "date": "2022-02",
                    "value": 562734.15
                  }
                ]
              },
              "children": [
                {
                  "key": 10,
                  "data": {
                    "name": "Accounts Receivable",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 58344
                      },
                      {
                        "date": "2023-12",
                        "value": 19396
                      },
                      {
                        "date": "2023-11",
                        "value": 57148
                      },
                      {
                        "date": "2023-10",
                        "value": 63648
                      },
                      {
                        "date": "2023-09",
                        "value": 46600
                      },
                      {
                        "date": "2023-08",
                        "value": 24800
                      },
                      {
                        "date": "2023-07",
                        "value": 19500
                      },
                      {
                        "date": "2023-06",
                        "value": 44200
                      },
                      {
                        "date": "2023-05",
                        "value": 41600
                      },
                      {
                        "date": "2023-04",
                        "value": 40300
                      },
                      {
                        "date": "2023-03",
                        "value": 27300
                      },
                      {
                        "date": "2023-02",
                        "value": 18600
                      },
                      {
                        "date": "2023-01",
                        "value": 9710
                      },
                      {
                        "date": "2022-12",
                        "value": 10600
                      },
                      {
                        "date": "2022-11",
                        "value": 165368
                      },
                      {
                        "date": "2022-10",
                        "value": 15368
                      },
                      {
                        "date": "2022-09",
                        "value": 14568
                      },
                      {
                        "date": "2022-08",
                        "value": 21050
                      },
                      {
                        "date": "2022-07",
                        "value": 9650
                      },
                      {
                        "date": "2022-06",
                        "value": 9182
                      },
                      {
                        "date": "2022-05",
                        "value": 11058
                      },
                      {
                        "date": "2022-04",
                        "value": 11181
                      },
                      {
                        "date": "2022-03",
                        "value": 12825
                      },
                      {
                        "date": "2022-02",
                        "value": 24825
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 11,
                      "data": {
                        "name": "Accounts Receivable",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 58344
                          },
                          {
                            "date": "2023-12",
                            "value": 19396
                          },
                          {
                            "date": "2023-11",
                            "value": 57148
                          },
                          {
                            "date": "2023-10",
                            "value": 63648
                          },
                          {
                            "date": "2023-09",
                            "value": 46600
                          },
                          {
                            "date": "2023-08",
                            "value": 24800
                          },
                          {
                            "date": "2023-07",
                            "value": 19500
                          },
                          {
                            "date": "2023-06",
                            "value": 44200
                          },
                          {
                            "date": "2023-05",
                            "value": 41600
                          },
                          {
                            "date": "2023-04",
                            "value": 40300
                          },
                          {
                            "date": "2023-03",
                            "value": 27300
                          },
                          {
                            "date": "2023-02",
                            "value": 18600
                          },
                          {
                            "date": "2023-01",
                            "value": 9710
                          },
                          {
                            "date": "2022-12",
                            "value": 10600
                          },
                          {
                            "date": "2022-11",
                            "value": 165368
                          },
                          {
                            "date": "2022-10",
                            "value": 15368
                          },
                          {
                            "date": "2022-09",
                            "value": 14568
                          },
                          {
                            "date": "2022-08",
                            "value": 21050
                          },
                          {
                            "date": "2022-07",
                            "value": 9650
                          },
                          {
                            "date": "2022-06",
                            "value": 9182
                          },
                          {
                            "date": "2022-05",
                            "value": 11058
                          },
                          {
                            "date": "2022-04",
                            "value": 11181
                          },
                          {
                            "date": "2022-03",
                            "value": 12825
                          },
                          {
                            "date": "2022-02",
                            "value": 24825
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 2,
                  "data": {
                    "name": "Bank",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 50512.69
                      },
                      {
                        "date": "2023-12",
                        "value": 53067.8
                      },
                      {
                        "date": "2023-11",
                        "value": 5085.58
                      },
                      {
                        "date": "2023-10",
                        "value": 6016
                      },
                      {
                        "date": "2023-09",
                        "value": 36571.37
                      },
                      {
                        "date": "2023-08",
                        "value": -68295.25
                      },
                      {
                        "date": "2023-07",
                        "value": -86240.85
                      },
                      {
                        "date": "2023-06",
                        "value": -75164.09
                      },
                      {
                        "date": "2023-05",
                        "value": -91237.29
                      },
                      {
                        "date": "2023-04",
                        "value": -92396.86
                      },
                      {
                        "date": "2023-03",
                        "value": -85323.53
                      },
                      {
                        "date": "2023-02",
                        "value": -52508.27
                      },
                      {
                        "date": "2023-01",
                        "value": -47497.87
                      },
                      {
                        "date": "2022-12",
                        "value": 147329.53
                      },
                      {
                        "date": "2022-11",
                        "value": 1003.64
                      },
                      {
                        "date": "2022-10",
                        "value": 4722.28
                      },
                      {
                        "date": "2022-09",
                        "value": 12430.3
                      },
                      {
                        "date": "2022-08",
                        "value": 14186.73
                      },
                      {
                        "date": "2022-07",
                        "value": 25697.19
                      },
                      {
                        "date": "2022-06",
                        "value": 87626.21
                      },
                      {
                        "date": "2022-05",
                        "value": 60875.56
                      },
                      {
                        "date": "2022-04",
                        "value": 108549.48
                      },
                      {
                        "date": "2022-03",
                        "value": 130879.91
                      },
                      {
                        "date": "2022-02",
                        "value": 201628
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 3,
                      "data": {
                        "name": "Bank of America (BOA) #7125",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 4,
                      "data": {
                        "name": "Hyper Wallet Shopify",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 881.14
                          },
                          {
                            "date": "2023-10",
                            "value": 241.78
                          },
                          {
                            "date": "2023-09",
                            "value": 957.16
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 473.6
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 236.8
                          },
                          {
                            "date": "2023-02",
                            "value": 249
                          },
                          {
                            "date": "2023-01",
                            "value": 710.5
                          },
                          {
                            "date": "2022-12",
                            "value": 238.84
                          }
                        ]
                      }
                    },
                    {
                      "key": 485,
                      "data": {
                        "name": "INC,ARGOID ANALYTICS *5686 (deleted)",
                        "values": [
                          {
                            "date": "2023-08",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-07",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-06",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-05",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-04",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-03",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-02",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-01",
                            "value": -106533.33
                          }
                        ]
                      }
                    },
                    {
                      "key": 5,
                      "data": {
                        "name": "PayPal",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 6,
                      "data": {
                        "name": "Silicon Valley Bank (SVB) #2316",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 262.56
                          },
                          {
                            "date": "2023-12",
                            "value": 262.56
                          },
                          {
                            "date": "2023-11",
                            "value": 261.89
                          },
                          {
                            "date": "2023-10",
                            "value": 261.25
                          },
                          {
                            "date": "2023-09",
                            "value": 260.59
                          },
                          {
                            "date": "2023-08",
                            "value": 259.95
                          },
                          {
                            "date": "2023-07",
                            "value": 258.63
                          },
                          {
                            "date": "2023-06",
                            "value": 257.97
                          },
                          {
                            "date": "2023-05",
                            "value": 257.34
                          },
                          {
                            "date": "2023-04",
                            "value": 256.69
                          },
                          {
                            "date": "2023-03",
                            "value": 256.06
                          },
                          {
                            "date": "2023-02",
                            "value": 255.41
                          },
                          {
                            "date": "2023-01",
                            "value": 254.82
                          },
                          {
                            "date": "2022-12",
                            "value": 254.17
                          },
                          {
                            "date": "2022-11",
                            "value": 253.52
                          },
                          {
                            "date": "2022-10",
                            "value": 2005.4
                          },
                          {
                            "date": "2022-09",
                            "value": 3231.42
                          },
                          {
                            "date": "2022-08",
                            "value": 10219.86
                          },
                          {
                            "date": "2022-07",
                            "value": 20199.3
                          },
                          {
                            "date": "2022-06",
                            "value": 20165.05
                          },
                          {
                            "date": "2022-05",
                            "value": 50119.65
                          },
                          {
                            "date": "2022-04",
                            "value": 104949.41
                          },
                          {
                            "date": "2022-03",
                            "value": 115131.97
                          },
                          {
                            "date": "2022-02",
                            "value": 172848.94
                          }
                        ]
                      }
                    },
                    {
                      "key": 7,
                      "data": {
                        "name": "Silicon Valley Bank (SVB) #2467",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000.13
                          },
                          {
                            "date": "2023-12",
                            "value": 52555.24
                          },
                          {
                            "date": "2023-11",
                            "value": 3692.55
                          },
                          {
                            "date": "2023-10",
                            "value": 5262.97
                          },
                          {
                            "date": "2023-09",
                            "value": 35103.62
                          },
                          {
                            "date": "2023-08",
                            "value": 37728.13
                          },
                          {
                            "date": "2023-07",
                            "value": 19783.85
                          },
                          {
                            "date": "2023-06",
                            "value": 30861.27
                          },
                          {
                            "date": "2023-05",
                            "value": 14315.1
                          },
                          {
                            "date": "2023-04",
                            "value": 13629.78
                          },
                          {
                            "date": "2023-03",
                            "value": 20466.94
                          },
                          {
                            "date": "2023-02",
                            "value": 53270.65
                          },
                          {
                            "date": "2023-01",
                            "value": 57820.14
                          },
                          {
                            "date": "2022-12",
                            "value": 146586.52
                          },
                          {
                            "date": "2022-11",
                            "value": 250.12
                          },
                          {
                            "date": "2022-10",
                            "value": 1618.32
                          },
                          {
                            "date": "2022-09",
                            "value": 8100.32
                          },
                          {
                            "date": "2022-08",
                            "value": 2868.31
                          },
                          {
                            "date": "2022-07",
                            "value": 4399.33
                          },
                          {
                            "date": "2022-06",
                            "value": 66362.6
                          },
                          {
                            "date": "2022-05",
                            "value": 9657.35
                          },
                          {
                            "date": "2022-04",
                            "value": 2501.51
                          },
                          {
                            "date": "2022-03",
                            "value": 14649.38
                          },
                          {
                            "date": "2022-02",
                            "value": 27680.5
                          }
                        ]
                      }
                    },
                    {
                      "key": 8,
                      "data": {
                        "name": "Silicon Valley Bank (SVB) #5356",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 250
                          },
                          {
                            "date": "2023-12",
                            "value": 250
                          },
                          {
                            "date": "2023-11",
                            "value": 250
                          },
                          {
                            "date": "2023-10",
                            "value": 250
                          },
                          {
                            "date": "2023-09",
                            "value": 250
                          },
                          {
                            "date": "2023-08",
                            "value": 250
                          },
                          {
                            "date": "2023-07",
                            "value": 250
                          },
                          {
                            "date": "2023-06",
                            "value": 250
                          },
                          {
                            "date": "2023-05",
                            "value": 250
                          },
                          {
                            "date": "2023-04",
                            "value": 250
                          },
                          {
                            "date": "2023-03",
                            "value": 250
                          },
                          {
                            "date": "2023-02",
                            "value": 250
                          },
                          {
                            "date": "2023-01",
                            "value": 250
                          },
                          {
                            "date": "2022-12",
                            "value": 250
                          },
                          {
                            "date": "2022-11",
                            "value": 500
                          },
                          {
                            "date": "2022-10",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-09",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-08",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-07",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-06",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-05",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-04",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-03",
                            "value": 1098.56
                          },
                          {
                            "date": "2022-02",
                            "value": 1098.56
                          }
                        ]
                      }
                    },
                    {
                      "key": 9,
                      "data": {
                        "name": "Stripe Holding Account",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 17,
                  "data": {
                    "name": "Fixed Asset",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 371674.65
                      },
                      {
                        "date": "2023-12",
                        "value": 371674.65
                      },
                      {
                        "date": "2023-11",
                        "value": 385487.18
                      },
                      {
                        "date": "2023-10",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-09",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-08",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-07",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-06",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-05",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-04",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-03",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-02",
                        "value": 385287.18
                      },
                      {
                        "date": "2023-01",
                        "value": 385287.18
                      },
                      {
                        "date": "2022-12",
                        "value": 385287.18
                      },
                      {
                        "date": "2022-11",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-10",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-09",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-07",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-08",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-06",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-05",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-04",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-03",
                        "value": 317931.38
                      },
                      {
                        "date": "2022-02",
                        "value": 314521.38
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 18,
                      "data": {
                        "name": "Accumulated Amortization",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": -28000
                          },
                          {
                            "date": "2024-01",
                            "value": -5210
                          },
                          {
                            "date": "2024-01",
                            "value": -30958.35
                          },
                          {
                            "date": "2023-12",
                            "value": -28000
                          },
                          {
                            "date": "2023-12",
                            "value": -5210
                          },
                          {
                            "date": "2023-12",
                            "value": -30958.35
                          },
                          {
                            "date": "2023-11",
                            "value": -21000
                          },
                          {
                            "date": "2023-11",
                            "value": -5210
                          },
                          {
                            "date": "2023-11",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-10",
                            "value": -21000
                          },
                          {
                            "date": "2023-10",
                            "value": -5210
                          },
                          {
                            "date": "2023-10",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-09",
                            "value": -21000
                          },
                          {
                            "date": "2023-09",
                            "value": -5210
                          },
                          {
                            "date": "2023-09",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-08",
                            "value": -21000
                          },
                          {
                            "date": "2023-08",
                            "value": -5210
                          },
                          {
                            "date": "2023-08",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-07",
                            "value": -21000
                          },
                          {
                            "date": "2023-07",
                            "value": -5210
                          },
                          {
                            "date": "2023-07",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-06",
                            "value": -21000
                          },
                          {
                            "date": "2023-06",
                            "value": -5210
                          },
                          {
                            "date": "2023-06",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-05",
                            "value": -21000
                          },
                          {
                            "date": "2023-05",
                            "value": -5210
                          },
                          {
                            "date": "2023-05",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-04",
                            "value": -21000
                          },
                          {
                            "date": "2023-04",
                            "value": -5210
                          },
                          {
                            "date": "2023-04",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-03",
                            "value": -21000
                          },
                          {
                            "date": "2023-03",
                            "value": -5210
                          },
                          {
                            "date": "2023-03",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-02",
                            "value": -21000
                          },
                          {
                            "date": "2023-02",
                            "value": -5210
                          },
                          {
                            "date": "2023-02",
                            "value": -24145.82
                          },
                          {
                            "date": "2023-01",
                            "value": -21000
                          },
                          {
                            "date": "2023-01",
                            "value": -5210
                          },
                          {
                            "date": "2023-01",
                            "value": -24145.82
                          },
                          {
                            "date": "2022-12",
                            "value": -21000
                          },
                          {
                            "date": "2022-12",
                            "value": -5210
                          },
                          {
                            "date": "2022-12",
                            "value": -24145.82
                          },
                          {
                            "date": "2022-11",
                            "value": -14000
                          },
                          {
                            "date": "2022-11",
                            "value": -1800
                          },
                          {
                            "date": "2022-11",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-10",
                            "value": -14000
                          },
                          {
                            "date": "2022-10",
                            "value": -1800
                          },
                          {
                            "date": "2022-10",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-09",
                            "value": -14000
                          },
                          {
                            "date": "2022-09",
                            "value": -1800
                          },
                          {
                            "date": "2022-09",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-08",
                            "value": -14000
                          },
                          {
                            "date": "2022-08",
                            "value": -1800
                          },
                          {
                            "date": "2022-08",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-07",
                            "value": -14000
                          },
                          {
                            "date": "2022-07",
                            "value": -1800
                          },
                          {
                            "date": "2022-07",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-06",
                            "value": -14000
                          },
                          {
                            "date": "2022-06",
                            "value": -1800
                          },
                          {
                            "date": "2022-06",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-05",
                            "value": -14000
                          },
                          {
                            "date": "2022-05",
                            "value": -1800
                          },
                          {
                            "date": "2022-05",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-04",
                            "value": -14000
                          },
                          {
                            "date": "2022-04",
                            "value": -1800
                          },
                          {
                            "date": "2022-04",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-03",
                            "value": -14000
                          },
                          {
                            "date": "2022-03",
                            "value": -1800
                          },
                          {
                            "date": "2022-03",
                            "value": -8666.62
                          },
                          {
                            "date": "2022-02",
                            "value": -14000
                          },
                          {
                            "date": "2022-02",
                            "value": -1800
                          },
                          {
                            "date": "2022-02",
                            "value": -8666.62
                          }
                        ]
                      }
                    },
                    {
                      "key": 19,
                      "data": {
                        "name": "Original Cost",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 105000
                          },
                          {
                            "date": "2024-01",
                            "value": 5410
                          },
                          {
                            "date": "2024-01",
                            "value": 325433
                          },
                          {
                            "date": "2023-12",
                            "value": 105000
                          },
                          {
                            "date": "2023-12",
                            "value": 5410
                          },
                          {
                            "date": "2023-12",
                            "value": 325433
                          },
                          {
                            "date": "2023-11",
                            "value": 105000
                          },
                          {
                            "date": "2023-11",
                            "value": 5410
                          },
                          {
                            "date": "2023-11",
                            "value": 325433
                          },
                          {
                            "date": "2023-10",
                            "value": 105000
                          },
                          {
                            "date": "2023-10",
                            "value": 5210
                          },
                          {
                            "date": "2023-10",
                            "value": 325433
                          },
                          {
                            "date": "2023-09",
                            "value": 105000
                          },
                          {
                            "date": "2023-09",
                            "value": 5210
                          },
                          {
                            "date": "2023-09",
                            "value": 325433
                          },
                          {
                            "date": "2023-08",
                            "value": 105000
                          },
                          {
                            "date": "2023-08",
                            "value": 5210
                          },
                          {
                            "date": "2023-08",
                            "value": 325433
                          },
                          {
                            "date": "2023-07",
                            "value": 105000
                          },
                          {
                            "date": "2023-07",
                            "value": 5210
                          },
                          {
                            "date": "2023-07",
                            "value": 325433
                          },
                          {
                            "date": "2023-06",
                            "value": 105000
                          },
                          {
                            "date": "2023-06",
                            "value": 5210
                          },
                          {
                            "date": "2023-06",
                            "value": 325433
                          },
                          {
                            "date": "2023-05",
                            "value": 105000
                          },
                          {
                            "date": "2023-05",
                            "value": 5210
                          },
                          {
                            "date": "2023-05",
                            "value": 325433
                          },
                          {
                            "date": "2023-04",
                            "value": 105000
                          },
                          {
                            "date": "2023-04",
                            "value": 5210
                          },
                          {
                            "date": "2023-04",
                            "value": 325433
                          },
                          {
                            "date": "2023-03",
                            "value": 105000
                          },
                          {
                            "date": "2023-03",
                            "value": 5210
                          },
                          {
                            "date": "2023-03",
                            "value": 325433
                          },
                          {
                            "date": "2023-02",
                            "value": 105000
                          },
                          {
                            "date": "2023-02",
                            "value": 5210
                          },
                          {
                            "date": "2023-02",
                            "value": 325433
                          },
                          {
                            "date": "2023-01",
                            "value": 105000
                          },
                          {
                            "date": "2023-01",
                            "value": 5210
                          },
                          {
                            "date": "2023-01",
                            "value": 325433
                          },
                          {
                            "date": "2022-12",
                            "value": 105000
                          },
                          {
                            "date": "2022-12",
                            "value": 5210
                          },
                          {
                            "date": "2022-12",
                            "value": 325433
                          },
                          {
                            "date": "2022-11",
                            "value": 105000
                          },
                          {
                            "date": "2022-11",
                            "value": 5210
                          },
                          {
                            "date": "2022-11",
                            "value": 232188
                          },
                          {
                            "date": "2022-10",
                            "value": 105000
                          },
                          {
                            "date": "2022-10",
                            "value": 5210
                          },
                          {
                            "date": "2022-10",
                            "value": 232188
                          },
                          {
                            "date": "2022-09",
                            "value": 105000
                          },
                          {
                            "date": "2022-09",
                            "value": 5210
                          },
                          {
                            "date": "2022-09",
                            "value": 232188
                          },
                          {
                            "date": "2022-08",
                            "value": 105000
                          },
                          {
                            "date": "2022-08",
                            "value": 5210
                          },
                          {
                            "date": "2022-08",
                            "value": 232188
                          },
                          {
                            "date": "2022-07",
                            "value": 105000
                          },
                          {
                            "date": "2022-07",
                            "value": 5210
                          },
                          {
                            "date": "2022-07",
                            "value": 232188
                          },
                          {
                            "date": "2022-06",
                            "value": 105000
                          },
                          {
                            "date": "2022-06",
                            "value": 5210
                          },
                          {
                            "date": "2022-06",
                            "value": 232188
                          },
                          {
                            "date": "2022-05",
                            "value": 105000
                          },
                          {
                            "date": "2022-05",
                            "value": 5210
                          },
                          {
                            "date": "2022-05",
                            "value": 232188
                          },
                          {
                            "date": "2022-04",
                            "value": 105000
                          },
                          {
                            "date": "2022-04",
                            "value": 5210
                          },
                          {
                            "date": "2022-04",
                            "value": 232188
                          },
                          {
                            "date": "2022-03",
                            "value": 105000
                          },
                          {
                            "date": "2022-03",
                            "value": 5210
                          },
                          {
                            "date": "2022-03",
                            "value": 232188
                          },
                          {
                            "date": "2022-02",
                            "value": 105000
                          },
                          {
                            "date": "2022-02",
                            "value": 1800
                          },
                          {
                            "date": "2022-02",
                            "value": 232188
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 24,
                  "data": {
                    "name": "Other Asset",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-12",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-11",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-10",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-09",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-08",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-07",
                        "value": 24380.38
                      },
                      {
                        "date": "2023-06",
                        "value": 22186.38
                      },
                      {
                        "date": "2023-05",
                        "value": 22186.38
                      },
                      {
                        "date": "2023-04",
                        "value": 22186.38
                      },
                      {
                        "date": "2023-03",
                        "value": 22186.38
                      },
                      {
                        "date": "2023-02",
                        "value": 22186.38
                      },
                      {
                        "date": "2023-01",
                        "value": 22186.38
                      },
                      {
                        "date": "2022-12",
                        "value": 22186.38
                      },
                      {
                        "date": "2022-11",
                        "value": 22059.06
                      },
                      {
                        "date": "2022-10",
                        "value": 21931.74
                      },
                      {
                        "date": "2022-09",
                        "value": 21804.42
                      },
                      {
                        "date": "2022-08",
                        "value": 21639.42
                      },
                      {
                        "date": "2022-07",
                        "value": 21385.42
                      },
                      {
                        "date": "2022-06",
                        "value": 16385.42
                      },
                      {
                        "date": "2022-05",
                        "value": 16385.42
                      },
                      {
                        "date": "2022-04",
                        "value": 16385.42
                      },
                      {
                        "date": "2022-03",
                        "value": 16385.42
                      },
                      {
                        "date": "2022-02",
                        "value": 16385.42
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 25,
                      "data": {
                        "name": "Income Tax Withheld",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-12",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-11",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-10",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-09",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-08",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-07",
                            "value": 9235.52
                          },
                          {
                            "date": "2023-06",
                            "value": 7041.52
                          },
                          {
                            "date": "2023-05",
                            "value": 7041.52
                          },
                          {
                            "date": "2023-04",
                            "value": 7041.52
                          },
                          {
                            "date": "2023-03",
                            "value": 7041.52
                          },
                          {
                            "date": "2023-02",
                            "value": 7041.52
                          },
                          {
                            "date": "2023-01",
                            "value": 7041.52
                          },
                          {
                            "date": "2022-12",
                            "value": 7041.52
                          },
                          {
                            "date": "2022-11",
                            "value": 6914.2
                          },
                          {
                            "date": "2022-10",
                            "value": 6786.88
                          },
                          {
                            "date": "2022-09",
                            "value": 6659.56
                          },
                          {
                            "date": "2022-08",
                            "value": 6494.56
                          },
                          {
                            "date": "2022-07",
                            "value": 6240.56
                          },
                          {
                            "date": "2022-06",
                            "value": 6240.56
                          },
                          {
                            "date": "2022-05",
                            "value": 6240.56
                          },
                          {
                            "date": "2022-04",
                            "value": 6240.56
                          },
                          {
                            "date": "2022-03",
                            "value": 6240.56
                          },
                          {
                            "date": "2022-02",
                            "value": 6240.56
                          }
                        ]
                      }
                    },
                    {
                      "key": 26,
                      "data": {
                        "name": "Investment in Argoid India",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 144.86
                          },
                          {
                            "date": "2023-12",
                            "value": 144.86
                          },
                          {
                            "date": "2023-11",
                            "value": 144.86
                          },
                          {
                            "date": "2023-10",
                            "value": 144.86
                          },
                          {
                            "date": "2023-09",
                            "value": 144.86
                          },
                          {
                            "date": "2023-08",
                            "value": 144.86
                          },
                          {
                            "date": "2023-07",
                            "value": 144.86
                          },
                          {
                            "date": "2023-06",
                            "value": 144.86
                          },
                          {
                            "date": "2023-05",
                            "value": 144.86
                          },
                          {
                            "date": "2023-04",
                            "value": 144.86
                          },
                          {
                            "date": "2023-03",
                            "value": 144.86
                          },
                          {
                            "date": "2023-02",
                            "value": 144.86
                          },
                          {
                            "date": "2023-01",
                            "value": 144.86
                          },
                          {
                            "date": "2022-12",
                            "value": 144.86
                          },
                          {
                            "date": "2022-11",
                            "value": 144.86
                          },
                          {
                            "date": "2022-10",
                            "value": 144.86
                          },
                          {
                            "date": "2022-09",
                            "value": 144.86
                          },
                          {
                            "date": "2022-08",
                            "value": 144.86
                          },
                          {
                            "date": "2022-07",
                            "value": 144.86
                          },
                          {
                            "date": "2022-06",
                            "value": 144.86
                          },
                          {
                            "date": "2022-05",
                            "value": 144.86
                          },
                          {
                            "date": "2022-04",
                            "value": 144.86
                          },
                          {
                            "date": "2022-03",
                            "value": 144.86
                          },
                          {
                            "date": "2022-02",
                            "value": 144.86
                          }
                        ]
                      }
                    },
                    {
                      "key": 27,
                      "data": {
                        "name": "Security Deposits",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 15000
                          },
                          {
                            "date": "2023-12",
                            "value": 15000
                          },
                          {
                            "date": "2023-11",
                            "value": 15000
                          },
                          {
                            "date": "2023-10",
                            "value": 15000
                          },
                          {
                            "date": "2023-09",
                            "value": 15000
                          },
                          {
                            "date": "2023-08",
                            "value": 15000
                          },
                          {
                            "date": "2023-07",
                            "value": 15000
                          },
                          {
                            "date": "2023-06",
                            "value": 15000
                          },
                          {
                            "date": "2023-05",
                            "value": 15000
                          },
                          {
                            "date": "2023-04",
                            "value": 15000
                          },
                          {
                            "date": "2023-03",
                            "value": 15000
                          },
                          {
                            "date": "2023-02",
                            "value": 15000
                          },
                          {
                            "date": "2023-01",
                            "value": 15000
                          },
                          {
                            "date": "2022-12",
                            "value": 15000
                          },
                          {
                            "date": "2022-11",
                            "value": 15000
                          },
                          {
                            "date": "2022-10",
                            "value": 15000
                          },
                          {
                            "date": "2022-09",
                            "value": 15000
                          },
                          {
                            "date": "2022-08",
                            "value": 15000
                          },
                          {
                            "date": "2022-07",
                            "value": 15000
                          },
                          {
                            "date": "2022-06",
                            "value": 10000
                          },
                          {
                            "date": "2022-05",
                            "value": 10000
                          },
                          {
                            "date": "2022-04",
                            "value": 10000
                          },
                          {
                            "date": "2022-03",
                            "value": 10000
                          },
                          {
                            "date": "2022-02",
                            "value": 10000
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 12,
                  "data": {
                    "name": "Other Current Asset",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 17438.37
                      },
                      {
                        "date": "2023-12",
                        "value": 17438.37
                      },
                      {
                        "date": "2023-11",
                        "value": 20486.6
                      },
                      {
                        "date": "2023-10",
                        "value": 16458.13
                      },
                      {
                        "date": "2023-09",
                        "value": 10767.55
                      },
                      {
                        "date": "2023-08",
                        "value": 26034.42
                      },
                      {
                        "date": "2023-07",
                        "value": 19157.48
                      },
                      {
                        "date": "2023-06",
                        "value": 13538.84
                      },
                      {
                        "date": "2023-05",
                        "value": 20223.87
                      },
                      {
                        "date": "2023-04",
                        "value": 16453.58
                      },
                      {
                        "date": "2023-03",
                        "value": 32784.96
                      },
                      {
                        "date": "2023-02",
                        "value": 28913.82
                      },
                      {
                        "date": "2023-01",
                        "value": 25224.07
                      },
                      {
                        "date": "2022-12",
                        "value": 17196.34
                      },
                      {
                        "date": "2022-11",
                        "value": 12201.61
                      },
                      {
                        "date": "2022-10",
                        "value": 10071.29
                      },
                      {
                        "date": "2022-09",
                        "value": 9073.37
                      },
                      {
                        "date": "2022-08",
                        "value": 2644.14
                      },
                      {
                        "date": "2022-07",
                        "value": 10279.49
                      },
                      {
                        "date": "2022-06",
                        "value": 7282.84
                      },
                      {
                        "date": "2022-05",
                        "value": 4060
                      },
                      {
                        "date": "2022-04",
                        "value": 5819.26
                      },
                      {
                        "date": "2022-03",
                        "value": 8427.52
                      },
                      {
                        "date": "2022-02",
                        "value": 5374.35
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 13,
                      "data": {
                        "name": "Prepaid Expenses",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 1438.37
                          },
                          {
                            "date": "2023-12",
                            "value": 1438.37
                          },
                          {
                            "date": "2023-11",
                            "value": 1986.6
                          },
                          {
                            "date": "2023-10",
                            "value": 2534.83
                          },
                          {
                            "date": "2023-09",
                            "value": 3093.25
                          },
                          {
                            "date": "2023-08",
                            "value": 525.12
                          },
                          {
                            "date": "2023-07",
                            "value": 1159.48
                          },
                          {
                            "date": "2023-06",
                            "value": 1793.84
                          },
                          {
                            "date": "2023-05",
                            "value": 2427.87
                          },
                          {
                            "date": "2023-04",
                            "value": 3307.58
                          },
                          {
                            "date": "2023-03",
                            "value": 4186.96
                          },
                          {
                            "date": "2023-02",
                            "value": 1214.82
                          },
                          {
                            "date": "2023-01",
                            "value": 1927.07
                          },
                          {
                            "date": "2022-12",
                            "value": 2689.34
                          },
                          {
                            "date": "2022-11",
                            "value": 3451.61
                          },
                          {
                            "date": "2022-10",
                            "value": 4071.29
                          },
                          {
                            "date": "2022-09",
                            "value": 4823.37
                          },
                          {
                            "date": "2022-08",
                            "value": 2644.14
                          },
                          {
                            "date": "2022-07",
                            "value": 3479.49
                          },
                          {
                            "date": "2022-06",
                            "value": 4314.84
                          },
                          {
                            "date": "2022-05",
                            "value": 2187
                          },
                          {
                            "date": "2022-04",
                            "value": 3542.26
                          },
                          {
                            "date": "2022-03",
                            "value": 4878.52
                          },
                          {
                            "date": "2022-02",
                            "value": 3306.35
                          }
                        ]
                      }
                    },
                    {
                      "key": 14,
                      "data": {
                        "name": "Receivable from Shareholders",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 15,
                      "data": {
                        "name": "Unbilled Revenue",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 16000
                          },
                          {
                            "date": "2023-12",
                            "value": 16000
                          },
                          {
                            "date": "2023-11",
                            "value": 18500
                          },
                          {
                            "date": "2023-10",
                            "value": 13923.3
                          },
                          {
                            "date": "2023-09",
                            "value": 7674.3
                          },
                          {
                            "date": "2023-08",
                            "value": 25509.3
                          },
                          {
                            "date": "2023-07",
                            "value": 17998
                          },
                          {
                            "date": "2023-06",
                            "value": 11745
                          },
                          {
                            "date": "2023-05",
                            "value": 17796
                          },
                          {
                            "date": "2023-04",
                            "value": 13146
                          },
                          {
                            "date": "2023-03",
                            "value": 28598
                          },
                          {
                            "date": "2023-02",
                            "value": 27699
                          },
                          {
                            "date": "2023-01",
                            "value": 23297
                          },
                          {
                            "date": "2022-12",
                            "value": 14507
                          },
                          {
                            "date": "2022-11",
                            "value": 8750
                          },
                          {
                            "date": "2022-10",
                            "value": 6000
                          },
                          {
                            "date": "2022-09",
                            "value": 4250
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 6800
                          },
                          {
                            "date": "2022-06",
                            "value": 2968
                          },
                          {
                            "date": "2022-05",
                            "value": 1873
                          },
                          {
                            "date": "2022-04",
                            "value": 2277
                          },
                          {
                            "date": "2022-03",
                            "value": 3549
                          },
                          {
                            "date": "2022-02",
                            "value": 2068
                          }
                        ]
                      }
                    },
                    {
                      "key": 16,
                      "data": {
                        "name": "Undeposited Funds",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              "key": 45,
              "data": {
                "name": "Equity",
                "values": [
                  {
                    "date": "2024-01",
                    "value": 119633.8
                  },
                  {
                    "date": "2023-12",
                    "value": 86330.43
                  },
                  {
                    "date": "2023-11",
                    "value": 137367.41
                  },
                  {
                    "date": "2023-10",
                    "value": 170049.61
                  },
                  {
                    "date": "2023-09",
                    "value": 194468.98
                  },
                  {
                    "date": "2023-08",
                    "value": 131699.55
                  },
                  {
                    "date": "2023-07",
                    "value": 169175.57
                  },
                  {
                    "date": "2023-06",
                    "value": 167260.62
                  },
                  {
                    "date": "2023-05",
                    "value": 167539.35
                  },
                  {
                    "date": "2023-04",
                    "value": 166833.65
                  },
                  {
                    "date": "2023-03",
                    "value": 173630.55
                  },
                  {
                    "date": "2023-02",
                    "value": 183250.02
                  },
                  {
                    "date": "2023-01",
                    "value": 190885.55
                  },
                  {
                    "date": "2022-12",
                    "value": 313296.08
                  },
                  {
                    "date": "2022-11",
                    "value": 260093.02
                  },
                  {
                    "date": "2022-10",
                    "value": 145942.54
                  },
                  {
                    "date": "2022-09",
                    "value": 163548.55
                  },
                  {
                    "date": "2022-08",
                    "value": 186091.22
                  },
                  {
                    "date": "2022-07",
                    "value": 211885.42
                  },
                  {
                    "date": "2022-06",
                    "value": 241946.8
                  },
                  {
                    "date": "2022-05",
                    "value": 323945.57
                  },
                  {
                    "date": "2022-04",
                    "value": 361358.39
                  },
                  {
                    "date": "2022-03",
                    "value": 415538.9
                  },
                  {
                    "date": "2022-02",
                    "value": 459583.68
                  }
                ]
              },
              "children": [
                {
                  "key": 46,
                  "data": {
                    "name": "Equity",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 86330.43
                      },
                      {
                        "date": "2023-12",
                        "value": 314756.8
                      },
                      {
                        "date": "2023-11",
                        "value": 314756.8
                      },
                      {
                        "date": "2023-10",
                        "value": 314756.8
                      },
                      {
                        "date": "2023-09",
                        "value": 314756.8
                      },
                      {
                        "date": "2023-08",
                        "value": 208223.47
                      },
                      {
                        "date": "2023-07",
                        "value": 208223.47
                      },
                      {
                        "date": "2023-06",
                        "value": 208223.47
                      },
                      {
                        "date": "2023-05",
                        "value": 208223.47
                      },
                      {
                        "date": "2023-04",
                        "value": 208223.47
                      },
                      {
                        "date": "2023-03",
                        "value": 207450.25
                      },
                      {
                        "date": "2023-02",
                        "value": 206762.75
                      },
                      {
                        "date": "2023-01",
                        "value": 206762.75
                      },
                      {
                        "date": "2022-12",
                        "value": 593794.93
                      },
                      {
                        "date": "2022-11",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-10",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-09",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-08",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-07",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-06",
                        "value": 566765.3
                      },
                      {
                        "date": "2022-05",
                        "value": 541765.3
                      },
                      {
                        "date": "2022-04",
                        "value": 541557.3
                      },
                      {
                        "date": "2022-03",
                        "value": 541557.3
                      },
                      {
                        "date": "2022-02",
                        "value": 541557.3
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 49,
                      "data": {
                        "name": "Additional Paid in Capital",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-12",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-11",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-10",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-09",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-08",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-07",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-06",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-05",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-04",
                            "value": 12846.06
                          },
                          {
                            "date": "2023-03",
                            "value": 12073.02
                          },
                          {
                            "date": "2023-02",
                            "value": 10235.96
                          },
                          {
                            "date": "2023-01",
                            "value": 10235.96
                          },
                          {
                            "date": "2022-12",
                            "value": 10235.96
                          },
                          {
                            "date": "2022-11",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-10",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-09",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-08",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-07",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-06",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-05",
                            "value": 5065.84
                          },
                          {
                            "date": "2022-04",
                            "value": 4058.08
                          },
                          {
                            "date": "2022-03",
                            "value": 4058.08
                          },
                          {
                            "date": "2022-02",
                            "value": 4058.08
                          }
                        ]
                      }
                    },
                    {
                      "key": 65,
                      "data": {
                        "name": "Amith Nandipura Prasanna - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.44
                          },
                          {
                            "date": "2023-12",
                            "value": 0.44
                          },
                          {
                            "date": "2023-11",
                            "value": 0.44
                          },
                          {
                            "date": "2023-10",
                            "value": 0.44
                          },
                          {
                            "date": "2023-09",
                            "value": 0.44
                          },
                          {
                            "date": "2023-08",
                            "value": 0.44
                          },
                          {
                            "date": "2023-07",
                            "value": 0.44
                          },
                          {
                            "date": "2023-06",
                            "value": 0.44
                          },
                          {
                            "date": "2023-05",
                            "value": 0.44
                          },
                          {
                            "date": "2023-04",
                            "value": 0.44
                          },
                          {
                            "date": "2023-03",
                            "value": 0.44
                          }
                        ]
                      }
                    },
                    {
                      "key": 71,
                      "data": {
                        "name": "Anand Reddy - Class A Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 150000
                          },
                          {
                            "date": "2023-12",
                            "value": 150000
                          },
                          {
                            "date": "2023-11",
                            "value": 150000
                          },
                          {
                            "date": "2023-10",
                            "value": 150000
                          },
                          {
                            "date": "2023-09",
                            "value": 150000
                          },
                          {
                            "date": "2023-08",
                            "value": 150000
                          },
                          {
                            "date": "2023-07",
                            "value": 150000
                          },
                          {
                            "date": "2023-06",
                            "value": 150000
                          },
                          {
                            "date": "2023-05",
                            "value": 150000
                          },
                          {
                            "date": "2023-04",
                            "value": 150000
                          },
                          {
                            "date": "2023-03",
                            "value": 150000
                          },
                          {
                            "date": "2023-02",
                            "value": 150000
                          },
                          {
                            "date": "2023-01",
                            "value": 150000
                          },
                          {
                            "date": "2022-12",
                            "value": 150000
                          },
                          {
                            "date": "2022-11",
                            "value": 150000
                          },
                          {
                            "date": "2022-10",
                            "value": 150000
                          },
                          {
                            "date": "2022-09",
                            "value": 150000
                          },
                          {
                            "date": "2022-08",
                            "value": 150000
                          },
                          {
                            "date": "2022-07",
                            "value": 150000
                          },
                          {
                            "date": "2022-06",
                            "value": 150000
                          },
                          {
                            "date": "2022-05",
                            "value": 150000
                          },
                          {
                            "date": "2022-04",
                            "value": 150000
                          },
                          {
                            "date": "2022-03",
                            "value": 150000
                          },
                          {
                            "date": "2022-02",
                            "value": 150000
                          }
                        ]
                      }
                    },
                    {
                      "key": 75,
                      "data": {
                        "name": "Anil Advani - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000
                          },
                          {
                            "date": "2023-12",
                            "value": 50000
                          },
                          {
                            "date": "2023-11",
                            "value": 50000
                          },
                          {
                            "date": "2023-10",
                            "value": 50000
                          },
                          {
                            "date": "2023-09",
                            "value": 50000
                          },
                          {
                            "date": "2023-08",
                            "value": 50000
                          },
                          {
                            "date": "2023-07",
                            "value": 50000
                          },
                          {
                            "date": "2023-06",
                            "value": 50000
                          },
                          {
                            "date": "2023-05",
                            "value": 50000
                          },
                          {
                            "date": "2023-04",
                            "value": 50000
                          },
                          {
                            "date": "2023-03",
                            "value": 50000
                          },
                          {
                            "date": "2023-02",
                            "value": 50000
                          },
                          {
                            "date": "2023-01",
                            "value": 50000
                          },
                          {
                            "date": "2022-12",
                            "value": 50000
                          },
                          {
                            "date": "2022-11",
                            "value": 50000
                          },
                          {
                            "date": "2022-10",
                            "value": 50000
                          },
                          {
                            "date": "2022-09",
                            "value": 50000
                          },
                          {
                            "date": "2022-08",
                            "value": 50000
                          },
                          {
                            "date": "2022-07",
                            "value": 50000
                          },
                          {
                            "date": "2022-06",
                            "value": 50000
                          },
                          {
                            "date": "2022-05",
                            "value": 50000
                          },
                          {
                            "date": "2022-04",
                            "value": 50000
                          },
                          {
                            "date": "2022-03",
                            "value": 50000
                          },
                          {
                            "date": "2022-02",
                            "value": 50000
                          }
                        ]
                      }
                    },
                    {
                      "key": 57,
                      "data": {
                        "name": "Baiju Chakkum Kulangara - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 153.95
                          },
                          {
                            "date": "2023-12",
                            "value": 153.95
                          },
                          {
                            "date": "2023-11",
                            "value": 153.95
                          },
                          {
                            "date": "2023-10",
                            "value": 153.95
                          },
                          {
                            "date": "2023-09",
                            "value": 153.95
                          },
                          {
                            "date": "2023-08",
                            "value": 153.95
                          },
                          {
                            "date": "2023-07",
                            "value": 153.95
                          },
                          {
                            "date": "2023-06",
                            "value": 153.95
                          },
                          {
                            "date": "2023-05",
                            "value": 153.95
                          },
                          {
                            "date": "2023-04",
                            "value": 153.95
                          },
                          {
                            "date": "2023-03",
                            "value": 153.95
                          },
                          {
                            "date": "2023-02",
                            "value": 153.95
                          },
                          {
                            "date": "2023-01",
                            "value": 153.95
                          },
                          {
                            "date": "2022-12",
                            "value": 153.95
                          },
                          {
                            "date": "2022-11",
                            "value": 153.95
                          },
                          {
                            "date": "2022-10",
                            "value": 153.95
                          },
                          {
                            "date": "2022-09",
                            "value": 153.95
                          },
                          {
                            "date": "2022-08",
                            "value": 153.95
                          },
                          {
                            "date": "2022-07",
                            "value": 153.95
                          },
                          {
                            "date": "2022-06",
                            "value": 153.95
                          },
                          {
                            "date": "2022-05",
                            "value": 153.95
                          },
                          {
                            "date": "2022-04",
                            "value": 153.95
                          },
                          {
                            "date": "2022-03",
                            "value": 153.95
                          },
                          {
                            "date": "2022-02",
                            "value": 153.95
                          }
                        ]
                      }
                    },
                    {
                      "key": 76,
                      "data": {
                        "name": "Bakul Roy & Hemlata Roy - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 77,
                      "data": {
                        "name": "Bawani Family Trust - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000
                          },
                          {
                            "date": "2023-12",
                            "value": 50000
                          },
                          {
                            "date": "2023-11",
                            "value": 50000
                          },
                          {
                            "date": "2023-10",
                            "value": 50000
                          },
                          {
                            "date": "2023-09",
                            "value": 50000
                          },
                          {
                            "date": "2023-08",
                            "value": 50000
                          },
                          {
                            "date": "2023-07",
                            "value": 50000
                          },
                          {
                            "date": "2023-06",
                            "value": 50000
                          },
                          {
                            "date": "2023-05",
                            "value": 50000
                          },
                          {
                            "date": "2023-04",
                            "value": 50000
                          },
                          {
                            "date": "2023-03",
                            "value": 50000
                          },
                          {
                            "date": "2023-02",
                            "value": 50000
                          },
                          {
                            "date": "2023-01",
                            "value": 50000
                          },
                          {
                            "date": "2022-12",
                            "value": 50000
                          },
                          {
                            "date": "2022-11",
                            "value": 50000
                          },
                          {
                            "date": "2022-10",
                            "value": 50000
                          },
                          {
                            "date": "2022-09",
                            "value": 50000
                          },
                          {
                            "date": "2022-08",
                            "value": 50000
                          },
                          {
                            "date": "2022-07",
                            "value": 50000
                          },
                          {
                            "date": "2022-06",
                            "value": 50000
                          },
                          {
                            "date": "2022-05",
                            "value": 50000
                          },
                          {
                            "date": "2022-04",
                            "value": 50000
                          },
                          {
                            "date": "2022-03",
                            "value": 50000
                          },
                          {
                            "date": "2022-02",
                            "value": 50000
                          }
                        ]
                      }
                    },
                    {
                      "key": 72,
                      "data": {
                        "name": "BRIIA Fund I, LLC - Class A Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 51,
                      "data": {
                        "name": "Chackaravarthy Esakkimuthu - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 6.46
                          },
                          {
                            "date": "2023-12",
                            "value": 6.46
                          },
                          {
                            "date": "2023-11",
                            "value": 6.46
                          },
                          {
                            "date": "2023-10",
                            "value": 6.46
                          },
                          {
                            "date": "2023-09",
                            "value": 6.46
                          },
                          {
                            "date": "2023-08",
                            "value": 6.46
                          },
                          {
                            "date": "2023-07",
                            "value": 6.46
                          },
                          {
                            "date": "2023-06",
                            "value": 6.46
                          },
                          {
                            "date": "2023-05",
                            "value": 6.46
                          },
                          {
                            "date": "2023-04",
                            "value": 6.46
                          },
                          {
                            "date": "2023-03",
                            "value": 6.46
                          },
                          {
                            "date": "2023-02",
                            "value": 6.46
                          },
                          {
                            "date": "2023-01",
                            "value": 6.46
                          },
                          {
                            "date": "2022-12",
                            "value": 6.46
                          },
                          {
                            "date": "2022-11",
                            "value": 6.46
                          },
                          {
                            "date": "2022-10",
                            "value": 6.46
                          },
                          {
                            "date": "2022-09",
                            "value": 6.46
                          },
                          {
                            "date": "2022-08",
                            "value": 6.46
                          },
                          {
                            "date": "2022-07",
                            "value": 6.46
                          },
                          {
                            "date": "2022-06",
                            "value": 6.46
                          },
                          {
                            "date": "2022-05",
                            "value": 6.46
                          },
                          {
                            "date": "2022-04",
                            "value": 6.46
                          },
                          {
                            "date": "2022-03",
                            "value": 6.46
                          },
                          {
                            "date": "2022-02",
                            "value": 6.46
                          }
                        ]
                      }
                    },
                    {
                      "key": 58,
                      "data": {
                        "name": "Chackaravarthy Esakkimuthu - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-12",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-11",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-10",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-09",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-08",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-07",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-06",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-05",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-04",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-03",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-02",
                            "value": 2022.85
                          },
                          {
                            "date": "2023-01",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-12",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-11",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-10",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-09",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-08",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-07",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-06",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-05",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-04",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-03",
                            "value": 2022.85
                          },
                          {
                            "date": "2022-02",
                            "value": 2022.85
                          }
                        ]
                      }
                    },
                    {
                      "key": 89,
                      "data": {
                        "name": "Chadha Family Revocable Trust",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 52,
                      "data": {
                        "name": "Chilukuri Naga Venkata Ravi Teja - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 2
                          },
                          {
                            "date": "2023-12",
                            "value": 2
                          },
                          {
                            "date": "2023-11",
                            "value": 2
                          },
                          {
                            "date": "2023-10",
                            "value": 2
                          },
                          {
                            "date": "2023-09",
                            "value": 2
                          },
                          {
                            "date": "2023-08",
                            "value": 2
                          },
                          {
                            "date": "2023-07",
                            "value": 2
                          },
                          {
                            "date": "2023-06",
                            "value": 2
                          },
                          {
                            "date": "2023-05",
                            "value": 2
                          },
                          {
                            "date": "2023-04",
                            "value": 2
                          },
                          {
                            "date": "2023-03",
                            "value": 2
                          },
                          {
                            "date": "2023-02",
                            "value": 2
                          },
                          {
                            "date": "2023-01",
                            "value": 2
                          },
                          {
                            "date": "2022-12",
                            "value": 2
                          },
                          {
                            "date": "2022-11",
                            "value": 2
                          },
                          {
                            "date": "2022-10",
                            "value": 2
                          },
                          {
                            "date": "2022-09",
                            "value": 2
                          },
                          {
                            "date": "2022-08",
                            "value": 2
                          },
                          {
                            "date": "2022-07",
                            "value": 2
                          },
                          {
                            "date": "2022-06",
                            "value": 2
                          },
                          {
                            "date": "2022-05",
                            "value": 2
                          },
                          {
                            "date": "2022-04",
                            "value": 2
                          },
                          {
                            "date": "2022-03",
                            "value": 2
                          },
                          {
                            "date": "2022-02",
                            "value": 2
                          }
                        ]
                      }
                    },
                    {
                      "key": 78,
                      "data": {
                        "name": "Dinesh Thakur - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 53,
                      "data": {
                        "name": "Gokulakannan Muralidharan - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 9.46
                          },
                          {
                            "date": "2023-12",
                            "value": 9.46
                          },
                          {
                            "date": "2023-11",
                            "value": 9.46
                          },
                          {
                            "date": "2023-10",
                            "value": 9.46
                          },
                          {
                            "date": "2023-09",
                            "value": 9.46
                          },
                          {
                            "date": "2023-08",
                            "value": 9.46
                          },
                          {
                            "date": "2023-07",
                            "value": 9.46
                          },
                          {
                            "date": "2023-06",
                            "value": 9.46
                          },
                          {
                            "date": "2023-05",
                            "value": 9.46
                          },
                          {
                            "date": "2023-04",
                            "value": 9.46
                          },
                          {
                            "date": "2023-03",
                            "value": 9.46
                          },
                          {
                            "date": "2023-02",
                            "value": 9.46
                          },
                          {
                            "date": "2023-01",
                            "value": 9.46
                          },
                          {
                            "date": "2022-12",
                            "value": 9.46
                          },
                          {
                            "date": "2022-11",
                            "value": 9.46
                          },
                          {
                            "date": "2022-10",
                            "value": 9.46
                          },
                          {
                            "date": "2022-09",
                            "value": 9.46
                          },
                          {
                            "date": "2022-08",
                            "value": 9.46
                          },
                          {
                            "date": "2022-07",
                            "value": 9.46
                          },
                          {
                            "date": "2022-06",
                            "value": 9.46
                          },
                          {
                            "date": "2022-05",
                            "value": 9.46
                          },
                          {
                            "date": "2022-04",
                            "value": 9.46
                          },
                          {
                            "date": "2022-03",
                            "value": 9.46
                          },
                          {
                            "date": "2022-02",
                            "value": 9.46
                          }
                        ]
                      }
                    },
                    {
                      "key": 59,
                      "data": {
                        "name": "Gokulakannan Muralidharan - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-12",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-11",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-10",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-09",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-08",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-07",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-06",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-05",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-04",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-03",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-02",
                            "value": 2646.63
                          },
                          {
                            "date": "2023-01",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-12",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-11",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-10",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-09",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-08",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-07",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-06",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-05",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-04",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-03",
                            "value": 2646.63
                          },
                          {
                            "date": "2022-02",
                            "value": 2646.63
                          }
                        ]
                      }
                    },
                    {
                      "key": 79,
                      "data": {
                        "name": "Gopal Krishna H K - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 20000
                          },
                          {
                            "date": "2023-12",
                            "value": 20000
                          },
                          {
                            "date": "2023-11",
                            "value": 20000
                          },
                          {
                            "date": "2023-10",
                            "value": 20000
                          },
                          {
                            "date": "2023-09",
                            "value": 20000
                          },
                          {
                            "date": "2023-08",
                            "value": 20000
                          },
                          {
                            "date": "2023-07",
                            "value": 20000
                          },
                          {
                            "date": "2023-06",
                            "value": 20000
                          },
                          {
                            "date": "2023-05",
                            "value": 20000
                          },
                          {
                            "date": "2023-04",
                            "value": 20000
                          },
                          {
                            "date": "2023-03",
                            "value": 20000
                          },
                          {
                            "date": "2023-02",
                            "value": 20000
                          },
                          {
                            "date": "2023-01",
                            "value": 20000
                          },
                          {
                            "date": "2022-12",
                            "value": 20000
                          },
                          {
                            "date": "2022-11",
                            "value": 20000
                          },
                          {
                            "date": "2022-10",
                            "value": 20000
                          },
                          {
                            "date": "2022-09",
                            "value": 20000
                          },
                          {
                            "date": "2022-08",
                            "value": 20000
                          },
                          {
                            "date": "2022-07",
                            "value": 20000
                          },
                          {
                            "date": "2022-06",
                            "value": 20000
                          },
                          {
                            "date": "2022-05",
                            "value": 20000
                          },
                          {
                            "date": "2022-04",
                            "value": 20000
                          },
                          {
                            "date": "2022-03",
                            "value": 20000
                          },
                          {
                            "date": "2022-02",
                            "value": 20000
                          }
                        ]
                      }
                    },
                    {
                      "key": 66,
                      "data": {
                        "name": "Gregory Varghese - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.4
                          },
                          {
                            "date": "2023-12",
                            "value": 0.4
                          },
                          {
                            "date": "2023-11",
                            "value": 0.4
                          },
                          {
                            "date": "2023-10",
                            "value": 0.4
                          },
                          {
                            "date": "2023-09",
                            "value": 0.4
                          },
                          {
                            "date": "2023-08",
                            "value": 0.4
                          },
                          {
                            "date": "2023-07",
                            "value": 0.4
                          },
                          {
                            "date": "2023-06",
                            "value": 0.4
                          },
                          {
                            "date": "2023-05",
                            "value": 0.4
                          },
                          {
                            "date": "2023-04",
                            "value": 0.4
                          },
                          {
                            "date": "2023-03",
                            "value": 0.4
                          },
                          {
                            "date": "2023-02",
                            "value": 0.4
                          },
                          {
                            "date": "2023-01",
                            "value": 0.4
                          },
                          {
                            "date": "2022-12",
                            "value": 0.4
                          }
                        ]
                      }
                    },
                    {
                      "key": 80,
                      "data": {
                        "name": "Gurmehr Kaur - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 81,
                      "data": {
                        "name": "Inventus Law P.C. - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000
                          },
                          {
                            "date": "2023-12",
                            "value": 50000
                          },
                          {
                            "date": "2023-11",
                            "value": 50000
                          },
                          {
                            "date": "2023-10",
                            "value": 50000
                          },
                          {
                            "date": "2023-09",
                            "value": 50000
                          },
                          {
                            "date": "2023-08",
                            "value": 50000
                          },
                          {
                            "date": "2023-07",
                            "value": 50000
                          },
                          {
                            "date": "2023-06",
                            "value": 50000
                          },
                          {
                            "date": "2023-05",
                            "value": 50000
                          },
                          {
                            "date": "2023-04",
                            "value": 50000
                          },
                          {
                            "date": "2023-03",
                            "value": 50000
                          },
                          {
                            "date": "2023-02",
                            "value": 50000
                          },
                          {
                            "date": "2023-01",
                            "value": 50000
                          },
                          {
                            "date": "2022-12",
                            "value": 50000
                          },
                          {
                            "date": "2022-11",
                            "value": 50000
                          },
                          {
                            "date": "2022-10",
                            "value": 50000
                          },
                          {
                            "date": "2022-09",
                            "value": 50000
                          },
                          {
                            "date": "2022-08",
                            "value": 50000
                          },
                          {
                            "date": "2022-07",
                            "value": 50000
                          },
                          {
                            "date": "2022-06",
                            "value": 50000
                          },
                          {
                            "date": "2022-05",
                            "value": 50000
                          },
                          {
                            "date": "2022-04",
                            "value": 50000
                          },
                          {
                            "date": "2022-03",
                            "value": 50000
                          },
                          {
                            "date": "2022-02",
                            "value": 50000
                          }
                        ]
                      }
                    },
                    {
                      "key": 54,
                      "data": {
                        "name": "Jack Porter - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 3.38
                          },
                          {
                            "date": "2023-12",
                            "value": 3.38
                          },
                          {
                            "date": "2023-11",
                            "value": 3.38
                          },
                          {
                            "date": "2023-10",
                            "value": 3.38
                          },
                          {
                            "date": "2023-09",
                            "value": 3.38
                          },
                          {
                            "date": "2023-08",
                            "value": 3.38
                          },
                          {
                            "date": "2023-07",
                            "value": 3.38
                          },
                          {
                            "date": "2023-06",
                            "value": 3.38
                          },
                          {
                            "date": "2023-05",
                            "value": 3.38
                          },
                          {
                            "date": "2023-04",
                            "value": 3.38
                          },
                          {
                            "date": "2023-03",
                            "value": 3.38
                          },
                          {
                            "date": "2023-02",
                            "value": 3.38
                          },
                          {
                            "date": "2023-01",
                            "value": 3.38
                          },
                          {
                            "date": "2022-12",
                            "value": 3.38
                          },
                          {
                            "date": "2022-11",
                            "value": 3.38
                          },
                          {
                            "date": "2022-10",
                            "value": 3.38
                          },
                          {
                            "date": "2022-09",
                            "value": 3.38
                          },
                          {
                            "date": "2022-08",
                            "value": 3.38
                          },
                          {
                            "date": "2022-07",
                            "value": 3.38
                          },
                          {
                            "date": "2022-06",
                            "value": 3.38
                          },
                          {
                            "date": "2022-05",
                            "value": 3.38
                          },
                          {
                            "date": "2022-04",
                            "value": 3.38
                          },
                          {
                            "date": "2022-03",
                            "value": 3.38
                          },
                          {
                            "date": "2022-02",
                            "value": 3.38
                          }
                        ]
                      }
                    },
                    {
                      "key": 94,
                      "data": {
                        "name": "Kanwar Chadda",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 73,
                      "data": {
                        "name": "Kanwar Chadha & Ashu Chadha - Class A Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000
                          },
                          {
                            "date": "2023-12",
                            "value": 50000
                          },
                          {
                            "date": "2023-11",
                            "value": 50000
                          },
                          {
                            "date": "2023-10",
                            "value": 50000
                          },
                          {
                            "date": "2023-09",
                            "value": 50000
                          },
                          {
                            "date": "2023-08",
                            "value": 50000
                          },
                          {
                            "date": "2023-07",
                            "value": 50000
                          },
                          {
                            "date": "2023-06",
                            "value": 50000
                          },
                          {
                            "date": "2023-05",
                            "value": 50000
                          },
                          {
                            "date": "2023-04",
                            "value": 50000
                          },
                          {
                            "date": "2023-03",
                            "value": 50000
                          },
                          {
                            "date": "2023-02",
                            "value": 50000
                          },
                          {
                            "date": "2023-01",
                            "value": 50000
                          },
                          {
                            "date": "2022-12",
                            "value": 50000
                          },
                          {
                            "date": "2022-11",
                            "value": 50000
                          },
                          {
                            "date": "2022-10",
                            "value": 50000
                          },
                          {
                            "date": "2022-09",
                            "value": 50000
                          },
                          {
                            "date": "2022-08",
                            "value": 50000
                          },
                          {
                            "date": "2022-07",
                            "value": 50000
                          },
                          {
                            "date": "2022-06",
                            "value": 50000
                          },
                          {
                            "date": "2022-05",
                            "value": 50000
                          },
                          {
                            "date": "2022-04",
                            "value": 50000
                          },
                          {
                            "date": "2022-03",
                            "value": 50000
                          },
                          {
                            "date": "2022-02",
                            "value": 50000
                          }
                        ]
                      }
                    },
                    {
                      "key": 82,
                      "data": {
                        "name": "Kanwar Chadha & Ashu Chadha - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 60,
                      "data": {
                        "name": "Kanwar Chadha - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 822
                          },
                          {
                            "date": "2023-12",
                            "value": 822
                          },
                          {
                            "date": "2023-11",
                            "value": 822
                          },
                          {
                            "date": "2023-10",
                            "value": 822
                          },
                          {
                            "date": "2023-09",
                            "value": 822
                          },
                          {
                            "date": "2023-08",
                            "value": 822
                          },
                          {
                            "date": "2023-07",
                            "value": 822
                          },
                          {
                            "date": "2023-06",
                            "value": 822
                          },
                          {
                            "date": "2023-05",
                            "value": 822
                          },
                          {
                            "date": "2023-04",
                            "value": 822
                          },
                          {
                            "date": "2023-03",
                            "value": 822
                          },
                          {
                            "date": "2023-02",
                            "value": 822
                          },
                          {
                            "date": "2023-01",
                            "value": 822
                          },
                          {
                            "date": "2022-12",
                            "value": 822
                          },
                          {
                            "date": "2022-11",
                            "value": 822
                          },
                          {
                            "date": "2022-10",
                            "value": 822
                          },
                          {
                            "date": "2022-09",
                            "value": 822
                          },
                          {
                            "date": "2022-08",
                            "value": 822
                          },
                          {
                            "date": "2022-07",
                            "value": 822
                          },
                          {
                            "date": "2022-06",
                            "value": 822
                          },
                          {
                            "date": "2022-05",
                            "value": 822
                          },
                          {
                            "date": "2022-04",
                            "value": 822
                          },
                          {
                            "date": "2022-03",
                            "value": 822
                          },
                          {
                            "date": "2022-02",
                            "value": 822
                          }
                        ]
                      }
                    },
                    {
                      "key": 67,
                      "data": {
                        "name": "Karthik Kumaraswamy - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.18
                          },
                          {
                            "date": "2023-12",
                            "value": 0.18
                          },
                          {
                            "date": "2023-11",
                            "value": 0.18
                          },
                          {
                            "date": "2023-10",
                            "value": 0.18
                          },
                          {
                            "date": "2023-09",
                            "value": 0.18
                          },
                          {
                            "date": "2023-08",
                            "value": 0.18
                          },
                          {
                            "date": "2023-07",
                            "value": 0.18
                          },
                          {
                            "date": "2023-06",
                            "value": 0.18
                          },
                          {
                            "date": "2023-05",
                            "value": 0.18
                          },
                          {
                            "date": "2023-04",
                            "value": 0.18
                          }
                        ]
                      }
                    },
                    {
                      "key": 55,
                      "data": {
                        "name": "Kurian Cheeramelil - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 2
                          },
                          {
                            "date": "2023-12",
                            "value": 2
                          },
                          {
                            "date": "2023-11",
                            "value": 2
                          },
                          {
                            "date": "2023-10",
                            "value": 2
                          },
                          {
                            "date": "2023-09",
                            "value": 2
                          },
                          {
                            "date": "2023-08",
                            "value": 2
                          },
                          {
                            "date": "2023-07",
                            "value": 2
                          },
                          {
                            "date": "2023-06",
                            "value": 2
                          },
                          {
                            "date": "2023-05",
                            "value": 2
                          },
                          {
                            "date": "2023-04",
                            "value": 2
                          },
                          {
                            "date": "2023-03",
                            "value": 2
                          },
                          {
                            "date": "2023-02",
                            "value": 2
                          },
                          {
                            "date": "2023-01",
                            "value": 2
                          },
                          {
                            "date": "2022-12",
                            "value": 2
                          },
                          {
                            "date": "2022-11",
                            "value": 2
                          },
                          {
                            "date": "2022-10",
                            "value": 2
                          },
                          {
                            "date": "2022-09",
                            "value": 2
                          },
                          {
                            "date": "2022-08",
                            "value": 2
                          },
                          {
                            "date": "2022-07",
                            "value": 2
                          },
                          {
                            "date": "2022-06",
                            "value": 2
                          },
                          {
                            "date": "2022-05",
                            "value": 2
                          },
                          {
                            "date": "2022-04",
                            "value": 2
                          },
                          {
                            "date": "2022-03",
                            "value": 2
                          },
                          {
                            "date": "2022-02",
                            "value": 2
                          }
                        ]
                      }
                    },
                    {
                      "key": 61,
                      "data": {
                        "name": "Kurian Cheeramelil - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 518.5
                          },
                          {
                            "date": "2023-12",
                            "value": 518.5
                          },
                          {
                            "date": "2023-11",
                            "value": 518.5
                          },
                          {
                            "date": "2023-10",
                            "value": 518.5
                          },
                          {
                            "date": "2023-09",
                            "value": 518.5
                          },
                          {
                            "date": "2023-08",
                            "value": 518.5
                          },
                          {
                            "date": "2023-07",
                            "value": 518.5
                          },
                          {
                            "date": "2023-06",
                            "value": 518.5
                          },
                          {
                            "date": "2023-05",
                            "value": 518.5
                          },
                          {
                            "date": "2023-04",
                            "value": 518.5
                          },
                          {
                            "date": "2023-03",
                            "value": 518.5
                          },
                          {
                            "date": "2023-02",
                            "value": 518.5
                          },
                          {
                            "date": "2023-01",
                            "value": 518.5
                          },
                          {
                            "date": "2022-12",
                            "value": 518.5
                          },
                          {
                            "date": "2022-11",
                            "value": 518.5
                          },
                          {
                            "date": "2022-10",
                            "value": 518.5
                          },
                          {
                            "date": "2022-09",
                            "value": 518.5
                          },
                          {
                            "date": "2022-08",
                            "value": 518.5
                          },
                          {
                            "date": "2022-07",
                            "value": 518.5
                          },
                          {
                            "date": "2022-06",
                            "value": 518.5
                          },
                          {
                            "date": "2022-05",
                            "value": 518.5
                          },
                          {
                            "date": "2022-04",
                            "value": 518.5
                          },
                          {
                            "date": "2022-03",
                            "value": 518.5
                          },
                          {
                            "date": "2022-02",
                            "value": 518.5
                          }
                        ]
                      }
                    },
                    {
                      "key": 68,
                      "data": {
                        "name": "Lavanya M K - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.24
                          },
                          {
                            "date": "2023-12",
                            "value": 0.24
                          },
                          {
                            "date": "2023-11",
                            "value": 0.24
                          },
                          {
                            "date": "2023-10",
                            "value": 0.24
                          },
                          {
                            "date": "2023-09",
                            "value": 0.24
                          },
                          {
                            "date": "2023-08",
                            "value": 0.24
                          },
                          {
                            "date": "2023-07",
                            "value": 0.24
                          },
                          {
                            "date": "2023-06",
                            "value": 0.24
                          },
                          {
                            "date": "2023-05",
                            "value": 0.24
                          },
                          {
                            "date": "2023-04",
                            "value": 0.24
                          },
                          {
                            "date": "2023-03",
                            "value": 0.24
                          },
                          {
                            "date": "2023-02",
                            "value": 0.24
                          },
                          {
                            "date": "2023-01",
                            "value": 0.24
                          },
                          {
                            "date": "2022-12",
                            "value": 0.24
                          },
                          {
                            "date": "2022-11",
                            "value": 0.24
                          },
                          {
                            "date": "2022-10",
                            "value": 0.24
                          },
                          {
                            "date": "2022-09",
                            "value": 0.24
                          },
                          {
                            "date": "2022-08",
                            "value": 0.24
                          },
                          {
                            "date": "2022-07",
                            "value": 0.24
                          },
                          {
                            "date": "2022-06",
                            "value": 0.24
                          },
                          {
                            "date": "2022-05",
                            "value": 0.24
                          }
                        ]
                      }
                    },
                    {
                      "key": 83,
                      "data": {
                        "name": "Narsipur Srinivasan Amarnath - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-12",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-11",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-10",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-09",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-08",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-07",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-06",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-05",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-04",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-03",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-02",
                            "value": 16737.7
                          },
                          {
                            "date": "2023-01",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-12",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-11",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-10",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-09",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-08",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-07",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-06",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-05",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-04",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-03",
                            "value": 16737.7
                          },
                          {
                            "date": "2022-02",
                            "value": 16737.7
                          }
                        ]
                      }
                    },
                    {
                      "key": 47,
                      "data": {
                        "name": "Opening Balance Equity",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-07",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-06",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-05",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-04",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-03",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-02",
                            "value": -106533.33
                          },
                          {
                            "date": "2023-01",
                            "value": -106533.33
                          }
                        ]
                      }
                    },
                    {
                      "key": 84,
                      "data": {
                        "name": "Pritam Bhusan Prasad - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 74,
                      "data": {
                        "name": "Rajiv Baronia - Class A Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 85,
                      "data": {
                        "name": "Rashpal Deol & Adarsh Kaur - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 48,
                      "data": {
                        "name": "Retained Earnings",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": -1551195.2
                          },
                          {
                            "date": "2023-12",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-11",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-10",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-09",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-08",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-07",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-06",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-05",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-04",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-03",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-02",
                            "value": -1322768.83
                          },
                          {
                            "date": "2023-01",
                            "value": -1322768.83
                          },
                          {
                            "date": "2022-12",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-11",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-10",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-09",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-08",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-07",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-06",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-05",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-04",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-03",
                            "value": -1042269.98
                          },
                          {
                            "date": "2022-02",
                            "value": -1042269.98
                          }
                        ]
                      }
                    },
                    {
                      "key": 90,
                      "data": {
                        "name": "RMCatch - Series Seed Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 425000
                          },
                          {
                            "date": "2023-12",
                            "value": 425000
                          },
                          {
                            "date": "2023-11",
                            "value": 425000
                          },
                          {
                            "date": "2023-10",
                            "value": 425000
                          },
                          {
                            "date": "2023-09",
                            "value": 425000
                          },
                          {
                            "date": "2023-08",
                            "value": 425000
                          },
                          {
                            "date": "2023-07",
                            "value": 425000
                          },
                          {
                            "date": "2023-06",
                            "value": 425000
                          },
                          {
                            "date": "2023-05",
                            "value": 425000
                          },
                          {
                            "date": "2023-04",
                            "value": 425000
                          },
                          {
                            "date": "2023-03",
                            "value": 425000
                          },
                          {
                            "date": "2023-02",
                            "value": 425000
                          },
                          {
                            "date": "2023-01",
                            "value": 425000
                          },
                          {
                            "date": "2022-12",
                            "value": 425000
                          },
                          {
                            "date": "2022-11",
                            "value": 425000
                          },
                          {
                            "date": "2022-10",
                            "value": 425000
                          },
                          {
                            "date": "2022-09",
                            "value": 425000
                          },
                          {
                            "date": "2022-08",
                            "value": 425000
                          },
                          {
                            "date": "2022-07",
                            "value": 425000
                          },
                          {
                            "date": "2022-06",
                            "value": 425000
                          },
                          {
                            "date": "2022-05",
                            "value": 425000
                          },
                          {
                            "date": "2022-04",
                            "value": 425000
                          },
                          {
                            "date": "2022-03",
                            "value": 425000
                          },
                          {
                            "date": "2022-02",
                            "value": 425000
                          }
                        ]
                      }
                    },
                    {
                      "key": 86,
                      "data": {
                        "name": "Satyamurthy Pullela - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 91,
                      "data": {
                        "name": "Sentienz Solutions Private Limited - Series Seed Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 105000
                          },
                          {
                            "date": "2023-12",
                            "value": 105000
                          },
                          {
                            "date": "2023-11",
                            "value": 105000
                          },
                          {
                            "date": "2023-10",
                            "value": 105000
                          },
                          {
                            "date": "2023-09",
                            "value": 105000
                          },
                          {
                            "date": "2023-08",
                            "value": 105000
                          },
                          {
                            "date": "2023-07",
                            "value": 105000
                          },
                          {
                            "date": "2023-06",
                            "value": 105000
                          },
                          {
                            "date": "2023-05",
                            "value": 105000
                          },
                          {
                            "date": "2023-04",
                            "value": 105000
                          },
                          {
                            "date": "2023-03",
                            "value": 105000
                          },
                          {
                            "date": "2023-02",
                            "value": 105000
                          },
                          {
                            "date": "2023-01",
                            "value": 105000
                          },
                          {
                            "date": "2022-12",
                            "value": 105000
                          },
                          {
                            "date": "2022-11",
                            "value": 105000
                          },
                          {
                            "date": "2022-10",
                            "value": 105000
                          },
                          {
                            "date": "2022-09",
                            "value": 105000
                          },
                          {
                            "date": "2022-08",
                            "value": 105000
                          },
                          {
                            "date": "2022-07",
                            "value": 105000
                          },
                          {
                            "date": "2022-06",
                            "value": 105000
                          },
                          {
                            "date": "2022-05",
                            "value": 105000
                          },
                          {
                            "date": "2022-04",
                            "value": 105000
                          },
                          {
                            "date": "2022-03",
                            "value": 105000
                          },
                          {
                            "date": "2022-02",
                            "value": 105000
                          }
                        ]
                      }
                    },
                    {
                      "key": 56,
                      "data": {
                        "name": "Soundararajan Velu - Class A Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 30.08
                          },
                          {
                            "date": "2023-12",
                            "value": 30.08
                          },
                          {
                            "date": "2023-11",
                            "value": 30.08
                          },
                          {
                            "date": "2023-10",
                            "value": 30.08
                          },
                          {
                            "date": "2023-09",
                            "value": 30.08
                          },
                          {
                            "date": "2023-08",
                            "value": 30.08
                          },
                          {
                            "date": "2023-07",
                            "value": 30.08
                          },
                          {
                            "date": "2023-06",
                            "value": 30.08
                          },
                          {
                            "date": "2023-05",
                            "value": 30.08
                          },
                          {
                            "date": "2023-04",
                            "value": 30.08
                          },
                          {
                            "date": "2023-03",
                            "value": 30.08
                          },
                          {
                            "date": "2023-02",
                            "value": 30.08
                          },
                          {
                            "date": "2023-01",
                            "value": 30.08
                          },
                          {
                            "date": "2022-12",
                            "value": 30.08
                          },
                          {
                            "date": "2022-11",
                            "value": 30.08
                          },
                          {
                            "date": "2022-10",
                            "value": 30.08
                          },
                          {
                            "date": "2022-09",
                            "value": 30.08
                          },
                          {
                            "date": "2022-08",
                            "value": 30.08
                          },
                          {
                            "date": "2022-07",
                            "value": 30.08
                          },
                          {
                            "date": "2022-06",
                            "value": 30.08
                          },
                          {
                            "date": "2022-05",
                            "value": 30.08
                          },
                          {
                            "date": "2022-04",
                            "value": 30.08
                          },
                          {
                            "date": "2022-03",
                            "value": 30.08
                          },
                          {
                            "date": "2022-02",
                            "value": 30.08
                          }
                        ]
                      }
                    },
                    {
                      "key": 64,
                      "data": {
                        "name": "Soundararajan Velu - Class C Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 120
                          },
                          {
                            "date": "2023-12",
                            "value": 120
                          },
                          {
                            "date": "2023-11",
                            "value": 120
                          },
                          {
                            "date": "2023-10",
                            "value": 120
                          },
                          {
                            "date": "2023-09",
                            "value": 120
                          },
                          {
                            "date": "2023-08",
                            "value": 120
                          },
                          {
                            "date": "2023-07",
                            "value": 120
                          },
                          {
                            "date": "2023-06",
                            "value": 120
                          },
                          {
                            "date": "2023-05",
                            "value": 120
                          },
                          {
                            "date": "2023-04",
                            "value": 120
                          },
                          {
                            "date": "2023-03",
                            "value": 120
                          },
                          {
                            "date": "2023-02",
                            "value": 120
                          },
                          {
                            "date": "2023-01",
                            "value": 120
                          },
                          {
                            "date": "2022-12",
                            "value": 120
                          },
                          {
                            "date": "2022-11",
                            "value": 120
                          },
                          {
                            "date": "2022-10",
                            "value": 120
                          },
                          {
                            "date": "2022-09",
                            "value": 120
                          },
                          {
                            "date": "2022-08",
                            "value": 120
                          },
                          {
                            "date": "2022-07",
                            "value": 120
                          },
                          {
                            "date": "2022-06",
                            "value": 120
                          },
                          {
                            "date": "2022-05",
                            "value": 120
                          },
                          {
                            "date": "2022-04",
                            "value": 120
                          },
                          {
                            "date": "2022-03",
                            "value": 120
                          },
                          {
                            "date": "2022-02",
                            "value": 120
                          }
                        ]
                      }
                    },
                    {
                      "key": 62,
                      "data": {
                        "name": "Srikanth G N - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 513.21
                          },
                          {
                            "date": "2023-12",
                            "value": 513.21
                          },
                          {
                            "date": "2023-11",
                            "value": 513.21
                          },
                          {
                            "date": "2023-10",
                            "value": 513.21
                          },
                          {
                            "date": "2023-09",
                            "value": 513.21
                          },
                          {
                            "date": "2023-08",
                            "value": 513.21
                          },
                          {
                            "date": "2023-07",
                            "value": 513.21
                          },
                          {
                            "date": "2023-06",
                            "value": 513.21
                          },
                          {
                            "date": "2023-05",
                            "value": 513.21
                          },
                          {
                            "date": "2023-04",
                            "value": 513.21
                          },
                          {
                            "date": "2023-03",
                            "value": 513.21
                          },
                          {
                            "date": "2023-02",
                            "value": 513.21
                          },
                          {
                            "date": "2023-01",
                            "value": 513.21
                          },
                          {
                            "date": "2022-12",
                            "value": 513.21
                          },
                          {
                            "date": "2022-11",
                            "value": 513.21
                          },
                          {
                            "date": "2022-10",
                            "value": 513.21
                          },
                          {
                            "date": "2022-09",
                            "value": 513.21
                          },
                          {
                            "date": "2022-08",
                            "value": 513.21
                          },
                          {
                            "date": "2022-07",
                            "value": 513.21
                          },
                          {
                            "date": "2022-06",
                            "value": 513.21
                          },
                          {
                            "date": "2022-05",
                            "value": 513.21
                          },
                          {
                            "date": "2022-04",
                            "value": 513.21
                          },
                          {
                            "date": "2022-03",
                            "value": 513.21
                          },
                          {
                            "date": "2022-02",
                            "value": 513.21
                          }
                        ]
                      }
                    },
                    {
                      "key": 50,
                      "data": {
                        "name": "Stock Option Outstanding",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-12",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-11",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-10",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-09",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-08",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-07",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-06",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-05",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-04",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-03",
                            "value": 50831.68
                          },
                          {
                            "date": "2023-02",
                            "value": 51981.68
                          },
                          {
                            "date": "2023-01",
                            "value": 51981.68
                          },
                          {
                            "date": "2022-12",
                            "value": 51981.68
                          },
                          {
                            "date": "2022-11",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-10",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-09",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-08",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-07",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-06",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-05",
                            "value": 30123.4
                          },
                          {
                            "date": "2022-04",
                            "value": 30923.4
                          },
                          {
                            "date": "2022-03",
                            "value": 30923.4
                          },
                          {
                            "date": "2022-02",
                            "value": 30923.4
                          }
                        ]
                      }
                    },
                    {
                      "key": 63,
                      "data": {
                        "name": "Suresh Kumar Subramanian - Class B Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 256.61
                          },
                          {
                            "date": "2023-12",
                            "value": 256.61
                          },
                          {
                            "date": "2023-11",
                            "value": 256.61
                          },
                          {
                            "date": "2023-10",
                            "value": 256.61
                          },
                          {
                            "date": "2023-09",
                            "value": 256.61
                          },
                          {
                            "date": "2023-08",
                            "value": 256.61
                          },
                          {
                            "date": "2023-07",
                            "value": 256.61
                          },
                          {
                            "date": "2023-06",
                            "value": 256.61
                          },
                          {
                            "date": "2023-05",
                            "value": 256.61
                          },
                          {
                            "date": "2023-04",
                            "value": 256.61
                          },
                          {
                            "date": "2023-03",
                            "value": 256.61
                          },
                          {
                            "date": "2023-02",
                            "value": 256.61
                          },
                          {
                            "date": "2023-01",
                            "value": 256.61
                          },
                          {
                            "date": "2022-12",
                            "value": 256.61
                          },
                          {
                            "date": "2022-11",
                            "value": 256.61
                          },
                          {
                            "date": "2022-10",
                            "value": 256.61
                          },
                          {
                            "date": "2022-09",
                            "value": 256.61
                          },
                          {
                            "date": "2022-08",
                            "value": 256.61
                          },
                          {
                            "date": "2022-07",
                            "value": 256.61
                          },
                          {
                            "date": "2022-06",
                            "value": 256.61
                          },
                          {
                            "date": "2022-05",
                            "value": 256.61
                          },
                          {
                            "date": "2022-04",
                            "value": 256.61
                          },
                          {
                            "date": "2022-03",
                            "value": 256.61
                          },
                          {
                            "date": "2022-02",
                            "value": 256.61
                          }
                        ]
                      }
                    },
                    {
                      "key": 69,
                      "data": {
                        "name": "Tuvia Barak - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.97
                          },
                          {
                            "date": "2023-12",
                            "value": 0.97
                          },
                          {
                            "date": "2023-11",
                            "value": 0.97
                          },
                          {
                            "date": "2023-10",
                            "value": 0.97
                          },
                          {
                            "date": "2023-09",
                            "value": 0.97
                          },
                          {
                            "date": "2023-08",
                            "value": 0.97
                          },
                          {
                            "date": "2023-07",
                            "value": 0.97
                          },
                          {
                            "date": "2023-06",
                            "value": 0.97
                          },
                          {
                            "date": "2023-05",
                            "value": 0.97
                          },
                          {
                            "date": "2023-04",
                            "value": 0.97
                          },
                          {
                            "date": "2023-03",
                            "value": 0.97
                          },
                          {
                            "date": "2023-02",
                            "value": 0.97
                          },
                          {
                            "date": "2023-01",
                            "value": 0.97
                          },
                          {
                            "date": "2022-12",
                            "value": 0.97
                          },
                          {
                            "date": "2022-11",
                            "value": 0.97
                          },
                          {
                            "date": "2022-10",
                            "value": 0.97
                          },
                          {
                            "date": "2022-09",
                            "value": 0.97
                          },
                          {
                            "date": "2022-08",
                            "value": 0.97
                          },
                          {
                            "date": "2022-07",
                            "value": 0.97
                          },
                          {
                            "date": "2022-06",
                            "value": 0.97
                          },
                          {
                            "date": "2022-05",
                            "value": 0.97
                          },
                          {
                            "date": "2022-04",
                            "value": 0.97
                          },
                          {
                            "date": "2022-03",
                            "value": 0.97
                          },
                          {
                            "date": "2022-02",
                            "value": 0.97
                          }
                        ]
                      }
                    },
                    {
                      "key": 92,
                      "data": {
                        "name": "Tuvia - Series Seed Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 50000
                          },
                          {
                            "date": "2023-12",
                            "value": 50000
                          },
                          {
                            "date": "2023-11",
                            "value": 50000
                          },
                          {
                            "date": "2023-10",
                            "value": 50000
                          },
                          {
                            "date": "2023-09",
                            "value": 50000
                          },
                          {
                            "date": "2023-08",
                            "value": 50000
                          },
                          {
                            "date": "2023-07",
                            "value": 50000
                          },
                          {
                            "date": "2023-06",
                            "value": 50000
                          },
                          {
                            "date": "2023-05",
                            "value": 50000
                          },
                          {
                            "date": "2023-04",
                            "value": 50000
                          },
                          {
                            "date": "2023-03",
                            "value": 50000
                          },
                          {
                            "date": "2023-02",
                            "value": 50000
                          },
                          {
                            "date": "2023-01",
                            "value": 50000
                          },
                          {
                            "date": "2022-12",
                            "value": 50000
                          },
                          {
                            "date": "2022-11",
                            "value": 50000
                          },
                          {
                            "date": "2022-10",
                            "value": 50000
                          },
                          {
                            "date": "2022-09",
                            "value": 50000
                          },
                          {
                            "date": "2022-08",
                            "value": 50000
                          },
                          {
                            "date": "2022-07",
                            "value": 50000
                          },
                          {
                            "date": "2022-06",
                            "value": 50000
                          },
                          {
                            "date": "2022-05",
                            "value": 50000
                          },
                          {
                            "date": "2022-04",
                            "value": 50000
                          },
                          {
                            "date": "2022-03",
                            "value": 50000
                          },
                          {
                            "date": "2022-02",
                            "value": 50000
                          }
                        ]
                      }
                    },
                    {
                      "key": 87,
                      "data": {
                        "name": "Venkat S Raju - Class B Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 88,
                      "data": {
                        "name": "Vivek Malka - Class C Preferred Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 25000
                          },
                          {
                            "date": "2023-12",
                            "value": 25000
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          },
                          {
                            "date": "2022-05",
                            "value": 25000
                          },
                          {
                            "date": "2022-04",
                            "value": 25000
                          },
                          {
                            "date": "2022-03",
                            "value": 25000
                          },
                          {
                            "date": "2022-02",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 70,
                      "data": {
                        "name": "William Lawrence - Class D Common Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0.83
                          },
                          {
                            "date": "2023-12",
                            "value": 0.83
                          },
                          {
                            "date": "2023-11",
                            "value": 0.83
                          },
                          {
                            "date": "2023-10",
                            "value": 0.83
                          },
                          {
                            "date": "2023-09",
                            "value": 0.83
                          },
                          {
                            "date": "2023-08",
                            "value": 0.83
                          },
                          {
                            "date": "2023-07",
                            "value": 0.83
                          },
                          {
                            "date": "2023-06",
                            "value": 0.83
                          },
                          {
                            "date": "2023-05",
                            "value": 0.83
                          },
                          {
                            "date": "2023-04",
                            "value": 0.83
                          },
                          {
                            "date": "2023-03",
                            "value": 0.83
                          },
                          {
                            "date": "2023-02",
                            "value": 0.83
                          },
                          {
                            "date": "2023-01",
                            "value": 0.83
                          },
                          {
                            "date": "2022-12",
                            "value": 0.83
                          }
                        ]
                      }
                    },
                    {
                      "key": 93,
                      "data": {
                        "name": "YourNest India VC Fund II - Series Seed Stock",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 300000
                          },
                          {
                            "date": "2023-12",
                            "value": 300000
                          },
                          {
                            "date": "2023-11",
                            "value": 300000
                          },
                          {
                            "date": "2023-10",
                            "value": 300000
                          },
                          {
                            "date": "2023-09",
                            "value": 300000
                          },
                          {
                            "date": "2023-08",
                            "value": 300000
                          },
                          {
                            "date": "2023-07",
                            "value": 300000
                          },
                          {
                            "date": "2023-06",
                            "value": 300000
                          },
                          {
                            "date": "2023-05",
                            "value": 300000
                          },
                          {
                            "date": "2023-04",
                            "value": 300000
                          },
                          {
                            "date": "2023-03",
                            "value": 300000
                          },
                          {
                            "date": "2023-02",
                            "value": 300000
                          },
                          {
                            "date": "2023-01",
                            "value": 300000
                          },
                          {
                            "date": "2022-12",
                            "value": 300000
                          },
                          {
                            "date": "2022-11",
                            "value": 300000
                          },
                          {
                            "date": "2022-10",
                            "value": 300000
                          },
                          {
                            "date": "2022-09",
                            "value": 300000
                          },
                          {
                            "date": "2022-08",
                            "value": 300000
                          },
                          {
                            "date": "2022-07",
                            "value": 300000
                          },
                          {
                            "date": "2022-06",
                            "value": 300000
                          },
                          {
                            "date": "2022-05",
                            "value": 300000
                          },
                          {
                            "date": "2022-04",
                            "value": 300000
                          },
                          {
                            "date": "2022-03",
                            "value": 300000
                          },
                          {
                            "date": "2022-02",
                            "value": 300000
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 95,
                  "data": {
                    "name": "Net Income",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 33303.37
                      },
                      {
                        "date": "2023-12",
                        "value": -228426.37
                      },
                      {
                        "date": "2023-11",
                        "value": -177389.39
                      },
                      {
                        "date": "2023-10",
                        "value": -144707.19
                      },
                      {
                        "date": "2023-09",
                        "value": -120287.82
                      },
                      {
                        "date": "2023-08",
                        "value": -76523.92
                      },
                      {
                        "date": "2023-07",
                        "value": -39047.9
                      },
                      {
                        "date": "2023-06",
                        "value": -40962.85
                      },
                      {
                        "date": "2023-05",
                        "value": -40684.12
                      },
                      {
                        "date": "2023-04",
                        "value": -41389.82
                      },
                      {
                        "date": "2023-03",
                        "value": -33819.7
                      },
                      {
                        "date": "2023-02",
                        "value": -23512.73
                      },
                      {
                        "date": "2023-01",
                        "value": -15877.2
                      },
                      {
                        "date": "2022-12",
                        "value": -280498.85
                      },
                      {
                        "date": "2022-11",
                        "value": -306672.28
                      },
                      {
                        "date": "2022-10",
                        "value": -420822.76
                      },
                      {
                        "date": "2022-09",
                        "value": -403216.75
                      },
                      {
                        "date": "2022-08",
                        "value": -380674.08
                      },
                      {
                        "date": "2022-07",
                        "value": -354879.88
                      },
                      {
                        "date": "2022-06",
                        "value": -324818.5
                      },
                      {
                        "date": "2022-05",
                        "value": -217819.73
                      },
                      {
                        "date": "2022-04",
                        "value": -180198.91
                      },
                      {
                        "date": "2022-03",
                        "value": -126018.4
                      },
                      {
                        "date": "2022-02",
                        "value": -81973.62
                      }
                    ]
                  },
                  "children": []
                }
              ]
            },
            {
              "key": 28,
              "data": {
                "name": "Liabilities",
                "values": [
                  {
                    "date": "2024-01",
                    "value": 402716.29
                  },
                  {
                    "date": "2023-12",
                    "value": 399626.77
                  },
                  {
                    "date": "2023-11",
                    "value": 355220.33
                  },
                  {
                    "date": "2023-10",
                    "value": 325740.08
                  },
                  {
                    "date": "2023-09",
                    "value": 309137.5
                  },
                  {
                    "date": "2023-08",
                    "value": 260507.18
                  },
                  {
                    "date": "2023-07",
                    "value": 192908.62
                  },
                  {
                    "date": "2023-06",
                    "value": 222787.69
                  },
                  {
                    "date": "2023-05",
                    "value": 210520.79
                  },
                  {
                    "date": "2023-04",
                    "value": 204996.63
                  },
                  {
                    "date": "2023-03",
                    "value": 208604.44
                  },
                  {
                    "date": "2023-02",
                    "value": 219229.09
                  },
                  {
                    "date": "2023-01",
                    "value": 204024.21
                  },
                  {
                    "date": "2022-12",
                    "value": 269303.35
                  },
                  {
                    "date": "2022-11",
                    "value": 258470.67
                  },
                  {
                    "date": "2022-10",
                    "value": 224082.15
                  },
                  {
                    "date": "2022-09",
                    "value": 212258.92
                  },
                  {
                    "date": "2022-08",
                    "value": 191360.45
                  },
                  {
                    "date": "2022-07",
                    "value": 173058.06
                  },
                  {
                    "date": "2022-06",
                    "value": 196461.05
                  },
                  {
                    "date": "2022-05",
                    "value": 86364.79
                  },
                  {
                    "date": "2022-04",
                    "value": 98508.15
                  },
                  {
                    "date": "2022-03",
                    "value": 70910.33
                  },
                  {
                    "date": "2022-02",
                    "value": 103150.47
                  }
                ]
              },
              "children": [
                {
                  "key": 29,
                  "data": {
                    "name": "Accounts Payable",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 42987.87
                      },
                      {
                        "date": "2023-12",
                        "value": 42334.97
                      },
                      {
                        "date": "2023-11",
                        "value": 72659.47
                      },
                      {
                        "date": "2023-10",
                        "value": 72606.57
                      },
                      {
                        "date": "2023-09",
                        "value": 73380.17
                      },
                      {
                        "date": "2023-08",
                        "value": 81070.17
                      },
                      {
                        "date": "2023-07",
                        "value": 103488.27
                      },
                      {
                        "date": "2023-06",
                        "value": 130836.25
                      },
                      {
                        "date": "2023-05",
                        "value": 146114.37
                      },
                      {
                        "date": "2023-04",
                        "value": 135413.13
                      },
                      {
                        "date": "2023-03",
                        "value": 138965.11
                      },
                      {
                        "date": "2023-02",
                        "value": 149604.96
                      },
                      {
                        "date": "2023-01",
                        "value": 138889.16
                      },
                      {
                        "date": "2022-12",
                        "value": 200176.2
                      },
                      {
                        "date": "2022-11",
                        "value": 192700.36
                      },
                      {
                        "date": "2022-10",
                        "value": 163052.24
                      },
                      {
                        "date": "2022-09",
                        "value": 152510.87
                      },
                      {
                        "date": "2022-08",
                        "value": 132155.95
                      },
                      {
                        "date": "2022-07",
                        "value": 114897.93
                      },
                      {
                        "date": "2022-06",
                        "value": 139409.84
                      },
                      {
                        "date": "2022-05",
                        "value": 67200
                      },
                      {
                        "date": "2022-04",
                        "value": 65214
                      },
                      {
                        "date": "2022-03",
                        "value": 54402
                      },
                      {
                        "date": "2022-02",
                        "value": 88187
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 30,
                      "data": {
                        "name": "Accounts Payable (A/P)",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 42987.87
                          },
                          {
                            "date": "2023-12",
                            "value": 42334.97
                          },
                          {
                            "date": "2023-11",
                            "value": 72659.47
                          },
                          {
                            "date": "2023-10",
                            "value": 72606.57
                          },
                          {
                            "date": "2023-09",
                            "value": 73380.17
                          },
                          {
                            "date": "2023-08",
                            "value": 81070.17
                          },
                          {
                            "date": "2023-07",
                            "value": 103488.27
                          },
                          {
                            "date": "2023-06",
                            "value": 130836.25
                          },
                          {
                            "date": "2023-05",
                            "value": 146114.37
                          },
                          {
                            "date": "2023-04",
                            "value": 135413.13
                          },
                          {
                            "date": "2023-03",
                            "value": 138965.11
                          },
                          {
                            "date": "2023-02",
                            "value": 149604.96
                          },
                          {
                            "date": "2023-01",
                            "value": 138889.16
                          },
                          {
                            "date": "2022-12",
                            "value": 200176.2
                          },
                          {
                            "date": "2022-11",
                            "value": 192700.36
                          },
                          {
                            "date": "2022-10",
                            "value": 163052.24
                          },
                          {
                            "date": "2022-09",
                            "value": 152510.87
                          },
                          {
                            "date": "2022-08",
                            "value": 132155.95
                          },
                          {
                            "date": "2022-07",
                            "value": 114897.93
                          },
                          {
                            "date": "2022-06",
                            "value": 139409.84
                          },
                          {
                            "date": "2022-05",
                            "value": 67200
                          },
                          {
                            "date": "2022-04",
                            "value": 65214
                          },
                          {
                            "date": "2022-03",
                            "value": 54402
                          },
                          {
                            "date": "2022-02",
                            "value": 88187
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 31,
                  "data": {
                    "name": "Credit Card",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 13950.44
                      },
                      {
                        "date": "2023-12",
                        "value": 11423.95
                      },
                      {
                        "date": "2023-11",
                        "value": 9629.24
                      },
                      {
                        "date": "2023-10",
                        "value": 12434.32
                      },
                      {
                        "date": "2023-09",
                        "value": 10727.37
                      },
                      {
                        "date": "2023-08",
                        "value": 11730.48
                      },
                      {
                        "date": "2023-07",
                        "value": 14397.15
                      },
                      {
                        "date": "2023-06",
                        "value": 12014.47
                      },
                      {
                        "date": "2023-05",
                        "value": 13683.47
                      },
                      {
                        "date": "2023-04",
                        "value": 10935.43
                      },
                      {
                        "date": "2023-03",
                        "value": 11374.5
                      },
                      {
                        "date": "2023-02",
                        "value": 12928.32
                      },
                      {
                        "date": "2023-01",
                        "value": 9104.39
                      },
                      {
                        "date": "2022-12",
                        "value": 13339.29
                      },
                      {
                        "date": "2022-11",
                        "value": 13699.41
                      },
                      {
                        "date": "2022-10",
                        "value": 14554.14
                      },
                      {
                        "date": "2022-09",
                        "value": 11851.67
                      },
                      {
                        "date": "2022-08",
                        "value": 12091.14
                      },
                      {
                        "date": "2022-07",
                        "value": 11784.01
                      },
                      {
                        "date": "2022-06",
                        "value": 5623.4
                      },
                      {
                        "date": "2022-05",
                        "value": 8046.66
                      },
                      {
                        "date": "2022-04",
                        "value": 5475.08
                      },
                      {
                        "date": "2022-03",
                        "value": 5148.75
                      },
                      {
                        "date": "2022-02",
                        "value": 1337.21
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 32,
                      "data": {
                        "name": "Silicon Valley Bank (SVB) Credit Card #4745",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 13950.44
                          },
                          {
                            "date": "2023-12",
                            "value": 11423.95
                          },
                          {
                            "date": "2023-11",
                            "value": 9629.24
                          },
                          {
                            "date": "2023-10",
                            "value": 12434.32
                          },
                          {
                            "date": "2023-09",
                            "value": 10727.37
                          },
                          {
                            "date": "2023-08",
                            "value": 11730.48
                          },
                          {
                            "date": "2023-07",
                            "value": 14397.15
                          },
                          {
                            "date": "2023-06",
                            "value": 12014.47
                          },
                          {
                            "date": "2023-05",
                            "value": 13683.47
                          },
                          {
                            "date": "2023-04",
                            "value": 10935.43
                          },
                          {
                            "date": "2023-03",
                            "value": 11374.5
                          },
                          {
                            "date": "2023-02",
                            "value": 12928.32
                          },
                          {
                            "date": "2023-01",
                            "value": 9104.39
                          },
                          {
                            "date": "2022-12",
                            "value": 13339.29
                          },
                          {
                            "date": "2022-11",
                            "value": 13699.41
                          },
                          {
                            "date": "2022-10",
                            "value": 14554.14
                          },
                          {
                            "date": "2022-09",
                            "value": 11851.67
                          },
                          {
                            "date": "2022-08",
                            "value": 12091.14
                          },
                          {
                            "date": "2022-07",
                            "value": 11784.01
                          },
                          {
                            "date": "2022-06",
                            "value": 5623.4
                          },
                          {
                            "date": "2022-05",
                            "value": 8046.66
                          },
                          {
                            "date": "2022-04",
                            "value": 5475.08
                          },
                          {
                            "date": "2022-03",
                            "value": 5148.75
                          },
                          {
                            "date": "2022-02",
                            "value": 1337.21
                          }
                        ]
                      }
                    },
                    {
                      "key": 33,
                      "data": {
                        "name": "Silicon Valley Bank (SVB) Credit Card #5686",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 39,
                  "data": {
                    "name": "Long Term Liability",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 57499.98
                      },
                      {
                        "date": "2023-12",
                        "value": 57499.98
                      },
                      {
                        "date": "2023-11",
                        "value": 55416.65
                      },
                      {
                        "date": "2023-10",
                        "value": 58333.32
                      },
                      {
                        "date": "2023-09",
                        "value": 61249.99
                      },
                      {
                        "date": "2023-08",
                        "value": 64166.66
                      },
                      {
                        "date": "2023-07",
                        "value": 67083.33
                      },
                      {
                        "date": "2023-06",
                        "value": 70000
                      },
                      {
                        "date": "2023-05",
                        "value": 35000
                      },
                      {
                        "date": "2023-04",
                        "value": 35000
                      },
                      {
                        "date": "2023-03",
                        "value": 35000
                      },
                      {
                        "date": "2023-02",
                        "value": 35000
                      },
                      {
                        "date": "2023-01",
                        "value": 35000
                      },
                      {
                        "date": "2022-12",
                        "value": 35000
                      },
                      {
                        "date": "2022-11",
                        "value": 35000
                      },
                      {
                        "date": "2022-10",
                        "value": 35000
                      },
                      {
                        "date": "2022-09",
                        "value": 35000
                      },
                      {
                        "date": "2022-08",
                        "value": 35000
                      },
                      {
                        "date": "2022-07",
                        "value": 35000
                      },
                      {
                        "date": "2022-06",
                        "value": 35000
                      },
                      {
                        "date": "2022-05",
                        "value": 0
                      },
                      {
                        "date": "2022-04",
                        "value": 0
                      },
                      {
                        "date": "2022-03",
                        "value": 0
                      },
                      {
                        "date": "2022-02",
                        "value": 0
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 40,
                      "data": {
                        "name": "Creditors for Capital Assets",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 41,
                      "data": {
                        "name": "KISS",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 0
                          },
                          {
                            "date": "2023-10",
                            "value": 0
                          },
                          {
                            "date": "2023-09",
                            "value": 0
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          },
                          {
                            "date": "2022-05",
                            "value": 0
                          },
                          {
                            "date": "2022-04",
                            "value": 0
                          },
                          {
                            "date": "2022-03",
                            "value": 0
                          },
                          {
                            "date": "2022-02",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 42,
                      "data": {
                        "name": "Loan from Anand Reddy",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 25000
                          },
                          {
                            "date": "2023-10",
                            "value": 25000
                          },
                          {
                            "date": "2023-09",
                            "value": 25000
                          },
                          {
                            "date": "2023-08",
                            "value": 25000
                          },
                          {
                            "date": "2023-07",
                            "value": 25000
                          },
                          {
                            "date": "2023-06",
                            "value": 25000
                          },
                          {
                            "date": "2023-05",
                            "value": 25000
                          },
                          {
                            "date": "2023-04",
                            "value": 25000
                          },
                          {
                            "date": "2023-03",
                            "value": 25000
                          },
                          {
                            "date": "2023-02",
                            "value": 25000
                          },
                          {
                            "date": "2023-01",
                            "value": 25000
                          },
                          {
                            "date": "2022-12",
                            "value": 25000
                          },
                          {
                            "date": "2022-11",
                            "value": 25000
                          },
                          {
                            "date": "2022-10",
                            "value": 25000
                          },
                          {
                            "date": "2022-09",
                            "value": 25000
                          },
                          {
                            "date": "2022-08",
                            "value": 25000
                          },
                          {
                            "date": "2022-07",
                            "value": 25000
                          },
                          {
                            "date": "2022-06",
                            "value": 25000
                          }
                        ]
                      }
                    },
                    {
                      "key": 43,
                      "data": {
                        "name": "Loan From Efficient Capital",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 57499.98
                          },
                          {
                            "date": "2023-12",
                            "value": 57499.98
                          },
                          {
                            "date": "2023-11",
                            "value": 20416.65
                          },
                          {
                            "date": "2023-10",
                            "value": 23333.32
                          },
                          {
                            "date": "2023-09",
                            "value": 26249.99
                          },
                          {
                            "date": "2023-08",
                            "value": 29166.66
                          },
                          {
                            "date": "2023-07",
                            "value": 32083.33
                          },
                          {
                            "date": "2023-06",
                            "value": 35000
                          }
                        ]
                      }
                    },
                    {
                      "key": 44,
                      "data": {
                        "name": "Loan from Kanwar Chadha",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 0
                          },
                          {
                            "date": "2023-11",
                            "value": 10000
                          },
                          {
                            "date": "2023-10",
                            "value": 10000
                          },
                          {
                            "date": "2023-09",
                            "value": 10000
                          },
                          {
                            "date": "2023-08",
                            "value": 10000
                          },
                          {
                            "date": "2023-07",
                            "value": 10000
                          },
                          {
                            "date": "2023-06",
                            "value": 10000
                          },
                          {
                            "date": "2023-05",
                            "value": 10000
                          },
                          {
                            "date": "2023-04",
                            "value": 10000
                          },
                          {
                            "date": "2023-03",
                            "value": 10000
                          },
                          {
                            "date": "2023-02",
                            "value": 10000
                          },
                          {
                            "date": "2023-01",
                            "value": 10000
                          },
                          {
                            "date": "2022-12",
                            "value": 10000
                          },
                          {
                            "date": "2022-11",
                            "value": 10000
                          },
                          {
                            "date": "2022-10",
                            "value": 10000
                          },
                          {
                            "date": "2022-09",
                            "value": 10000
                          },
                          {
                            "date": "2022-08",
                            "value": 10000
                          },
                          {
                            "date": "2022-07",
                            "value": 10000
                          },
                          {
                            "date": "2022-06",
                            "value": 10000
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  "key": 34,
                  "data": {
                    "name": "Other Current Liability",
                    "values": [
                      {
                        "date": "2024-01",
                        "value": 288278
                      },
                      {
                        "date": "2023-12",
                        "value": 288367.87
                      },
                      {
                        "date": "2023-11",
                        "value": 217514.97
                      },
                      {
                        "date": "2023-10",
                        "value": 182365.87
                      },
                      {
                        "date": "2023-09",
                        "value": 163779.97
                      },
                      {
                        "date": "2023-08",
                        "value": 103539.87
                      },
                      {
                        "date": "2023-07",
                        "value": 7939.87
                      },
                      {
                        "date": "2023-06",
                        "value": 9936.97
                      },
                      {
                        "date": "2023-05",
                        "value": 15722.95
                      },
                      {
                        "date": "2023-04",
                        "value": 23648.07
                      },
                      {
                        "date": "2023-03",
                        "value": 23264.83
                      },
                      {
                        "date": "2023-02",
                        "value": 21695.81
                      },
                      {
                        "date": "2023-01",
                        "value": 21030.66
                      },
                      {
                        "date": "2022-12",
                        "value": 20787.86
                      },
                      {
                        "date": "2022-11",
                        "value": 17070.9
                      },
                      {
                        "date": "2022-10",
                        "value": 11475.77
                      },
                      {
                        "date": "2022-09",
                        "value": 12896.38
                      },
                      {
                        "date": "2022-08",
                        "value": 12113.36
                      },
                      {
                        "date": "2022-07",
                        "value": 11376.12
                      },
                      {
                        "date": "2022-06",
                        "value": 16427.81
                      },
                      {
                        "date": "2022-05",
                        "value": 11118.13
                      },
                      {
                        "date": "2022-04",
                        "value": 27819.07
                      },
                      {
                        "date": "2022-03",
                        "value": 11359.58
                      },
                      {
                        "date": "2022-02",
                        "value": 13626.26
                      }
                    ]
                  },
                  "children": [
                    {
                      "key": 35,
                      "data": {
                        "name": "Accrued Expense",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 0
                          },
                          {
                            "date": "2023-12",
                            "value": 89.87
                          },
                          {
                            "date": "2023-11",
                            "value": 3336.97
                          },
                          {
                            "date": "2023-10",
                            "value": 3339.87
                          },
                          {
                            "date": "2023-09",
                            "value": 3836.97
                          },
                          {
                            "date": "2023-08",
                            "value": 3439.87
                          },
                          {
                            "date": "2023-07",
                            "value": 3439.87
                          },
                          {
                            "date": "2023-06",
                            "value": 3436.97
                          },
                          {
                            "date": "2023-05",
                            "value": 7222.95
                          },
                          {
                            "date": "2023-04",
                            "value": 13148.07
                          },
                          {
                            "date": "2023-03",
                            "value": 13498.83
                          },
                          {
                            "date": "2023-02",
                            "value": 12898.81
                          },
                          {
                            "date": "2023-01",
                            "value": 11942.66
                          },
                          {
                            "date": "2022-12",
                            "value": 10699.86
                          },
                          {
                            "date": "2022-11",
                            "value": 6570.9
                          },
                          {
                            "date": "2022-10",
                            "value": 11475.77
                          },
                          {
                            "date": "2022-09",
                            "value": 12403.24
                          },
                          {
                            "date": "2022-08",
                            "value": 9433.92
                          },
                          {
                            "date": "2022-07",
                            "value": 8877.5
                          },
                          {
                            "date": "2022-06",
                            "value": 12910.01
                          },
                          {
                            "date": "2022-05",
                            "value": 6614.03
                          },
                          {
                            "date": "2022-04",
                            "value": 22295.79
                          },
                          {
                            "date": "2022-03",
                            "value": 4850
                          },
                          {
                            "date": "2022-02",
                            "value": 6097.5
                          }
                        ]
                      }
                    },
                    {
                      "key": 36,
                      "data": {
                        "name": "Deferred Income",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 54500
                          },
                          {
                            "date": "2023-12",
                            "value": 54500
                          },
                          {
                            "date": "2023-11",
                            "value": 63700
                          },
                          {
                            "date": "2023-10",
                            "value": 77848
                          },
                          {
                            "date": "2023-09",
                            "value": 58400
                          },
                          {
                            "date": "2023-08",
                            "value": 2500
                          },
                          {
                            "date": "2023-07",
                            "value": 4500
                          },
                          {
                            "date": "2023-06",
                            "value": 6500
                          },
                          {
                            "date": "2023-05",
                            "value": 8500
                          },
                          {
                            "date": "2023-04",
                            "value": 10500
                          },
                          {
                            "date": "2023-03",
                            "value": 6500
                          },
                          {
                            "date": "2023-02",
                            "value": 7500
                          },
                          {
                            "date": "2023-01",
                            "value": 8500
                          },
                          {
                            "date": "2022-12",
                            "value": 9500
                          },
                          {
                            "date": "2022-11",
                            "value": 10500
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 493.14
                          },
                          {
                            "date": "2022-08",
                            "value": 2679.44
                          },
                          {
                            "date": "2022-07",
                            "value": 2498.62
                          },
                          {
                            "date": "2022-06",
                            "value": 3517.8
                          },
                          {
                            "date": "2022-05",
                            "value": 4504.1
                          },
                          {
                            "date": "2022-04",
                            "value": 5523.28
                          },
                          {
                            "date": "2022-03",
                            "value": 6509.58
                          },
                          {
                            "date": "2022-02",
                            "value": 7528.76
                          }
                        ]
                      }
                    },
                    {
                      "key": 37,
                      "data": {
                        "name": "Reimbursement Payable Gokul",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 3578
                          },
                          {
                            "date": "2023-12",
                            "value": 3578
                          },
                          {
                            "date": "2023-11",
                            "value": 4078
                          },
                          {
                            "date": "2023-10",
                            "value": 3578
                          },
                          {
                            "date": "2023-09",
                            "value": 3943
                          },
                          {
                            "date": "2023-08",
                            "value": 0
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 3266
                          },
                          {
                            "date": "2023-02",
                            "value": 1297
                          },
                          {
                            "date": "2023-01",
                            "value": 588
                          },
                          {
                            "date": "2022-12",
                            "value": 588
                          },
                          {
                            "date": "2022-11",
                            "value": 0
                          },
                          {
                            "date": "2022-10",
                            "value": 0
                          },
                          {
                            "date": "2022-09",
                            "value": 0
                          },
                          {
                            "date": "2022-08",
                            "value": 0
                          },
                          {
                            "date": "2022-07",
                            "value": 0
                          },
                          {
                            "date": "2022-06",
                            "value": 0
                          }
                        ]
                      }
                    },
                    {
                      "key": 38,
                      "data": {
                        "name": "Share Application Money pending Allotment",
                        "values": [
                          {
                            "date": "2024-01",
                            "value": 230200
                          },
                          {
                            "date": "2023-12",
                            "value": 230200
                          },
                          {
                            "date": "2023-11",
                            "value": 146400
                          },
                          {
                            "date": "2023-10",
                            "value": 97600
                          },
                          {
                            "date": "2023-09",
                            "value": 97600
                          },
                          {
                            "date": "2023-08",
                            "value": 97600
                          },
                          {
                            "date": "2023-07",
                            "value": 0
                          },
                          {
                            "date": "2023-06",
                            "value": 0
                          },
                          {
                            "date": "2023-05",
                            "value": 0
                          },
                          {
                            "date": "2023-04",
                            "value": 0
                          },
                          {
                            "date": "2023-03",
                            "value": 0
                          },
                          {
                            "date": "2023-02",
                            "value": 0
                          },
                          {
                            "date": "2023-01",
                            "value": 0
                          },
                          {
                            "date": "2022-12",
                            "value": 0
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              "key": 192,
              "data": {
                "name": "netAssets",
                "values": [
                  {
                    "date": "2023-12",
                    "value": 86330.43
                  },
                  {
                    "date": "2024-01",
                    "value": 119633.8
                  },
                  {
                    "date": "2023-11",
                    "value": 137367.41
                  },
                  {
                    "date": "2023-06",
                    "value": 167260.62
                  },
                  {
                    "date": "2023-10",
                    "value": 170049.61
                  },
                  {
                    "date": "2023-09",
                    "value": 194468.98
                  },
                  {
                    "date": "2023-08",
                    "value": 131699.55
                  },
                  {
                    "date": "2023-07",
                    "value": 169175.57
                  },
                  {
                    "date": "2023-05",
                    "value": 167539.35
                  },
                  {
                    "date": "2023-04",
                    "value": 166833.65
                  },
                  {
                    "date": "2023-03",
                    "value": 173630.55
                  },
                  {
                    "date": "2023-02",
                    "value": 183250.02
                  },
                  {
                    "date": "2023-01",
                    "value": 190885.55
                  },
                  {
                    "date": "2022-12",
                    "value": 313296.08
                  },
                  {
                    "date": "2022-11",
                    "value": 260093.02
                  },
                  {
                    "date": "2022-10",
                    "value": 145942.54
                  },
                  {
                    "date": "2022-09",
                    "value": 163548.55
                  },
                  {
                    "date": "2022-08",
                    "value": 186091.22
                  },
                  {
                    "date": "2022-07",
                    "value": 211885.42
                  },
                  {
                    "date": "2022-06",
                    "value": 241946.8
                  },
                  {
                    "date": "2022-05",
                    "value": 323945.57
                  },
                  {
                    "date": "2022-04",
                    "value": 361358.39
                  },
                  {
                    "date": "2022-03",
                    "value": 415538.9
                  },
                  {
                    "date": "2022-02",
                    "value": 459583.68
                  }
                ]
              },
              "children": []
            }
          ]
        }
    ])
  },

  getTreeTableNodes() {
      return Promise.resolve(this.getTreeTableNodesData());
  },
};

