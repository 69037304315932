import React, { useState, useEffect } from "react";
import { Box, useColorModeValue, SimpleGrid, Flex, Text, Select,transition, Button, Icon} from "@chakra-ui/react";
import { CgRemoveR } from "react-icons/cg";
import { useMediaQuery } from "@chakra-ui/react";
import { MdOutlineFilterAltOff, MdOutlineFilterAlt } from 'react-icons/md';
import FixedHeaderTop from "components/fixedHeaderDecisionExtend/FixedHeaderTop";
import MainTable from "./components/MainTable";
import {columnsDataComplex} from "views/admin/decisionsFolder/transactions/variables/columnsData";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Period from "components/period/Period";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import moment from "moment";
import CustomSelect from 'components/customSelect/CustomSelect'
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles'
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { useDispatch, useSelector } from "react-redux";
import { localStorageKeys } from "utils/localStorageKeys";
import { getTransactionCategories } from "redux/actions/infoAction";
import { transactionTypeOptions, pendingStatusOptions, TRANSACTION_DATE } from "utils/constants";
import EclCalenderRange from 'components/eclCalender/eclCalenderRange';
import Pagination from "components/pagination/pagination";
import SelectAmount from 'components/popUps/SelectAmount';
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import NoResults from "components/errorStates/NoResults";
import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

export default function Transactions({onDecisionTransactionChange}) {

  const { selectedDecisions, transactionCategories, dateTypeFilter } = useSelector(state => state.infoReducer)

  const dispatch = useDispatch();

  const location = useLocation();

  const [content, setContent] = useState([])

  const [contentTable, setContentTable] = useState([])

  const [decisionId, setDecisionId] = useState("");

  const [months, setMonths] = useState('')

  const [decisionRunDate, setDecisionRunDate] = useState('')

  const [search, setSearch] = useState('');

  const [transTypeOptions, setTransTypeOptions] = useState('');

  const [selectedTransType, setSelectedTransType] = useState('');

  const [autoCategoriesOptions, setAutoCategoriesOptions] = useState([])

  const [selectedAutoCategory, setSelectedAutoCategory] = useState('')

  const [pendingStatus, setPendingStatus] = useState('')

  const [selectedPendingStatus, setSelectedPendingStatus] = useState('')

  const [startAmount, setStartAmount] = useState('')

  const [endAmount, setEndAmount] = useState('')

  const [transDateStart, setTransDateStart] = useState('')

  const [transDateEnd, setTransDateEnd] = useState('')

  const [activeAppId, setActiveAppId] = useState("");

  const [sorting, setSorting] = useState("");

  const resultsPerPageList = [10, 20, 30, 40, 50]

  const defaultResultsPerPage = 10

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(10);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [fromAmt, setFromAmt] = useState(null)

  const [toAmt, setToAmt] = useState(null)

  const [showFilter, setShowFilter] = useState(true)

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'all', label: "All" },{ value: 'plaid', label: "Plaid" },{ value: "teller", label: "Teller" }])

  const [dataSource, setDataSource] = useState([])

  const [isSmallerThan1300] = useMediaQuery("(max-width: 1300px)");

  const [lastRefreshAt, setLastRefreshAt] = useState("")

  const [height, setHeight] = useState(0);




  const [accountNoOptionsStore, setAccountNoOptionsStore] = useState([])

  const [accountNoOptions, setAccountNoOptions] = useState(accountNoOptionsStore)

  const [selectedAccountNo, setSelectedAccountNo] = useState([{ value: '', label: "All" }])


  const handleDataSourceSelectChange = (selectedOption) => {
    if (selectedOption.value !== dataSource.value) {
      setDataSource(selectedOption);

      if (selectedOption.value === "all") {

        setAccountNoOptions(accountNoOptionsStore);
      } else {
        const filteredAccounts = accountNoOptionsStore.filter(option => option.dataSource === selectedOption.value);
        const filteredOptionsWithAll = [{ label: "All", value: " " }, ...filteredAccounts];
        setAccountNoOptions(filteredOptionsWithAll);
      }
      if (selectedAccountNo.value !== " " && selectedAccountNo.value !== selectedOption.value) {
        setSelectedAccountNo({ label: "All", value: " " });
      }
    }
  };


  const handleAccountNoSelectChange = (selectedOption) => {
    if (selectedOption.value === 'all') {
      setSelectedAccountNo({ label: 'All', value: ' ' }); // Change value to ' '
      setDataSource({ label: 'All', value: 'all' });
    } else {
      setSelectedAccountNo(selectedOption);
      // // Fetch the corresponding data source based on the selected account number
      // const dataSource = accountNoOptionsStore.find(option => option.value === selectedOption.value)?.dataSource;
      // if (dataSource) {
      //   setDataSource({ label: dataSource.charAt(0).toUpperCase() + dataSource.slice(1), value: dataSource });
      // }
    }
  };



  const fetchAccounts = () => {

    service.get(`${apiendpoints?.APPLICATIONS}/${activeAppId}/bank-accounts?data_source=all`).then((res) => {
      const { content} = res
      let accountList = []
      accountList.push({ label: "All", value: "all" });

      content && content.length > 0 && content.map((item) => {
        accountList.push({label: item?.account_number, value: item?.account_number, dataSource: item?.source})
      })
      setAccountNoOptionsStore(accountList)

      setError(false)


    }).catch(error => {

      setError(true)
    })
  }



  const fetchData = () => {
    setLoader(true)
    service.get(`${apiendpoints?.DECISIONS_TRANSACTION}/${decisionId}/transaction?sort_by=${sorting}&search=${search}&category=${selectedAutoCategory ? selectedAutoCategory.value : ''}&trans_type=${selectedTransType ? selectedTransType.value : ''}&pending_status=${selectedPendingStatus ? selectedPendingStatus.value : ""}&start_amount=${startAmount}&end_amount=${endAmount}&trans_date_start=${transDateStart}&trans_date_end=${transDateEnd}&page=${currentPage}&per_page_items=${resultsPerPage}&data_source=${dataSource?.value ? dataSource?.value : ""}&account_number=${selectedAccountNo.value ? selectedAccountNo.value : ""}&account_data_source=${selectedAccountNo.dataSource ? selectedAccountNo.dataSource : ""}`).then((res) => {
      const { content, content: { count, current_page, next_page, previous_page, total_pages } } = res
      setLastRefreshAt(moment.utc(content?.last_refreshed_date).format("DD MMM YYYY,  h:mm A"))
      setLoader(false)
      setContent(content)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setContentTable(content?.transactions)
      setTransTypeOptions(transactionTypeOptions)
      setPendingStatus(pendingStatusOptions)
      setCurrentPage(current_page)

      let periodMonths = content?.period_of_study == null ? "-" : content?.period_of_study
      setMonths(periodMonths)

      let decRunDate = content?.decision_run_date == null ? "-" : content?.decision_run_date
      setDecisionRunDate(moment.utc(decRunDate).format("DD MMM YYYY,  h:mm A"))

      onDecisionTransactionChange(
        {
          decisionId: decisionId,
          search: search,
          category: selectedAutoCategory ? selectedAutoCategory.value : '',
          transType: selectedTransType ? selectedTransType.value : '',
          pendingStatus: selectedPendingStatus ? selectedPendingStatus.value : "",
          startAmount: startAmount  ,
          endAmount: endAmount,
          transDateStart: transDateStart,
          transDateEnd: transDateEnd,
          dataSource: dataSource?.value ? dataSource?.value : "",

        }
      )

    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case TRANSACTION_DATE:
        setTransDateStart(startDate)
        setTransDateEnd(endDate)
        break;
      default:
        setTransDateStart('')
        setTransDateEnd('')
    }
  };

  const handleClearFilter = () => {
    setSearch("")
    setTransDateStart('')
    setTransDateEnd("")
    setStartAmount("")
    setEndAmount("")
    setSelectedTransType('')
    setSelectedAutoCategory('')
    setFromAmt('')
    setToAmt('')
    setSelectedPendingStatus('')
    setDataSource(dataSourceOptions[0])

  }


  const mapOptions = () => {
    const categorySelectOption = transactionCategories?.map((cat) => {
      return {
        label: cat?.category, value: cat?.category
      }
    })
    setAutoCategoriesOptions(categorySelectOption)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedTransType(selectedOption);
  };

  const handleCategorySelectChange = (selectedOption) => {
    setSelectedAutoCategory(selectedOption);
  };

  const handleStatusSelectChange = (selectedOption) => {
    setSelectedPendingStatus(selectedOption);
  };

  const updateAmountFilterValues = (fromAmt, toAmt) => {
    setStartAmount(fromAmt)
    setEndAmount(toAmt)
  }



  useEffect(() => {
    mapOptions()
  }, [transactionCategories])

  useEffect(() => {
    dispatch(getTransactionCategories())
  }, [])

  useEffect(() => {
    setDataSource(dataSourceOptions[0])
  }, [])

  useEffect(() => {
    setActiveAppId(selectedDecisions && selectedDecisions[0]?.application_id)
    setDecisionId(selectedDecisions[0]?.decision_ids[0])
  }, [])

  useEffect(() => {
    var decisionSelected = localStorage.getItem(localStorageKeys?.SELECTED_DECISIONS)
    if (decisionSelected && (JSON.parse(decisionSelected).length > 0)) {
      dispatch(mapSelectedDecisions(JSON.parse(decisionSelected)))
      setActiveAppId(JSON.parse(decisionSelected)[0]?.application_id)
    }
  }, [])

  useEffect(() => {
    if (activeAppId && decisionId) {
      fetchData()
    }
  }, [selectedAutoCategory, dataSource, currentPage, resultsPerPage, decisionId, activeAppId, search, selectedTransType, selectedPendingStatus, startAmount, endAmount, transDateStart, transDateEnd, sorting, selectedAccountNo])

  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, selectedDecisions.length]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height]);


  useEffect(() => {
    if (dataSource && activeAppId ) {
        fetchAccounts();
    }
}, [activeAppId]);

useEffect(() => {
  if(accountNoOptionsStore.length > 0){
    setAccountNoOptions(accountNoOptionsStore)
  }

},[accountNoOptionsStore])





  return (
    <Box id={'paddingTop'} pt={{ base: "140px", md: "140px", xl: "140px" }}>
      <FixedHeaderTop decisionId={decisionId} setDecisionId={setDecisionId} activeAppId={activeAppId} setActiveAppId={setActiveAppId} />
      <Flex alignItems={'center'} justify={'space-between'} my={'20px'}>
        <Period months={months} decisionRunDate={decisionRunDate} />
        <Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
      </Flex>

      {
        showFilter ?

          <Flex justify={'space-between'} gap={'20px'} my={'20px'} width={'100%'} wrap={'wrap'}>
            <Box flex="2" minW={'300px'}><SearchBar search={search} setSearch={setSearch} mb={{ base: '10px', md: 'unset' }} /></Box>

            <Box flex="1">    <CustomSelect
              options={transTypeOptions}
              value={selectedTransType}
              onChange={handleSelectChange}
              placeholder="Type"
              isSearchable={false}
              isClearable={false}
              customStyles={customStyleSelected}
            /></Box>
            <Box flex="1">   <SelectAmount btnText={'Amount'} updateAmountFilterValues={updateAmountFilterValues} fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} /></Box>
            <Box flex="1">  <CustomSelect
              options={autoCategoriesOptions}
              value={selectedAutoCategory}
              onChange={handleCategorySelectChange}
              placeholder="Category"
              isSearchable={false}
              isClearable={false}
              customStyles={customStyleSelected}
            /></Box>
            <Box flex="1" maxW={isSmallerThan1300 ? "180px" : "100%"}>   <CustomSelect
              options={pendingStatus}
              value={selectedPendingStatus}
              onChange={handleStatusSelectChange}
              placeholder="Pending Status"
              isSearchable={false}
              isClearable={false}
              customStyles={customStyleSelected}
            /></Box>
            <Box flex="1" maxW={isSmallerThan1300 ? "150px" : "100%"}><EclCalenderRange onDateRangeSelect={handleDateRangeSelect} btnText={TRANSACTION_DATE} /></Box>
            <Box flex="1"> <CustomSelect
              options={dataSourceOptions}
              value={dataSource}
              onChange={handleDataSourceSelectChange}
              placeholder="Data Source"
              isSearchable={false}
              isClearable={false}
              customStyles={customStyleSelected}
            /></Box>
            <Box flex="1"> <CustomSelect
            options={accountNoOptions}
            value={selectedAccountNo}
            onChange={handleAccountNoSelectChange}
            placeholder="All"
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            /></Box>
            <Box ms={'auto'} w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
          </Flex>
          : ""}

      {loader ? <LazyLoader view="table" /> :
        error ? <SomethingWrong /> :
          (content && content.count == 0) || (decisionId === undefined) ? <NoResults dataSource={true}/> : <>

            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
              <MainTable titleData={`Data Last Refreshed At: ${lastRefreshAt}`}  columnsData={columnsDataComplex} tableData={contentTable} handleSortType={handleSortType} />
              <Flex justify="flex-end">
                <Pagination paginationProps={paginationProps} />
              </Flex>
            </SimpleGrid></>
      }

      <ToastContainer/>
    </Box>
  );
}
