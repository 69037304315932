import { Box, Flex, Stack, Button, Icon } from "@chakra-ui/react";
import {EmailIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
import {MdOutlineLogout} from "react-icons/md";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import {ReactComponent as Logout } from "assets/icons/log-out.svg"
import { handleLogout } from "utils/helperFunctions/logout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function SidebarContent(props) {

  const { routes } = props;

  const history = useHistory()

  const dispatch = useDispatch()

  return (
    <Box height={'calc(100vh - 0px)'} bg={'white'} borderBottomRightRadius='15px'>
      <Flex direction='column' height={'calc(100% - 30px)'} pt='25px' ps="15px" pe={'20px'}>
        <Brand />
        <Stack direction='column' mb='auto' mt='8px' h={'100%'} justify={'space-between'}>
          <Box ps='0px'>
            <Links routes={routes} />
          </Box>
          <NavLink to='/auth/sign-in'>
            <Button w={'100%'} className={'logout-btn'} onClick={() => handleLogout(history,dispatch)} leftIcon={<Icon as={MdOutlineLogout} width="20px" height="20px" color="rgba(224, 79, 79, 1)" />} _hover={'nohover'}>Log Out
            </Button>
          </NavLink>
        </Stack>
      </Flex>
    </Box>

  );
}

export default SidebarContent;
