import React from 'react'
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'assets/css/customStyle/EclCalender.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch } from 'react-redux';
import { MdKeyboardArrowDown,} from "react-icons/md";
import { Menu, MenuButton, MenuList, MenuItem, Button, Stack, Box, Text, Flex, Icon} from '@chakra-ui/react'
import {setDateFilter} from 'redux/actions/infoAction'
import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, startOfYear, endOfYear, addYears, format} from 'date-fns';
import { useState } from 'react';
import { DefinedRange } from 'react-date-range';

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -31)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -92)),
    startOfLastSixtyDay: startOfDay(addDays(new Date(), -62)),
    startOfLastSixMonths: startOfDay(addDays(new Date(), -183)),
    startOfLast12Months: startOfDay(addDays(new Date(), -365)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: 'All',
            range: () => ({
                startDate: null,
                endDate: null
            }),
        },

        {
            label: 'This Month',
            range: () => ({
                startDate: defineds.startOfLastThirtyDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last Month',
            range: () => ({
                startDate: defineds.startOfLastSixtyDay,
                endDate: defineds.startOfLastThirtyDay
            })
        },
        {
            label: 'Last Quarter',
            range: () => ({
                startDate: defineds.startOfLastNintyDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last 6 Months',
            range: () => ({
                startDate: defineds.startOfLastSixMonths,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last 12 Months',
            range: () => ({
                startDate: defineds.startOfLast12Months,
                endDate: defineds.endOfToday
            })
        },
    ];
    return customDateObjects;
};


export default function OnlyRangeCalender({btnText, onDateRangeSelect}) {

    const dispatch = useDispatch()

    const handleDateFilterType = () =>{dispatch(setDateFilter(btnText))}

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
            color: 'rgba(0, 0, 0, 0.07)'
        }
    ]);

    const sideBar = sideBarOptions();

    const staticRanges = [ ...createStaticRanges(sideBar)];

    const selectedStartDate = format(state[0].startDate, 'd MMM yyyy');

    const selectedEndDate = format(state[0].endDate, 'd MMM yyyy');


    return (<>
        <Menu flip={false} placement="bottom">
            <MenuButton textAlign={'left'} minW={'150px'} w={'100%'} fontSize='13px' fontWeight='600' color='rgba(0, 0, 0, 0.9)' as={Button} rightIcon={<Icon as={MdKeyboardArrowDown} width="17.5px" height="17.5px" />} className='ecl-border-ui' variant='outline' onClick={handleDateFilterType} >
                {btnText}
            </MenuButton>
            <MenuList p={5} className='ecl-border-ui only-range-calender-container'>
                <DefinedRange className='only-range-calender'
                    onChange={item => {
                        if ((item.selection.startDate && item.selection.endDate) === null) {
                            onDateRangeSelect('', '');
                        } else {
                            const startOfMonth = new Date(item.selection.startDate.getFullYear(), item.selection.startDate.getMonth() + 1, 1);
                            const endOfMonth = new Date(item.selection.endDate.getFullYear(), item.selection.endDate.getMonth() + 1, 0);
                            setState([{ startDate: startOfMonth, endDate: endOfMonth, key: 'selection', color: 'rgba(0, 0, 0, 0.07)' }]);
                            const selectedStartDate = format(startOfMonth, 'yyyy-MM-dd');
                            const selectedEndDate = format(endOfMonth, 'yyyy-MM-dd');
                            onDateRangeSelect(selectedStartDate, selectedEndDate);
                        }
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    staticRanges={staticRanges}
                    showDateDisplay={false} />
            </MenuList>
        </Menu>
    </>
    );
}

