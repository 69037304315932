import { MdCheckCircle, MdCancel, MdOutlineError, MdPending, MdDoNotDisturbOn, MdCreate, MdSearch, MdDoNotDisturb } from "react-icons/md";
import { Icon, Flex, Image } from "@chakra-ui/react";

export const applications_statusOptions = [
  { value: 'Approved', label: <><Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 0.50)" me={'5px'} />Approved</> },
  { value: 'In Progress', label: <><Icon as={MdOutlineError} width="20px" height="20px" color="rgba(254, 198, 1, 1)" me={'5px'} />In Progress</> },
  { value: 'Declined', label: <><Icon as={MdCancel} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />Declined</> },
  { value: 'Pending', label: <><Icon as={MdPending} width="20px" height="20px" color="rgba(195, 183, 229, 1)" me={'5px'} />Pending</> },
  { value: 'Manual Review', label: <><Icon as={MdSearch} width="18px" height="18px" p={'2px'} bg={'rgba(0, 162, 173, 1)'} borderRadius={'50%'} color="rgba(255,255,255)" me={'5px'} />Manual Review</> },
  { value: 'Failed', label: <><Icon as={MdDoNotDisturb} width="20px" height="20px" color="rgba(224, 79, 79, 1)"  me={'5px'} />Failed</> }
];

export const decisionRunTriggeredOptions = [
  { value: 'Bubble', label: "Bubble" },
  { value: 'Underwriter', label: "Underwriter" }
];

export const pendingStatusOptions = [
  { value:"True" , label: 'Yes' },
  { value:"False", label:  'No' }
]
export const pendingStatusOption = [
  { value:"True" , label: 'Yes' },
  { value:"False", label:  'No' }
]

export const transactionTypeOptions = [
  { value: 'Credit', label: "Credit" },
  { value: "Debit", label: "Debit" }
]
export const invoiceStatusOptions = [
  { value: 'Submitted', label: "Submitted" },
  { value: "Unknown", label: "Unknown" },
  { value: "Draft", label: "Draft" },
  { value: "PartiallyPaid", label: "Partially Paid" },
  { value: "Paid", label: "Paid" },
  { value: "Void", label: "Void" },
]
export const invoiceCurrencyOptions = [
  { value: 'GBP', label: "GBP" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
]

export const APPLICATION_DECISION_RUN_DATE = 'Decision Run Date';
export const APPLICATION_CREATED_DATE = 'Created Date';
export const APPLICATION_MODIFIED_DATE = 'Modified Date';
export const DECISION_DATE = 'Decision Date';
export const TRANSACTION_DATE = 'Date';
export const INVOICE_DUE_DATE = 'Due Date';
export const BALANCESHEET_MONTH = 'Filter';
export const PL_STATEMENT_MONTH = 'Filter';


