import React, { useState } from 'react'
import {
    Button, Flex,Text, HStack, Menu, MenuButton, MenuList, MenuItem,Checkbox, MenuDivider} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-red.svg';


export default function FixedSubHeaderTop(props) {

    const { selectedColumnOptions, handleSelectChange, customSelectOptions, setSelectedColumnOptions, setCustomSelectOptions } = props;

    const [update, setUpdate] = useState("")

    const handleCheckboxChange = (index) => {
        const updatedOptions = [...selectedColumnOptions];
        updatedOptions[index].isShow = !updatedOptions[index].isShow;
        setUpdate(updatedOptions);
      };

      const handleApplyFilter = () => {
        setSelectedColumnOptions(update);
      };

    return (<>
        <Flex justify={'flex-start'} width={'100%'} align={'center'} gap={'20px'} >
            <Text as={'span'} className={'label-select'}>
                Columns to View
            </Text>
            <Menu closeOnSelect={false} className='checkbox-menu-dropdown'>
                <MenuButton as={Button} className='ecl-border-ui'   minW={'190px'} maxW={'200px'} rightIcon={<ChevronDownIcon />}>
                  <Text fontSize='13px' fontWeight='13px'>Select</Text>
                </MenuButton>
                <MenuList minW={'190px'} maxW={'200px'} pb={'14px'}>
                    <MenuList minW={'160px'} maxW={'180px'} maxH='140px' overflowY={'auto'} border={'none'} p={2} boxShadow={'none'} >
                        {customSelectOptions?.map((option, index) => {
                            return (<>
                                <MenuItem minH='40px' ps={'0.2rem'} pe={'0.2rem'} style={{ fontSize: "13px" , borderBottom:"1px solid rgba(0, 0, 0, 0.2)", color:"rgba(0, 0, 0, 0.9)"}} >
                                    <Checkbox size={'sm'}  onChange={() => handleCheckboxChange(index)} isChecked={option.isShow} colorScheme='teal' key={option.accessor}>{option.Header.split(' ').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}</Checkbox>
                                </MenuItem>
                            </>)
                        })}
                    </MenuList>
                    <Flex align={'center'} justify={'center'} pt={'4'}>
                        <HStack justifyContent={'center'} gap={3}>
                            <MenuItem minW={'160px'} justifyContent={'center'} className='green-btn' onClick={handleApplyFilter}>Apply Filter</MenuItem>
                        </HStack>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>

    </>
    )
}
