import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Box,useColorModeValue, SimpleGrid,Flex,Text,Select,Image,Icon,Button,useMediaQuery} from "@chakra-ui/react";
import {MdBarChart, MdClose,MdCheck,MdCancel} from "react-icons/md";
import {CheckCircleIcon } from '@chakra-ui/icons'
// import FixedHeaderTop from "views/admin/invoices/components/fixedHeaderInvoice/FixedHeaderTop";
import FixedHeaderTop from "components/fixedHeaderDecisionExtend/FixedHeaderTop";
import MainTable from "./components/MainTable";
import {columnsDataComplex} from "views/admin/invoices/variables/columnsData";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Period from "components/period/Period";
import { useEffect } from "react";
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import Pagination from "components/pagination/pagination";
import NoResults from "components/errorStates/NoResults";
import { getTransactionCategories, setActiveAppsRedux } from "redux/actions/infoAction";
import { INVOICE_DUE_DATE, invoiceStatusOptions , invoiceCurrencyOptions} from 'utils/constants';
import { customStyleSelected } from "components/customSelect/CustomSelectStyles";
import CustomSelect from "components/customSelect/CustomSelect";
import { useLocation } from 'react-router-dom';
import SelectAmount from 'components/popUps/SelectAmount';
import EclCalenderRange from 'components/eclCalender/eclCalenderRange'
import { CgRemoveR } from "react-icons/cg";
import moment from "moment";
import { MdOutlineFilterAltOff, MdOutlineFilterAlt } from 'react-icons/md';


export default function Invoices() {

  const dispatch = useDispatch()

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { selectedDecisions, transactionCategories, dateTypeFilter } = useSelector(state => state.infoReducer)

  const [selected, setSelected] = useState(false);

  const [activeApps, setActiveApps] = useState("");

  const [decisionId, setDecisionId] = useState("");

  const [activeAppId, setActiveAppId] = useState("");

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [search, setSearch] = useState('');

  const resultsPerPageList = [25, 50, 100, 150, 200]

  const defaultResultsPerPage = 25

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(25);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [startAmount, setStartAmount] = useState("");

  const [endAmount, setEndAmount] = useState("");

  const [sorting, setSorting] = useState("")

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'codat', label: "Codat" }])

  const [dataSource, setDataSource] = useState([])

  const [showFilter, setShowFilter] = useState(true)

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [fromAmt, setFromAmt] = useState(null)

  const [toAmt, setToAmt] = useState(null)

  const [months, setMonths] = useState('')

  const [decisionRunDate, setDecisionRunDate] = useState('')

  const [isSmallerThan1300] = useMediaQuery("(max-width: 1300px)");

  const [lastRefreshAt, setLastRefreshAt] = useState("")


  const updateAmountFilterValues = (fromAmt, toAmt) => {
    setStartAmount(fromAmt)
    setEndAmount(toAmt)
  }

  const handleClearFilter = () => {
    setSearch("")
    setStartDate("")
    setEndDate("")
    setStartAmount("")
    setEndAmount("")
    setSelectedStatus('')
    setSelectedCurrency('')
    setFromAmt('')
    setToAmt('')
  }

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const handleSelectChange = (selected) => {
		setSelectedStatus(selected);
	};
  const handleCurrencyChange = (selected) => {
		setSelectedCurrency(selected);
	};

  const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }


  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case INVOICE_DUE_DATE:
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      default:
        setStartDate("");
        setEndDate("");
    }
  };

  const fetchDetails = () => {
    setLoader(true)
    service.get(`${apiendpoints?.DECISIONS}/${decisionId}/codat/invoices?search=${search}&sort_by=${sorting}&status=${selectedStatus?.value ? selectedStatus.value : ''}&start_amount=${startAmount}&end_amount=${endAmount}&currency=${selectedCurrency?.value? selectedCurrency.value : ''}&due_date_start=${startDate}&due_date_end=${endDate}&page=${currentPage}&per_page_items=${resultsPerPage}`).then((res) => {
      const { content, content: { count, current_page, next_page, previous_page, total_pages } } = res
      setContent(content?.invoices)

      let periodMonths = content?.period_of_study
      setMonths(periodMonths)
      let decRunDate = content?.decision_run_date == null ? "-" : content?.decision_run_date
      setDecisionRunDate(moment.utc(decRunDate).format("DD MMM YYYY,  h:mm A"))
      setLastRefreshAt(moment.utc(content?.last_refreshed_date).format("DD MMM YYYY,  h:mm A"))

      setError(false)
      setLoader(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)

    }).catch(error => {
      setLoader(false)
      setError(true)
    })
  }

  useEffect(() => {
    setDataSource(dataSourceOptions[0])
  }, [])


  useEffect(() => {
    if (decisionId) {
      fetchDetails()
    }
  }, [ decisionId, search, sorting, selectedStatus, startDate, endDate, startAmount, endAmount, currentPage, resultsPerPage, selectedCurrency, dataSource?.value])

  useEffect(() => {
    dispatch(getTransactionCategories())
  }, [])

  useEffect(() => {
    setActiveAppId(selectedDecisions && selectedDecisions[0]?.application_id)
    setDecisionId(selectedDecisions[0]?.decision_ids[0])
  }, [])

  useEffect(() => {
    var decisionSelected = localStorage.getItem(localStorageKeys?.SELECTED_DECISIONS)
    if (decisionSelected && (JSON.parse(decisionSelected).length > 0)) {
      dispatch(mapSelectedDecisions(JSON.parse(decisionSelected)))
      setActiveAppId(JSON.parse(decisionSelected)[0]?.application_id)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, selectedDecisions.length]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height]);


  return (
    <Box id={'paddingTop'} pt={{ base: "180px", md: "140px", xl: "140px" }}>
      <FixedHeaderTop   decisionId={decisionId} setDecisionId={setDecisionId} activeAppId={activeAppId} setActiveAppId={setActiveAppId}  fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} activeApps={activeApps} setActiveApps={setActiveApps} showFilter={showFilter} setShowFilter={setShowFilter} onDateRangeSelect={handleDateRangeSelect}  selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} handleClearFilter={handleClearFilter} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} search={search} setSearch={setSearch} updateAmountFilterValues={updateAmountFilterValues} />

      <Flex alignItems={'center'} justify={'space-between'} my={'20px'}>
        <Period months={months} decisionRunDate={decisionRunDate} />
        <Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
      </Flex>


      {
        showFilter ?



      <Flex justify={'space-between'} gap={'20px'} my={'20px'} width={'100%'} wrap={'wrap'}>
        <Box flex="2" minW={'300px'}><SearchBar search={search} setSearch={setSearch} /></Box>
        <Box flex="1">    <SelectAmount btnText={'Total Amount'} updateAmountFilterValues={updateAmountFilterValues} handleClearFilter={handleClearFilter} fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} /></Box>
        <Box flex="1">   <EclCalenderRange onDateRangeSelect={handleDateRangeSelect} btnText={INVOICE_DUE_DATE} /></Box>
        <Box flex="1">  		<CustomSelect
          options={invoiceStatusOptions}
          value={selectedStatus}
          onChange={handleSelectChange}
          placeholder="Status"
          isSearchable={false}
          isClearable={false}
          customStyles={customStyleSelected}
        /></Box>
        <Box flex="1" maxW={isSmallerThan1300 ? "180px" : "100%"}>
          <CustomSelect
            options={invoiceCurrencyOptions}
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            placeholder="Currency"
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
          /></Box>
        <Box flex="1" maxW={isSmallerThan1300 ? "150px" : "100%"}>        <CustomSelect
          options={dataSourceOptions}
          value={dataSource}
          onChange={(data) => setDataSource(data)}
          placeholder=""
          isSearchable={false}
          isClearable={false}
          customStyles={customStyleSelected}
        // isDisabled={false}
        /></Box>
        <Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
      </Flex> :""}

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content?.length == 0 || decisionId == undefined ? <NoResults dataSource={true}/> :
              <>
                <MainTable titleData={`Data Last Refreshed At: ${lastRefreshAt}`}  handleSortType={handleSortType} columnsData={columnsDataComplex} tableData={content && content?.length > 0 ? content : []}/>
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
    </Box>
  );
}
