import {Flex,Table,Tbody,Td,Text,Th,Thead,Tr,useColorModeValue,Checkbox} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {useGlobalFilter,usePagination,useSortBy,useTable,} from "react-table";
import { TriangleUpIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import moment from "moment";
import { getCurrencyValueWithoutDollar } from "utils/helperFunctions/getCurrencyValue";
import {withoutDollarWithoutDecimal} from "utils/helperFunctions/processValue";
import Modal from "components/popUps/Modal";

export default function MainTable(props) {

  const {titleData, columnsData, tableData, selected, selectedValues, setSelectedValues, onSelectedValuesChange, handleSortType } = props;

  const [isHovered, setIsHovered] = useState(false);

  const [selectedDescription, setSelectedDescription] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState("Description");

  // State for non-matching custom category values
  const [nonMatchingCustomCategoryValues, setNonMatchingCustomCategoryValues] = useState([]);

  const handleDescriptionCellClick = (cellValue,cell) => {
    setSelectedDescription(cellValue);
    setIsModalOpen(true);
    setModalTitle(cell?.column?.Header)
  };

  const handleCloseModal = () => {
    setSelectedDescription(null);
    setIsModalOpen(false);
    setModalTitle(null)
  };

  const columns = useMemo(() => columnsData, [columnsData]);

  const handleCheckboxChange = (e, value, source, cellValue) => {

    const currentValues = source === "Plaid" ? selectedValues?.plaid : selectedValues?.teller;
    const onChangeValues = e.target.checked
      ? source === "Plaid"
        ? [...currentValues, value]
        : [...currentValues, value]
      : source === "Plaid"
        ? currentValues?.filter(item => item !== value)
        : selectedValues?.teller.filter(item => item !== value);
    const updatedSelectedValues = source === "Plaid"
      ? { plaid: onChangeValues, teller: selectedValues.teller }
      : { plaid: selectedValues?.plaid, teller: onChangeValues };
    // setSelectedValues(updatedSelectedValues);
    onSelectedValuesChange(updatedSelectedValues);
  };

  const title = useMemo(() => titleData, [titleData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      title,
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 500;

  const textColor = useColorModeValue("rgba(0, 0, 0, 0.4)", "white");

  const textColorCheckbox = useColorModeValue("rgba(0,0, 0, 1)", "white");

  function processValue(value) {
    switch (true) {
        case value === 0:
            return '0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '0' :  withoutDollarWithoutDecimal(value);
    }
}

  // useEffect to calculate non-matching custom category values
  useEffect(() => {
    let categoryCellValues = [];
    let categoryCustomCellValues = [];

    // Iterate over each row in the page
    page.forEach(row => {
      const categoryCell = row?.cells?.find(c => c.column.Header === "CATEGORY");
      const categoryCustomCell = row?.cells?.find(c => c.column.Header === "CUSTOM CATEGORY");

      // Push value of "CATEGORY" cell to array
      categoryCellValues.push(categoryCell?.value);

      // Push object with value and index to array
      categoryCustomCellValues.push({ value: categoryCustomCell?.value, index: categoryCustomCellValues.length });
    });

    let nonMatchingCustomCategoryValues = [];

    // Compare "CATEGORY" and "CUSTOM CATEGORY" values
    for (let i = 0; i < categoryCellValues.length; i++) {

      // If values do not match
      if (categoryCellValues[i] !== categoryCustomCellValues[i].value) {
        nonMatchingCustomCategoryValues.push({
          index: categoryCustomCellValues[i].index,
          value: categoryCustomCellValues[i].value
        });
      }
    }

    // Update state with non-matching values
    setNonMatchingCustomCategoryValues(nonMatchingCustomCategoryValues);
  }, [page]); // Depend on the 'page' variable to trigger recalculation when page changes

  return (<>
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflow={{ sm: "auto", lg: "auto" }}
    >

{title?
        <Flex className="table-header">
        <Text fontSize={'13px'} className="last-refresh-at">{title} </Text>
      </Flex> :""}


      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' borderTop={'1px solid rgb(0, 0, 0, 0.1)'}>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  // {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header"
                  key={index}
                  maxW={column.Header === "DESCRIPTION" ? "230px" : column.Header === "TRANSACTION ID" ? "200px" : ""}
                  borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <Flex className="table-header-text">{column.render("Header")}
                    {["","S. No.","ACCOUNT NO","DESCRIPTION","CURRENCY","CUSTOM CATEGORY"].includes(column.Header) ? null :
                      <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                        <Flex direction={'column'} pl={'0px'}>
                          <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                          <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                        </Flex>
                      </span>}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const isLastCell = index === row.cells.length - 1;
                  if (cell.column.Header === "CUSTOM CATEGORY") {
                    var isNonMatching = nonMatchingCustomCategoryValues.some(item => item.index === cell.row.index);
                    data = (
                      <Flex align='center'>
                        {selected && <Checkbox
                          colorScheme='teal'
                          me='10px'
                          onChange={(e) => handleCheckboxChange(e, cell.row?.original?.id, cell?.row?.values?.source, cell.value)}
                        />}
                        <Text color={selected ? 'rgba(0, 162, 173, 1)' : (isNonMatching ? textColorCheckbox : textColor)} >
                          {cell?.value == null ? "-" : cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TRANSACTION ID") {
                    data = (<Flex align='center' justify={'space-between'}>
                      <Flex align='center' maxW={'100px'} >
                        <Text me='10px' maxW={'100%'} overflow="hidden"
                          textOverflow="ellipsis" >
                          {cell.value}
                        </Text>
                      </Flex>
                      <TriangleUpIcon w={2.5} h={2.5} color={'#00A2AD'} title="Click to Full View" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => handleDescriptionCellClick(cell.value,cell)} />
                    </Flex>);
                  } else if (cell.column.Header === "DATE") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "PENDING STATUS") {
                    data = (
                      <Flex align='center'>
                        <Text me='10px' >
                          {cell.value  ? "Yes" : "No"}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "DESCRIPTION") {
                    data = (<Flex align='center' justify={'space-between'}>
                      <Flex align='center' maxW={'230px'}>
                        <Text me='10px' maxW={'100%'} overflow="hidden"
                          textOverflow="ellipsis" >
                          {cell.value}
                        </Text>
                      </Flex>
                      <TriangleUpIcon w={2.5} h={2.5} color={'#00A2AD'} title="Click to Full View" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => handleDescriptionCellClick(cell.value,cell)} />
                    </Flex>);
                  } else if (cell.column.Header === "AMOUNT") {
                    data = (
                      <Text textAlign={'right'}>
                        {cell?.value == null  ? "-" : processValue(cell?.value)}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === "AMOUNT LOCAL") {
                    data = (
                      <Text textAlign={'right'}>
                        {cell?.value == null ? "-" : processValue(Math.round((cell?.value)))}
                      </Text>
                    );
                  } else {
                    data = (
                      <Text>
                        {cell?.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      className="table-cell"
                      maxW={cell.column.Header === "DESCRIPTION" ? "280px" : cell.column.Header === "TRANSACTION ID" ? "140px" : ""}
                      borderRight={isLastCell ? "transparent" : "1px solid #E0E8f0"}
                      bg={selected ? "" : (isNonMatching ? "green.100" : "")}
                      >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
    <Modal description={selectedDescription} onClose={handleCloseModal} title={modalTitle} />
  </>);
}
