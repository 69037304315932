import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/Common.css'
import 'assets/css/App.css';
import {BrowserRouter as Router,Switch,Route,Redirect,Link} from "react-router-dom";
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin/index';
import ApplicationExtendLayout from 'layouts/application/applicationExtend';
import DecisionExtendLayout from 'layouts/decision/decisionExtend.js';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import Auth from './utils/helperFunctions/authGuard'
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { Provider } from 'react-redux';
import store from './redux/store'

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<Provider store={store}>
				<ThemeEditorProvider>
					<Router>
						<Switch>
							<Route path={`/auth`} component={AuthLayout} />
							<Route path={`/decision`} component={Auth(DecisionExtendLayout)} />
							<Route path={`/application`} component={Auth(ApplicationExtendLayout)} />
							<Route path={`/`} component={Auth(AdminLayout)} />
							<Redirect from='/' to='/' />
						</Switch>
					</Router>
				</ThemeEditorProvider>
			</Provider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
