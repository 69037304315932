import { Box, Flex, SimpleGrid,} from "@chakra-ui/react";
import FixedHeaderTop from "./components/fixedHeaderApplications/FixedHeaderTop";
import React,{ useState, useEffect} from "react";
import {columnsDataComplex} from "views/admin/applications/variables/columnsData";
import MainTable from "./components/MainTable";
import LazyLoader from 'components/lazyLoader/LazyLoader'
import NoResults from 'components/errorStates/NoResults'
import SomethingWrong from "components/errorStates/SomethingWrong";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from 'utils/apiendpoints'
import Pagination from "components/pagination/pagination";
import { APPLICATION_DECISION_RUN_DATE } from "utils/constants";
import { APPLICATION_CREATED_DATE } from "utils/constants";
import { APPLICATION_MODIFIED_DATE } from "utils/constants";
import { mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useLocation } from 'react-router-dom';


export default function Applications() {

  const { dateTypeFilter } = useSelector(state => state.infoReducer);

  const dispatch = useDispatch();

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const [showFilter, setShowFilter] = useState(true)

  const [selectedValues, setSelectedValues] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [search, setSearch] = useState('');

  const [status, setStatus] = useState('');

  const resultsPerPageList = [10, 20, 30, 40, 50]

  const defaultResultsPerPage = 10

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(10);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [createdAtStart, setCreatedAtStart] = useState("");

  const [createdAtEnd, setCreatedAtEnd] = useState("");

  const [modifiedAtStart, setModifiedAtStart] = useState("");

  const [modifiedAtEnd, setModifiedAtEnd] = useState("");

  const [decisionRunDateStart, setDecisionRunDateStart] = useState("");

  const [decisionRunDateEnd, setDecisionRunDateEnd] = useState("");

  const [sorting, setSorting] = useState("");

  const [mainTableDataArray, setMainTableDataArray] = useState([]);

  const handleSelectedValuesChange = (globalSelectedValues) => {

    dispatch(mapSelectedApplications(globalSelectedValues))
    setSelectedValues(globalSelectedValues);
    localStorage.setItem(localStorageKeys.SELECTED_APPLICATIONS,JSON.stringify(globalSelectedValues))
  };

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case APPLICATION_CREATED_DATE:
        setCreatedAtStart(startDate);
        setCreatedAtEnd(endDate);
        break;
      case APPLICATION_MODIFIED_DATE:
        setModifiedAtStart(startDate);
        setModifiedAtEnd(endDate);
        break;
      case APPLICATION_DECISION_RUN_DATE :
        setDecisionRunDateStart(startDate);
        setDecisionRunDateEnd(endDate);
        break;
      default:
        setCreatedAtStart("");
        setCreatedAtEnd("");
        setModifiedAtStart("");
        setModifiedAtEnd("");
        setDecisionRunDateStart("");
        setDecisionRunDateEnd("");
    }
  };


  const fetchData = () => {
    setLoader(true)
    service.get(`${apiendpoints.APPLICATIONS}?search=${search}&status=${selectedOption? selectedOption.value : ''}&page=${currentPage}&per_page_items=${resultsPerPage}&created_at_start=${createdAtStart}&created_at_end=${createdAtEnd}&modified_at_start=${modifiedAtStart}&modified_at_end=${modifiedAtEnd}&decision_run_date_start=${decisionRunDateStart}&decision_run_date_end=${decisionRunDateEnd}&sort_by=${sorting}`).then((res) => {
      const { content , content : { count, current_page, next_page, previous_page, total_pages} } = res
      setContent(content);
      setLoader(false)
      setError(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)

      if (content?.applications) {
        updateMainTableDataArray(content.applications);
      }
    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }

  const updateMainTableDataArray = (applications) => {
    const data = applications.map((app) => ({
      unique_id: app.record_id,
      id: app.id,
      record_id: app.record_id,
      application_id: app.application_id,
      created_at: app.created_at,
      modified_at: app.modified_at,
      business_name: app.business_name,
      person_name: app.person_name,
      latest_decision_run_date: app.latest_decision_run_date,
      decision_status: app.decision_status,
    }));
    setMainTableDataArray(data);
  };


  useEffect(() => {
    fetchData();
  }, [search, selectedOption, sorting, currentPage, resultsPerPage,createdAtStart, createdAtEnd, modifiedAtStart, modifiedAtEnd, decisionRunDateStart, decisionRunDateEnd]);




  const updateHeight = () => {
    if (document.getElementById('getHeightApp')) {
      const newHeight = document.getElementById('getHeightApp').offsetHeight;
      setHeight(newHeight);
    }
  };

  setTimeout(() => {
    updateHeight();
  }, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateHeight();

  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTopApp');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 35}px 0 0 0`;
    }
  }, [height, showFilter]);

  return (<>
    <Box id={'paddingTopApp'} pt={{ base: "180px", md: "140px", xl: "140px" }}>
      <FixedHeaderTop showFilter={showFilter} setShowFilter={setShowFilter}
      onDateRangeSelect={handleDateRangeSelect} selectedOption={selectedOption} setSelectedOption={setSelectedOption} status={status} setStatus={setStatus} search={search} setSearch={setSearch} selectedValues={selectedValues} handleSelectedValuesChange={handleSelectedValuesChange}
      setCreatedAtStart={setCreatedAtStart}
      setCreatedAtEnd={ setCreatedAtEnd}
      setModifiedAtStart={setModifiedAtStart}
      setModifiedAtEnd={setModifiedAtEnd}
      setDecisionRunDateStart={setDecisionRunDateStart}
      setDecisionRunDateEnd={setDecisionRunDateEnd}/>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults /> :
              <>
                <MainTable setSorting={setSorting} selectedValues={selectedValues} setSelectedValues={setSelectedValues} onSelectedValuesChange={handleSelectedValuesChange} columnsData={columnsDataComplex} tableData={mainTableDataArray} handleSortType={handleSortType} />
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
    </Box>
  </>);
}
