import React from "react";
import { Image, Text, Flex } from "@chakra-ui/react";
import ErrorState from 'assets/img/states/error.svg'

export default function SomethingWrong() {

    return (<>
        <Flex direction={'column'} alignItems={'center'} justify={'center'} bg={'white'} height={'calc(100vh - 295px)'} borderRadius={'15px'}>
            <Image src={ErrorState} w={'130px'} h={'100px'} />
            <Text fontSize={'1.5rem'} as={'strong'}>Something went wrong!</Text>
            <Text fontSize={'1.5rem'}>Please refresh the page and check again.</Text>
        </Flex>
    </>);
}
