import React from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
// import { TestData } from '../variables/TestData';
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import { Icon } from "@chakra-ui/react";
import moment from "moment";
import { getCurrencyValue } from "utils/helperFunctions/getCurrencyValue";


export default function MainTable({nodes, setNodes, startDate, endDate, tableTitle}) {

  function processValue(value) {
    switch (true) {
        case value === 0:
            return '$ 0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '$ 0' : getCurrencyValue(roundedValue);
    }
}

  const getMonthColumns = () => {
    if (!nodes || nodes.length === 0 || !nodes[0]?.data?.values) { return null;}

    const firstNode = nodes[0];
    if (!firstNode || !firstNode.data || !firstNode.data.values) {return null;}
    const columns = [];
    firstNode.data.values.forEach((val) => {
      columns.push(
        <Column key={val.date} field={val.value} header={moment.utc(val.date).format("MMM YYYY")} body={(node) => processValue(node.data?.values?.find((v) => v.date === val.date)?.value) || '-'} />
      );
    });

    return columns;
  };

  const header = <div>Data Last Refreshed At : {moment.utc(tableTitle).format("DD MMM YYYY,  h:mm A")}</div>;
  const togglerTemplate = (node, options) => {
    if (!node) {return null;}

    const expanded = options.expanded;

    return (
      <Button type="button" className="p-treetable-toggler p-link " style={{ marginLeft: options.props.level ===0 ? '0px' : `${parseInt(options.buttonStyle.marginLeft) + 14}px`, visibility: options.buttonStyle.visibility}} tabIndex={-1} onClick={options.onClick}>
        <span aria-hidden="true">
          {expanded ? <Icon as={MdArrowDropDown} transform='rotate(315deg)' w={'22px'} h={'22px'} /> : <Icon as={MdArrowRight} w={'22px'} h={'22px'} />}
        </span>
      </Button>
    );
  };

  const rowClassName = (node) => {
    return { 'no-children-style': ((node.children && node.children.length === 0) || !node.children) };
  }

  return (
    <div className="card" style={{ height: 'calc(100vh - 320px)' }}>
      <TreeTable value={nodes} rowClassName={rowClassName} header={tableTitle? header:""} togglerTemplate={togglerTemplate} tableStyle={{ minWidth: '50rem' }} scrollable
      frozenWidth='24%' scrollHeight="calc(100vh - 380px)">
        <Column className='first-column' field="name" header="" expander frozen></Column>
        {getMonthColumns()}
      </TreeTable>
    </div>
  );
}
