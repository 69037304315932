// Chakra imports
import { Box, useDisclosure,} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
import SidebarSubMenuOne from 'components/sidebarSubMenuOne/SidebarSubMenu';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {decisionRoutes, decisionRunRoutes} from 'routes.js';

import DecisionTransaction from 'views/admin/decisionsFolder/transactions';

export default function DecisionExtend(props) {
	const { ...rest } = props;
	const [ toggleSidebar, setToggleSidebar ] = useState(false);

	const [decisionTransaction, setDecisionTransaction] = useState(null);
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.items && prop.items.length > 0) {
				return prop.items.map((submenu, key) => (
					<Route path={submenu.layout + submenu.path} component={submenu.component} key={key} />
				));
			}
			if (prop.items && prop.items.length === 0) {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}

			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};

	document.documentElement.dir = 'ltr';

	const handleDecisionTransactionChange = (values) => {
		setDecisionTransaction(values)
	}

	return (
		<Box>
			<Box>
				<SidebarContext.Provider value={{toggleSidebar,setToggleSidebar}}>
					<SidebarSubMenuOne routes={decisionRunRoutes} display='none' {...rest} decisionTransaction={decisionTransaction} />
					<Box className='box-right-section-layout'>
						{getRoute() ? (
							<Box mx='auto' p={{ base: '20px', md: '40px' }} pe='20px' minH='100vh' pt='50px'>
								<Switch>
								<Route path="/decision/transaction">
										<DecisionTransaction onDecisionTransactionChange={handleDecisionTransactionChange}/>
									</Route>
									{getRoutes(decisionRunRoutes)}
									<Redirect from='/decision' to='/decision/summary' />
								</Switch>
							</Box>
						) : null}
					</Box>
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
