export const eclColumnsDataRevenue = [
  {
    Header: "Account",
    accessor: "account",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "DATE-MONTH",
    accessor: "month",
  },
  {
    Header: "CREDITS",
    accessor: "credits",
  },
  {
    Header: "DEBITS",
    accessor: "debits",
  },
  {
    Header: "SALES",
    accessor: "sales",
  },
  {
    Header: "EXPENSES",
    accessor: "expenses",
  },
  {
    Header: "BURN",
    accessor: "burn",
  },
];


export const eclColumnsDataDebt = [
  {
    Header: "Account",
    accessor: "account",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "DATE-MONTH",
    accessor: "month",
  },

  {
    Header: "LOAN CREDITS",
    accessor: "loan_credits",
  },

  {
    Header: "LOAN REPAYMENTS",
    accessor: "loan_repayments",
  },
  {
    Header: "CC DEBT",
    accessor: "cc_debt",
  },
  {
    Header: "OD LIMIT",
    accessor: "od_limit",
  },
  {
    Header: "ECL OUTSTANDING",
    accessor: "ecl_outstanding",
  },
];

export const eclRiskColumn = [
  {
    Header: "SCORECARD",
    accessor: "name",
  },
  {
    Header: "METRIC",
    accessor: "metric",
  },
  {
    Header: "SCORE",
    accessor: "score",
  },
  {
    Header: "WEIGHT",
    accessor: "weight",
  },
  {
    Header: "WEIGHTED RANK",
    accessor: "weight_rank",
  },
];


export const eclLineColumn = [
  {
    Header: "LINE ESTIMATE",
    accessor: "name",
  },
  {
    Header: "VALUES",
    accessor: "metric",
  }
]

export const eclBankColumn = [
  {
    Header: "Account",
    accessor: "name",
  },
  {
    Header: "FINAL",
    accessor: "estimate",
  }
]

export const eclDecisionColumn = [
  {
    Header: "Decision Summary",
    accessor: "name",
  },
  {
    Header: "VALUES",
    accessor: "value",
  }
]


export const eclKybColumn = [
  {
    Header: "Institution Name",
    accessor: "institution_name",
  },
  {
    Header: "ACCOUNT NUMBER",
    accessor: "number",
  },
  {
    Header: "ACCOUNT OWNER NAME",
    accessor: "account_owner_name",
  },
  // {
  //   Header: "BANK NAME",
  //   accessor: "name",
  // },
  {
    Header: "KYB STATUS",
    accessor: "status",
  },
  {
    Header: "KYB LEVEL",
    accessor: "level",
  },
  {
    Header: "ACH PRIORITY",
    accessor: "priority",
  },
];




export const eclDebtAggregation = [
  {
    Header: "DEBT AGGREGATION",
    accessor: "nameD",
  },
  {
    Header: "VALUE",
    accessor: "valueD",
  }
];

export const eclRevenueAggregation = [
  {
    Header: "REVENUE AGGREGATION",
    accessor: "nameC",
  },
  {
    Header: "VALUE",
    accessor: "valueC",
  }
];

export const eclDebtFromBank = [
  {
    Header: "DEBT FROM BANK",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }
];

export const eclRevenueFromBank = [
  {
    Header: "REVENUE FROM BANK",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }
];


export const eclDebtFromCodat = [
  {
    Header: "DEBT FROM CODAT",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }
];

export const eclRevenueFromFinancials = [
  {
    Header: "REVENUE FROM FINANCIALS",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }
];

export const eclRevenueFromInvoices = [
  {
    Header: "REVENUE FROM INVOICES",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }
];

export const duplicationRulesColumns = [
  {
    Header: "RULE",
    accessor: "rule",
  },
  {
    Header: "VALUES",
    accessor: "values",
  },
  {
    Header: "DB HIT COUNT",
    accessor: "db_hit_count",
  },
  {
    Header: "MATCHING INSTANCES",
    accessor: "matching_instances",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
];
export const valueThresholdRulesColumns = [
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "RULES",
    accessor: "rules",
  },
  {
    Header: "VARIABLE SELECTED",
    accessor: "variable_selected",
  },
  {
    Header: "EXPECTED VALUE",
    accessor: "expected_value",
  },
  {
    Header: "ACTUAL VALUE",
    accessor: "actual_value",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
];