export const columnsDataComplex = [
  {
    Header: "",
    accessor: "unique_id",
  },
  {
    Header: "APPLICATION ID",
    accessor: "application_id",
  },
  {
    Header: "RECORD ID",
    accessor: "record_id",
  },
  {
    Header: "BUSINESS NAME",
    accessor: "business_name",
  },
  {
    Header: "CUSTOMER NAME",
    accessor: "person_name",
  },
  {
    Header: "CREATED AT",
    accessor: "created_at",
  },
  {
    Header: "LAST DECISION RUN AT",
    accessor: "latest_decision_run_date",
  },
  {
    Header: "MODIFIED AT",
    accessor: "modified_at",
  },
  {
    Header: "STATUS",
    accessor: "decision_status",
  },
];
