import { Box } from '@chakra-ui/react';
import SidebarSubMenu from 'components/sidebarSubMenu/SidebarSubMenu';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { applicationRoutes } from 'routes';
import PlaidTransactions from 'views/admin/plaidTransactions';

export default function ApplicationExtend(props) {

	const { ...rest } = props;

	const [toggleSidebar, setToggleSidebar] = useState(false);

	const [plaidValues, setPlaidValues] = useState(null);
	const getRoute = () => {
		if (typeof window !== 'undefined' && window.location) {
			return window.location.pathname !== '/admin/full-screen-maps';
		}
		return false;
	};

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.items && prop.items.length > 0) {
				return prop.items.map((submenu, key) => (
					<Route path={submenu.layout + submenu.path} component={submenu.component} key={key} />
				));
			}
			if (prop.items && prop.items.length === 0) {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}

			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};

	document.documentElement.dir = 'ltr';

	const handlePlaidValuesChange = (values) => {
		setPlaidValues(values);
	  };





	return (
		<Box>
			<Box>
				<SidebarContext.Provider value={{toggleSidebar, setToggleSidebar}}>
					<SidebarSubMenu routes={applicationRoutes} display='none' {...rest} plaidValues={plaidValues} />
					<Box className='box-right-section-layout'>
						{getRoute() ? (
							<Box mx='auto' p={{ base: '20px', md: '40px' }} pe='20px' minH='100vh' pt='50px'>
								<Switch>
									<Route path="/application/plaid">
										<PlaidTransactions onPlaidValuesChange={handlePlaidValuesChange}/>
									</Route>
									{getRoutes(applicationRoutes)}
									<Redirect from='/application' to='/application/plaid' />
								</Switch>
							</Box>
						) : null}
					</Box>
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
