import React, { useState, useEffect } from 'react'
import { Button, Box, Flex, Text, } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-red.svg';
import { useSelector, useDispatch, } from 'react-redux';
import CustomSelect from 'components/customSelect/CustomSelect';
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles'
import { mapSelectedDecisions, mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";

export default function FixedSubHeaderTop({
    setCustomData,
    setCustomRevenueDetails,
    setSelectedCustomRevenuePopupOptions,
    setInputValue,
    setUpdatedValue,
    setMinPercentage,
    setMaxPercentage,

    setBankBasedInputValue,
    setCodatBasedInputValue,
    setDebtCustomInputValue,
    setBankBasedUpdatedValue,
    setCodatBasedUpdatedValue,
    setTotalBasedUpdatedValue

    ,decisionId, setDecisionId, activeAppId, setActiveAppId }) {

    const dispatch = useDispatch()

    const { selectedDecisions, selectedApplications } = useSelector(state => state.infoReducer)

    const getDecisionIdsForApplication = (applicationId) => {
        if (!applicationId) {
            return null
        }
        const selectedDecision = selectedDecisions.find(
            (decision) => decision.application_id === applicationId
        );
        return selectedDecision ? selectedDecision.decision_ids : [];

    };

    const handleAppIdClick = (app) => {
        setActiveAppId(app || "");
    };

    const handleDecisionChange = (selectedDecision) => {
        setDecisionId(selectedDecision?.value);

    };

    const removeApp = (appToRemove) => {
        const updatedApps = selectedDecisions?.filter((selectedApp) => selectedApp.application_id !== appToRemove);
        let newActiveAppId = "";
        if (activeAppId === appToRemove) {
            newActiveAppId = updatedApps.length >= 1 ? updatedApps[0]?.application_id : "";
        } else {
            newActiveAppId = activeAppId;
        }

        dispatch(mapSelectedDecisions(updatedApps));
        setActiveAppId(newActiveAppId);
        localStorage.setItem(localStorageKeys.SELECTED_DECISIONS, JSON.stringify(updatedApps));
    };



    useEffect(() => {
        if (activeAppId && selectedDecisions && selectedDecisions.length > 0) {
            let decisionArray = getDecisionIdsForApplication(activeAppId);
            setDecisionId(decisionArray[0]);
        }
    }, [activeAppId, selectedDecisions]);

    return (<>
        <Flex justify={'space-between'} width={'100%'} align={'center'}>
            {selectedDecisions?.length > 0 ?

                <Flex align={'center'} justify={'space-between'} gap={'10px'} width={'100%'} flexWrap={'wrap'}>

                    <Flex gap={'10px'} align={'center'}>
                        <Text as={'span'} color={'gray.500'}>
                            Selected Applications
                        </Text>

                        {selectedDecisions && selectedDecisions?.map((selectedValue, index) => (
                            <Box key={index} className={selectedValue?.application_id === activeAppId ? 'selected-value-box-ui' : 'not-selected-value-box-ui'} onClick={() => handleAppIdClick(selectedValue && selectedValue?.application_id)}>

                                <Flex p={'4px 10px'} gap={'5px'} align={'center'} justify={'space-between'} >
                                    <Text flex={'1'} textAlign={'center'} as={'span'}> {selectedValue?.application_id} </Text>

                                    {selectedDecisions && selectedDecisions?.length > 1 ? (
                                        <CloseIcon cursor={'pointer'} width={'15px'} height={'15px'} onClick={(e) => { e.stopPropagation(); removeApp(selectedValue?.application_id) }} />) : ""}

                                </Flex>
                            </Box>))}


                    </Flex>

                    <Flex gap={'10px'} align={'center'}>
                        {/* {getDecisionIdsForApplication(activeAppId)?.length > 0 ? (<>
                        <Text as={'span'} color={'gray.500'}> Select Decision Run </Text>
                        <CustomSelect
                            options={
                                activeAppId ? getDecisionIdsForApplication(activeAppId).map((decisionId) => {
                                    return {
                                        label: decisionId,
                                        value: decisionId,
                                    }
                                })
                                    : []
                            }
                            value={{ label: decisionId, value: decisionId }}
                            onChange={handleDecisionChange}
                            placeholder={selectedDecisions[0]?.decision_ids[0] || 'No Decision Ids'}
                            isSearchable={false}
                            isClearable={false}
                            customStyles={customStyleSelected}
                        />

                    </>) : (
                        <Text color={'gray.500'}>No Decision Ids</Text>
                    )} */}

                        {getDecisionIdsForApplication(activeAppId)?.length > 0 ? (
                            <>
                                {getDecisionIdsForApplication(activeAppId).length === 1 ? (<>
                                    <Text as={'span'} color={'gray.500'}>
                                        Selected Decision ID :
                                    </Text>
                                    <Text as={'span'} color={'black'} fontWeight={'600'}>
                                        {getDecisionIdsForApplication(activeAppId)[0]}
                                    </Text>
                                </>) : (
                                    <>
                                        <Text as={'span'} color={'gray.500'}>
                                            Select Decision ID
                                        </Text>
                                        <CustomSelect
                                            options={
                                                activeAppId
                                                    ? getDecisionIdsForApplication(activeAppId).map((decisionId) => {
                                                        return {
                                                            label: decisionId,
                                                            value: decisionId,
                                                        };
                                                    })
                                                    : []
                                            }
                                            value={{
                                                label: decisionId,
                                                value: decisionId,
                                            }}
                                            onChange={handleDecisionChange}
                                            placeholder={selectedDecisions[0]?.decision_ids[0] || 'No Decision Ids'}
                                            isSearchable={false}
                                            isClearable={false}
                                            customStyles={customStyleSelected}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <Text color={'gray.500'}>No Decision IDs</Text>
                        )}
                    </Flex>
                </Flex> :
                <Flex>
                    <Text as={'span'} color={'gray.500'}>
                        Applications Loading...
                    </Text>
                </Flex>

            }
        </Flex>
    </>
    )
}
