import React, { useState } from 'react'
import { MdKeyboardArrowDown,} from "react-icons/md";
import { Menu,MenuItem, MenuButton, MenuList, Button, FormControl, FormLabel, Input, HStack, useColorModeValue, Text, Box , Icon} from '@chakra-ui/react'

export default function SelectAmount(props) {

    const { btnText, updateAmountFilterValues, fromAmt, toAmt, setFromAmt, setToAmt } = props;

    const textColor = useColorModeValue("#00A2AD", "#00A2AD")

    // const [fromAmt, setFromAmt] = useState(null)

    // const [toAmt, setToAmt] = useState(null)

    const handleSubmit =() => {
        updateAmountFilterValues(fromAmt,toAmt)
    }

    return (<>
        <Menu flip={false} placement="bottom-end">
            <MenuButton textAlign={'left'} w={'100%'} fontSize='0.8rem' as={Button} rightIcon={<Icon as={MdKeyboardArrowDown} width="17.5px" height="17.5px"/>} className='ecl-border-ui' variant='outline'>
                {btnText}
            </MenuButton>
            <MenuList p={5} minWidth={'unset'} maxWidth={'410px'}>
                <Text fontSize={'xl'} fontWeight={'semibold'} pb={4} color={textColor}>Select Amount</Text>
                <HStack pb={10} gap={3}>
                    <FormControl >
                        <FormLabel color={textColor}>From</FormLabel>
                        <Input type='number' name='fromAmount' className='ecl-border-ui' value={fromAmt || ''} placeholder='Amount' onChange={(e) => { setFromAmt(e.target.value) }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel color={textColor}>To</FormLabel>
                        <Input type='number' name='toAmount' className='ecl-border-ui' value={toAmt || ''} placeholder='Amount' onChange={(e) => { setToAmt(e.target.value) }} />
                    </FormControl>
                </HStack>
                <HStack justifyContent={'space-between'} gap={3}>
                    {/* <Button onClick={() => handleSubmit()} flex={'1'} className='green-btn'>Apply Filter</Button> */}
                    <MenuItem minW={'150px'} justifyContent={'center'} className='green-btn' onClick={() => handleSubmit()} >Apply Filter</MenuItem>
                </HStack>
            </MenuList>
        </Menu>
    </>)
}
