import React from 'react'
import { Modal, ModalOverlay,  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button, FormControl, FormLabel, Input, HStack, useColorModeValue, Text
} from '@chakra-ui/react'

export default function DesModal({ description, onClose, title }) {

    const { isOpen, onOpen, } = useDisclosure()

    const initialRef = React.useRef(null)

    const finalRef = React.useRef(null)

    const textColor = useColorModeValue("#00A2AD", "#00A2AD")

    return (<>
        <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={!!description} onClose={onClose} >
            <ModalOverlay />
            <ModalContent m={'auto'}>
                <ModalHeader color={textColor}>{title}</ModalHeader>
                <ModalCloseButton size='sm' color="red" border={'1px solid red'} borderRadius={'0'} fontSize={'8px'} _hover={'none'} h={'16px'} w={'16px'} />
                <ModalBody pb={6}>
                    <Text>
                        {description}
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}
