import React, { useState } from 'react';
import {routes} from 'routes.js';
import { Portal, Box, useDisclosure, Text, Button, Link, useColorModeValue, Flex,  Icon } from '@chakra-ui/react';
// import Navbar from 'components/navbar/NavbarPerson';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import CustomSelect from 'components/customSelect/CustomSelect'
import {customStyleSelected} from 'components/customSelect/CustomSelectStyles'
import {MdOutlineFilterAltOff, MdOutlineFilterAlt} from "react-icons/md";
import { CgRemoveR } from "react-icons/cg";

function FixedHeaderTop(props) {

	const {showFilter, setShowFilter, selectedValues, personSearch, setPersonSearch ,      
	idTypesOption,
	selectedIdTypesOption,
	setSelectedIdTypesOption,
	idCountryOption,
	selectedIdCountryOption,
	setSelectedIdCountryOption,
	idNumberOption,
	selectedIdNumberOption,
	setSelectedIdNumberOption,

	selectedColumnOptions,
	setSelectedColumnOptions,
	customSelectOptions,
	setCustomSelectOptions,


} = props;



	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	
	let mainText = useColorModeValue('cbrand.500', 'white');
	const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }

	const handleIdTypesChange = (selectedOption) => {
		setSelectedIdTypesOption(selectedOption);
	};

	const handleIdCountryChange = (selectedOption) => {
		setSelectedIdCountryOption(selectedOption);
	};

	const handleIdNumberChange = (selectedOption) => {
		setSelectedIdNumberOption(selectedOption);
	};

	const handleClearFilter = () => {
		setSelectedIdTypesOption("");
		setSelectedIdCountryOption("");
		setSelectedIdNumberOption("")
		setPersonSearch('')
	};




	return (
		<Portal> 
			<Box id={'getHeight'} className='box-fixed-header-container'>
				{/* <Navbar
					brandText={getActiveRoute(routes)}
					personSearch={personSearch}
					setPersonSearch={setPersonSearch}
					idTypesOption={idTypesOption}
					selectedIdTypesOption={selectedIdTypesOption}
					setSelectedIdTypesOption={setSelectedIdTypesOption}
					idCountryOption={idCountryOption}
					selectedIdCountryOption={selectedIdCountryOption}
					setSelectedIdCountryOption={setSelectedIdCountryOption}
					idNumberOption={idNumberOption}
					selectedIdNumberOption={selectedIdNumberOption}
					setSelectedIdNumberOption={setSelectedIdNumberOption}
					showFilter={showFilter} setShowFilter={setShowFilter}
				/> */}


				<Flex justify={'space-between'} align={'center'} width={'100%'}>
					<Box mb={{ sm: '8px', md: '0px' }} me={'auto'}>
						<Text color={mainText} bg='inherit' fontWeight='bold' fontSize='28px'>
							{getActiveRoute(routes)}
						</Text>
					</Box>
					<Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
				</Flex>
				{
					showFilter ? <Flex justify={'space-between'} gap={'10px'} width={'100%'}>
						<Box flex="2"><SearchBar search={personSearch} setSearch={setPersonSearch} mb={{ base: '10px', md: 'unset' }} /></Box>
						<Box flex="1">
							<CustomSelect
								options={idTypesOption}
								value={selectedIdTypesOption}
								onChange={handleIdTypesChange}
								placeholder="ID Type"
								isSearchable={false}
								isClearable={false}
								customStyles={customStyleSelected}
							/>
						</Box>
						<Box flex="1">
							<CustomSelect
								options={idNumberOption}
								value={selectedIdNumberOption}
								onChange={handleIdNumberChange}
								placeholder="ID Number"
								isSearchable={false}
								isClearable={false}
								customStyles={customStyleSelected}
							/>
						</Box>
						<Box flex="1">
							<CustomSelect
								options={idCountryOption}
								value={selectedIdCountryOption}
								onChange={handleIdCountryChange}
								placeholder="ID Country"
								isSearchable={false}
								isClearable={false}
								customStyles={customStyleSelected}
							/>
						</Box>
						<Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
					</Flex> : ""
				}

				<hr className='divider' />
				<FixedSubHeaderTop selectedValues={selectedValues} setCustomSelectOptions={setCustomSelectOptions} setSelectedColumnOptions={setSelectedColumnOptions} selectedColumnOptions={selectedColumnOptions} customSelectOptions={customSelectOptions} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop