import React, { useState } from "react";
import { Image, Text, Flex } from "@chakra-ui/react";
import EmptyState from 'assets/img/states/empty.svg'

export default function NoResults({
    dataSource
}) {

    return (<>
        <Flex alignItems={'center'} justify={'center'} bg={'white'} height={'calc(100vh - 250px)'} borderRadius={'15px'} direction={'column'} >
            <Image src={EmptyState} w={'150px'} h={'100px'} />

            {
                dataSource && dataSource ?
                    <Text fontSize={'1.5rem'} mt={5} mb={8}>
                        <Text as={'strong'}>No Records Found!</Text> Please try a different data source.</Text>

                    : <Text fontSize={'1.5rem'} mt={5} mb={8}><Text as={'strong'}>No Results Found!</Text> Please try again.</Text>

            }
        </Flex>
    </>);
}
