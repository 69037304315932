export const columnsDataComplex = [
  {
    Header: "S. No.",
    accessor: "id",
  },
  {
    Header: "SOURCE",
    accessor: "source",
  },
  {
    Header: "ACCOUNT NO",
    accessor: "account_number",
  },
  {
    Header: "TRANSACTION ID",
    accessor: "transaction_id",
  },
  {
    Header: "DATE",
    accessor: "date_transacted",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "PENDING STATUS",
    accessor: "is_pending",
  },
  {
    Header: "TYPE",
    accessor: "trans_type",
  },
  {
    Header: "CURRENCY",
    accessor: "iso_currency_code",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "AMOUNT LOCAL",
    accessor: "amount_local",
  },
  {
    Header: "CATEGORY",
    accessor: "auto_category",
  },
  {
    Header: "CUSTOM CATEGORY",
    accessor: "manual_category",
  },
];