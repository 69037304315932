import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Checkbox } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable, } from "react-table";
import { TriangleUpIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import Pagination from "components/pagination/pagination";
import moment from "moment";
import { getCurrencyValue, getCurrencyValueWithoutDollar } from "utils/helperFunctions/getCurrencyValue";
import Modal from "components/popUps/Modal";

export default function MainTable(props) {

  const {titleData,  columnsData, tableData, selected, selectedValues, setSelectedValues, onSelectedValuesChange, handleSortType } = props;

  const [isHovered, setIsHovered] = useState(false);

  const [selectedDescription, setSelectedDescription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDescriptionCellClick = (cellValue) => {
    setSelectedDescription(cellValue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDescription(null);
    setIsModalOpen(false);
  };

  const columns = useMemo(() => columnsData, [columnsData]);

  const handleCheckboxChange = (e, value) => {
    const updatedSelectedValues = e.target.checked
      ? [...selectedValues, value]
      : selectedValues.filter((item) => item !== value);
    setSelectedValues(updatedSelectedValues);
    onSelectedValuesChange(updatedSelectedValues);
  };


  const data = useMemo(() => tableData, [tableData]);
  const title = useMemo(() => titleData, [titleData]);

  const tableInstance = useTable(
    {
      title,
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 500;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const textColorCheckbox = useColorModeValue("rgba(0, 162, 173, 1)", "white");


  function processValue(value) {
    switch (true) {
        case value === 0:
            return '0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return getCurrencyValueWithoutDollar(roundedValue);
    }
}


  return (<>
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflow={{ sm: "auto", lg: "auto" }}>
      {title?
        <Flex className="table-header">
        <Text fontSize={'13px'} className="last-refresh-at">{title} </Text>
      </Flex> :""}
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' borderTop={'1px solid rgb(0, 0, 0, 0.1)'}>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  // {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header"
                  key={index}
                  borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <Flex className="table-header-text">{column.render("Header")}
                    {["","ID","INVOICE NUMBER","CUSTOMER ID","CUSTOMER NAME","CURRENCY","STATUS"].includes(column.Header) ? null :
                      <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                        <Flex direction={'column'} pl={'0px'}>
                          <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                          <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                        </Flex>
                      </span>}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const isLastCell = index === row.cells.length - 1;
                  if (cell.column.Header === "CUSTOM CATEGORY") {
                    data = (
                      <Flex align='center'>
                        {selected && <Checkbox
                          colorScheme='teal'
                          me='10px'
                          onChange={(e) => handleCheckboxChange(e, cell.row?.original?.id)}
                        />}
                        <Text color={selected ? textColorCheckbox : textColor} >
                          {cell?.value == null ? "-" : cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TRANSACTION ID") {
                    data = (<Flex align='center' justify={'space-between'}>
                      <Flex align='center' maxW={'100px'} >
                        <Text me='10px' maxW={'100%'} overflow="hidden"
                          textOverflow="ellipsis" >
                          {cell.value}
                        </Text>
                      </Flex>
                      <TriangleUpIcon w={2.5} h={2.5} color={'#00A2AD'} title="Click to Full View" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => handleDescriptionCellClick(cell.value)} />
                    </Flex>);
                  } else if (cell.column.Header === "ISSUE DATE") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "DUE DATE") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "DELETED") {
                    data = (
                      <Text>
                        {cell.value ? "Yes" : "No"}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Text color={
                        cell.value === "Paid"
                          ? "rgba(0, 162, 173, 1)"
                          : cell.value === "PartiallyPaid"
                            ? "rgba(254, 198, 1, 1)"
                            : cell.value === "Submitted"
                              ? "rgba(195, 183, 229, 1)"
                              : cell.value === "Draft"
                                ? "rgba(0, 0, 0, 0.2)"
                                : cell.value === "Unknown"
                                  ? "rgba(0, 0, 0, 1)"
                                  : cell.value === "Void"
                                    ? "rgba(0, 0, 0, 0.45)"
                                    : null} >
                        {cell?.value == null ? "-" : cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "DESCRIPTION") {
                    data = (<Flex align='center' justify={'space-between'}>
                      <Flex align='center' maxW={'230px'}>
                        <Text me='10px' maxW={'100%'} overflow="hidden"
                          textOverflow="ellipsis" >
                          {cell.value}
                        </Text>
                      </Flex>
                      <TriangleUpIcon w={2.5} h={2.5} color={'#00A2AD'} title="Click to Full View" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={() => handleDescriptionCellClick(cell.value)} />
                    </Flex>);
                  } else if (cell.column.Header === "TOTAL AMT") {
                    data = (
                      <Text textAlign={'right'}>
                          {cell?.value == null  ? "-" : processValue(Math.round((cell?.value)))}
                      </Text>
                    );
                  }else if (cell.column.Header === "DUE AMT") {
                    data = (
                      <Text textAlign={'right'}>
                          {cell?.value == null  ? "-" : processValue(Math.round((cell?.value)))}
                      </Text>
                    );
                  }else if (cell.column.Header === "TOTAL TAX") {
                    data = (
                      <Text textAlign={'right'}>
                          {cell?.value == null  ? "-" : processValue(Math.round((cell?.value)))}
                      </Text>
                    );
                  } else {
                    data = (
                      <Text>
                        {cell?.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      className="table-cell"
                      maxW={cell.column.Header === "DESCRIPTION" ? "280px" : cell.column.Header === "TRANSACTION ID" ? "140px" : ""}
                      borderRight={isLastCell ? "transparent" : "1px solid #E0E8f0"}>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
    <Modal description={selectedDescription} onClose={handleCloseModal} />
  </>);
}
