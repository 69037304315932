import { combineReducers } from "redux";
import { userReducer} from '../reducers/userReducer'
import { DESTROY_SESSION } from "../types/types";
import { infoReducer } from "./infoReducer";


const appReducer = combineReducers({
    userReducer,
    infoReducer
});

const rootReducer = (state, action) => {
    if (action.type === DESTROY_SESSION) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
};
export default rootReducer;