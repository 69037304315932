import React, { useEffect, useState } from 'react'
import { Button, Box, Breadcrumb, Select, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
// import { CloseIcon } from '@chakra-ui/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-red.svg';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux';
import { localStorageKeys } from "utils/localStorageKeys";
import { mapSelectedDecisions } from "redux/actions/infoAction";

export default function FixedSubHeaderTop({ setSelectedDecisionIds, selectedValues, selectedDecisionIds, handleSelectedValuesChange }) {

    const history = useHistory()

    const dispatch = useDispatch()

    const { selectedDecisions } = useSelector(state => state.infoReducer)

    const removeValues = (removableValue) => {
        const updatedValues = selectedDecisionIds.filter(item => item !== removableValue);
        setSelectedDecisionIds(updatedValues);

        const updatedSelectedDecisions = selectedDecisions.map(decision => {
          const decisionIndex = decision.decision_ids.findIndex(id => id === removableValue);

          if (decisionIndex !== -1) {
            const updatedDecisionIds = [
              ...decision.decision_ids.slice(0, decisionIndex),
              ...decision.decision_ids.slice(decisionIndex + 1)
            ];

            return {
              application_id: decision.application_id,
              decision_ids: updatedDecisionIds
            };
          }

          return decision;
        }).filter(decision => decision.decision_ids.length > 0);

        dispatch(mapSelectedDecisions(updatedSelectedDecisions));
        localStorage.setItem(localStorageKeys.SELECTED_DECISIONS, JSON.stringify(updatedSelectedDecisions));
      };

    const handleRoute = () => {
        history.push(`/decision/summary`)
    }

    return (
        <>
            <Flex justify={'space-between'} width={'100%'} align={'center'}>
                {selectedDecisionIds?.length <= 0 ? <Flex>
                    <Text as={'span'} className='label-select'>
                        Select any Decision ID to View Details
                    </Text>
                </Flex> :
                    <Flex>
                        <Text as={'span'} className='label-select'>
                            Selected Decision ID
                        </Text>
                        {selectedDecisionIds?.map((selectedValue, index) => (<Flex key={index} align={'center'}>
                            <Text as={'span'} px={'5px'} className="vertical-divider-line">&#124;</Text>
                            <Text pe={'5px'} as={'span'} className='list-selected'>
                                {selectedValue}
                            </Text>
                            <CloseIcon cursor={'pointer'} width={'15px'} height={'15px'} onClick={() => removeValues(selectedValue)} />
                        </Flex>))}
                    </Flex>}
                <Button className='view-detail-btn'  isDisabled={selectedDecisionIds?.length <= 0} onClick={() => handleRoute()}>
                    View Details
                </Button>
            </Flex>
        </>
    )
}
