export const columnsDataComplex = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "INVOICE NUMBER",
      accessor: "invoice_number",
    },
    {
      Header: "ISSUE DATE",
      accessor: "issue_date",
    },
    {
      Header: "CUSTOMER ID",
      accessor: "customer_id",
    },
    {
      Header: "CUSTOMER NAME",
      accessor: "customer_name",
    },
    {
      Header: "CURRENCY",
      accessor: "currency",
    },
    {
      Header: "TOTAL AMT",
      accessor: "total_amount",
    },
    {
      Header: "TOTAL TAX",
      accessor: "total_tax_amount",
    },
    {
      Header: "DUE DATE",
      accessor: "due_date",
    },
    {
      Header: "DUE AMT",
      accessor: "amount_due",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DELETED",
      accessor: "is_deleted",
    },
  ];