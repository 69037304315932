import { SET_DATE_FILTER } from "redux/types/types"
import service from "utils/service";
import { apiendpoints } from 'utils/apiendpoints'
import { SELECTED_APPLICATIONS, SELECTED_DECISIONS, SET_ACTIVE_APPS } from "redux/types/types";
import { TRANSACTION_CATEGORIES } from "redux/types/types";


export const setDateFilter = (data) => (dispatch) => {
    dispatch({
        type: SET_DATE_FILTER,
        payload : data
    })
}

export const getUserCountry = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_BUSINESS_COUNTRIES).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserRelationshipType = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_BUSINESS_RELATIONSHIP_TYPES).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserIdType = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_ID_TYPES).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserIdCountry = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_ID_COUNTRIES).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserIdNumber = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_ID_NUMBERS).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const mapSelectedApplications = (data) => (dispatch) => {
    dispatch({
        type: SELECTED_APPLICATIONS,
        payload : data
    })
}
export const mapSelectedDecisions = (data) => (dispatch) => {
    dispatch({
        type: SELECTED_DECISIONS,
        payload : data
    })
}

export const getTransactionCategories = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(apiendpoints.GET_UNIQUE_TRANSACTION_CATEGORIES).then((res) => {
            resolve(res?.content)
            dispatch({
                type: TRANSACTION_CATEGORIES,
                payload: res?.content
            })
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setActiveAppsRedux = (data) => (dispatch) => {
    dispatch({
        type: SET_ACTIVE_APPS,
        payload: data,
    })
}