import {USER_INFO} from '../types/types'

const initialState = {
    userInfo: {},
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                userInfo: action.payload,
            }
        default:
            return state
    }
}

export {
    userReducer
}

