import React, { useState } from 'react';
import {routes} from 'routes.js';
import { Portal, Box, useDisclosure, Text, Button, Link, useColorModeValue, Flex, Icon } from '@chakra-ui/react';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import {MdOutlineFilterAltOff, MdOutlineFilterAlt} from "react-icons/md";
import { CgRemoveR } from "react-icons/cg";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import EclCalenderRange from 'components/eclCalender/eclCalenderRange';
import { DECISION_DATE } from 'utils/constants';
import CustomSelect from 'components/customSelect/CustomSelect';
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles';
import { applications_statusOptions } from 'utils/constants';
import { decisionRunTriggeredOptions } from 'utils/constants';

function FixedHeaderTop(props) {

	const {showFilter, setShowFilter, setSelectedDecisionIds, selectedDecisionIds, selectedValues, handleSelectedValuesChange, setDecisionSearch, decisionSearch, onDateRangeSelect, selectedOption, setSelectedOption, handleClearFilter, triggerFilter, setTriggerFilter} = props;

	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};


	let mainText = useColorModeValue('cbrand.500', 'white');

	const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }

	const handleSelectChange = (selectedOption) => {
		setSelectedOption(selectedOption);
	};

	const handleSelectChangeTrigger = (selectedOption) => {
		setTriggerFilter(selectedOption);
	};


	return (
		<Portal>
			<Box id={'getHeightApp'} className='box-fixed-header-container'>
				<Flex justify={'space-between'} align={'center'} width={'100%'}>
					<Box mb={{ sm: '8px', md: '0px' }} me={'auto'}>
						<Text color={mainText} bg='inherit' fontWeight='bold' fontSize='28px'>
							{getActiveRoute(routes)}
						</Text>
					</Box>
					<Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
				</Flex>
				{
					showFilter ?
						<Flex justify={'space-between'} gap={'10px'} width={'100%'}>
							<Box flex="2"><SearchBar search={decisionSearch} setSearch={setDecisionSearch} mb={{ base: '10px', md: 'unset' }} /></Box>
							<Box flex="1"><EclCalenderRange onDateRangeSelect={onDateRangeSelect} btnText={DECISION_DATE} /></Box>
							<Box flex="1"><CustomSelect
								options={decisionRunTriggeredOptions}
								value={triggerFilter}
								onChange={handleSelectChangeTrigger}
								placeholder="Triggered"
								isSearchable={false}
								isClearable={false}
								customStyles={customStyleSelected}
							/></Box>
							<Box flex="1"><CustomSelect
								options={applications_statusOptions}
								value={selectedOption}
								onChange={handleSelectChange}
								placeholder="Status"
								isSearchable={false}
								isClearable={false}
								customStyles={customStyleSelected}
							/></Box>

							<Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
						</Flex> : ""}
				<hr className='divider' />
				<FixedSubHeaderTop setSelectedDecisionIds={setSelectedDecisionIds} selectedDecisionIds={selectedDecisionIds} selectedValues={selectedValues} handleSelectedValuesChange={handleSelectedValuesChange} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop