import React, { useState } from 'react';
import {routes} from 'routes.js';
import { Portal, Box, useDisclosure, Text, Button, Link, useColorModeValue,Flex, Icon  } from '@chakra-ui/react';
// import Navbar from 'components/navbar/NavbarBusiness';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import CustomSelect from 'components/customSelect/CustomSelect'
import {customStyleSelected} from 'components/customSelect/CustomSelectStyles'
import {MdOutlineFilterAltOff, MdOutlineFilterAlt} from "react-icons/md";
import { CgRemoveR } from "react-icons/cg";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import EclCalenderRange from 'components/eclCalender/eclCalenderRange';

function FixedHeaderTop(props) {

	const [fixed] = useState(false);

	const {showFilter, setShowFilter, selectedValues, businessSearch , setBusinessSearch ,onDateRangeSelect, selectedOption, setSelectedOption, selectedrelationshipOption, setSelectedrelationshipOption,businessStartDate,setBusinessStartDate,businessEndDate,setBusinessEndDate,countriesOption,setCountriesOption,relationshipTypesOption,
		setRelationshipTypesOption,
		selectedColumnOptions,
		// handleSelectChange,
		customSelectOptions,
		setSelectedColumnOptions,
		setCustomSelectOptions
	} = props;

	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	let mainText = useColorModeValue('cbrand.500', 'white');

	const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }


	const handleClearFilter = () => {
		setSelectedOption("");
		setSelectedrelationshipOption("");
		setBusinessSearch("")
		setBusinessStartDate("")
		setBusinessEndDate("")
	};

	const handleSelectChange = (selectedOption) => {
		setSelectedOption(selectedOption);
	};

	const handleRelationshipSelectChange = (selectedrelationshipOption) => {
		setSelectedrelationshipOption(selectedrelationshipOption);
	};

	return (
		<Portal>
			<Box id={'getHeight'} className='box-fixed-header-container'>
				{/* <Navbar
					brandText={getActiveRoute(routes)}
					fixed={fixed}
					businessSearch={businessSearch}
					setBusinessSearch={setBusinessSearch}
					businessStartDate={businessStartDate}
					setBusinessStartDate={setBusinessStartDate}
					businessEndDate={businessEndDate}
					setBusinessEndDate={setBusinessEndDate}
					onDateRangeSelect={onDateRangeSelect}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					selectedrelationshipOption={selectedrelationshipOption}
					setSelectedrelationshipOption={setSelectedrelationshipOption}
					countriesOption={countriesOption}
					setCountriesOption={setCountriesOption}
					relationshipTypesOption={relationshipTypesOption}
					setRelationshipTypesOption={setRelationshipTypesOption}
					showFilter={showFilter} setShowFilter={setShowFilter}
				/> */}

<Flex justify={'space-between'} align={'center'} width={'100%'}>
			<Box mb={{ sm: '8px', md: '0px' }} me={'auto'}>
				<Text color={mainText} bg='inherit' fontWeight='bold' fontSize='28px'>
						{getActiveRoute(routes)}
				</Text>
			</Box>
			<Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
		</Flex>

		{
			showFilter ? <Flex justify={'space-between'} gap={'10px'} width={'100%'}>
				<Box flex="2"><SearchBar search={businessSearch} setSearch={setBusinessSearch} mb={{ base: '10px', md: 'unset' }} /></Box>

				<Box flex="1">
					<CustomSelect
						options={relationshipTypesOption}
						value={selectedrelationshipOption}
						onChange={handleRelationshipSelectChange}
						placeholder="Relationship"
						isSearchable={false}
						isClearable={false}
						customStyles={customStyleSelected}
					/>
				</Box>
				<Box flex="1">
					<CustomSelect
						options={countriesOption}
						value={selectedOption}
						onChange={handleSelectChange}
						placeholder="Country"
						isSearchable={false}
						isClearable={false}
						customStyles={customStyleSelected}
					/>
				</Box>
				<Box flex="1"><EclCalenderRange btnText={'Established Date'} onDateRangeSelect={onDateRangeSelect} /></Box>
				<Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
			</Flex> : ""
		}
		
		<hr className='divider' />





				<FixedSubHeaderTop setCustomSelectOptions={setCustomSelectOptions} setSelectedColumnOptions={setSelectedColumnOptions} selectedValues={selectedValues} selectedColumnOptions={selectedColumnOptions} handleSelectChange={handleSelectChange} customSelectOptions={customSelectOptions} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop