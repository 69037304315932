export const columnsDataComplex = [
  {
    Header: "",
    accessor: "unique_id",
  },
  {
    Header: "Decision ID",
    accessor: "decision_id",
  },
  {
    Header: "Application ID",
    accessor: "record_id",
  },
  {
    Header: "Business Name",
    accessor: "business_name",
  },
  {
    Header: "Person Name",
    accessor: "customer_name",
  },
  {
    Header: "Last Execution Date Time",
    accessor: "created_at",
  },
  {
    Header: "TRIGGERED BY",
    accessor: "triggered_by",
  },
  {
    Header: "Decision Status",
    accessor: "loan_status",
  },
];
