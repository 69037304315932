import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Box,useColorModeValue, SimpleGrid,Flex,Text,Select,Image,Icon,} from "@chakra-ui/react";
import {MdBarChart, MdClose,MdCheck,MdCancel} from "react-icons/md";
import {CheckCircleIcon } from '@chakra-ui/icons'
import FixedHeaderTop from "views/admin/invoices/components/fixedHeaderInvoice/FixedHeaderTop";
import MainTable from "./components/MainTable";
import {columnsDataComplex} from "views/admin/invoices/variables/columnsData";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Period from "components/period/Period";
import { useEffect } from "react";
import { mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import Pagination from "components/pagination/pagination";
import NoResults from "components/errorStates/NoResults";
import { getTransactionCategories, setActiveAppsRedux } from "redux/actions/infoAction";
import { INVOICE_DUE_DATE } from "utils/constants";
import { customStyleSelected } from "components/customSelect/CustomSelectStyles";
import CustomSelect from "components/customSelect/CustomSelect";
import { useLocation } from 'react-router-dom';
import moment from "moment";


export default function Invoices() {

  const dispatch = useDispatch()

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { selectedApplications, transactionCategories, dateTypeFilter, activeAppsRedux } = useSelector(state => state.infoReducer)

  const [selected, setSelected] = useState(false);

  const [activeApps, setActiveApps] = useState("");

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [search, setSearch] = useState('');

  const resultsPerPageList = [25, 50, 100, 150, 200]

  const defaultResultsPerPage = 25

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(25);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [startAmount, setStartAmount] = useState("");

  const [endAmount, setEndAmount] = useState("");

  const [sorting, setSorting] = useState("")

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'codat', label: "Codat" }])

  const [dataSource, setDataSource] = useState([])

  const [showFilter, setShowFilter] = useState(true)

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [fromAmt, setFromAmt] = useState(null)

  const [toAmt, setToAmt] = useState(null)

  const [lastRefreshAt, setLastRefreshAt] = useState("")


  const updateAmountFilterValues = (fromAmt, toAmt) => {
    setStartAmount(fromAmt)
    setEndAmount(toAmt)
  }

  const handleClearFilter = () => {
    setSearch("")
    setStartDate("")
    setEndDate("")
    setStartAmount("")
    setEndAmount("")
    setSelectedStatus('')
    setSelectedCurrency('')
    setFromAmt('')
    setToAmt('')
  }

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case INVOICE_DUE_DATE:
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      default:
        setStartDate("");
        setEndDate("");
    }
  };

  const fetchDetails = () => {
    setLoader(true)
    service.get(`${apiendpoints?.APPLICATIONS}/${activeApps.globalRecordId}/codat/invoices?search=${search}&sort_by=${sorting}&status=${selectedStatus?.value ? selectedStatus.value : ''}&start_amount=${startAmount}&end_amount=${endAmount}&currency=${selectedCurrency?.value? selectedCurrency.value : ''}&due_date_start=${startDate}&due_date_end=${endDate}&page=${currentPage}&per_page_items=${resultsPerPage}`).then((res) => {
      const { content, content: { count, current_page, next_page, previous_page, total_pages } } = res
      setContent(content)
      setLastRefreshAt(moment.utc(content?.last_refreshed_date).format("DD MMM YYYY,  h:mm A"))
      setError(false)
      setLoader(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)
    }).catch(error => {
      setLoader(false)
      setError(true)
    })
  }

  useEffect(() => {
    setDataSource(dataSourceOptions[0])
  }, [])


  useEffect(() => {
    if (activeApps) {
      fetchDetails()
    }
  }, [activeApps, selectedCurrency, selectedStatus, search, currentPage, resultsPerPage, startDate, endDate, sorting,  startAmount, endAmount])

  useEffect(() => {
    dispatch(getTransactionCategories())
  }, [])

  useEffect(() => {
    var appsSelected = localStorage.getItem(localStorageKeys?.SELECTED_APPLICATIONS)
    if (appsSelected && (JSON.parse(appsSelected).length > 0)) {
      dispatch(mapSelectedApplications(JSON.parse(appsSelected)))
      setActiveApps(JSON.parse(appsSelected)[0])
      dispatch(setActiveAppsRedux(JSON.parse(appsSelected)[0]));
    }
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height, showFilter]);

  return (
    <Box id={'paddingTop'} pt={{ base: "180px", md: "140px", xl: "140px" }}>
      <FixedHeaderTop fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} activeApps={activeApps} setActiveApps={setActiveApps} showFilter={showFilter} setShowFilter={setShowFilter} onDateRangeSelect={handleDateRangeSelect}  selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} handleClearFilter={handleClearFilter} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} search={search} setSearch={setSearch} updateAmountFilterValues={updateAmountFilterValues} />
      <Flex align={'center'} gap={'20px'} mb={'20px'} >
          <Text as={'span'} className={'label-select'}> Select Data Source </Text>
          <CustomSelect
            options={dataSourceOptions}
            value={dataSource}
            onChange={(data) => setDataSource(data)}
            placeholder=""
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            // isDisabled={false}
          />
        </Flex>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults dataSource={true}/> :
              <>
                <MainTable titleData={`Data Last Refreshed At: ${lastRefreshAt}`}  handleSortType={handleSortType} columnsData={columnsDataComplex} tableData={content && content?.invoices && content?.invoices?.length > 0 ? content?.invoices : []}/>
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
      <ToastContainer />
    </Box>
  );
}
