import { DESTROY_SESSION } from "redux/types/types"
import { USER_INFO } from "redux/types/types"


export const mapUserInfo = (data) => dispatch =>  {
    dispatch({
        type: USER_INFO,
        payload : data
    })
}

export const destroySession = () => (dispatch) => {
    dispatch({
        type: DESTROY_SESSION,
    })
}


