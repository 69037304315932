import React, {useEffect, useState} from "react";
import { useDispatch} from 'react-redux';
import { getUserIdType, getUserIdCountry, getUserIdNumber } from 'redux/actions/infoAction'
import { Box, SimpleGrid, Flex} from "@chakra-ui/react";
import FixedHeaderTop from "./components/fixedHeaderPersons/FixedHeaderTop";
import MainTable from "./components/MainTable";
import service from "utils/service";
import {columnsDataComplex} from "views/admin/persons/variables/columnsData";
import { apiendpoints } from 'utils/apiendpoints'
import LazyLoader from 'components/lazyLoader/LazyLoader'
import NoResults from 'components/errorStates/NoResults'
import SomethingWrong from "components/errorStates/SomethingWrong";
import Pagination from "components/pagination/pagination";
import { useLocation } from 'react-router-dom';

export default function Persons() {

  const dispatch = useDispatch(); 

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [personSearch, setPersonSearch] = useState('');

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(10);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const resultsPerPageList = [10, 20, 30, 40, 50]

  const defaultResultsPerPage = 10

  const [sorting, setSorting] = useState("");

  const [idTypesOption, setIdTypesOption] = useState([])

  const [selectedIdTypesOption, setSelectedIdTypesOption] = useState('')

  const [idCountryOption, setIdCountryOption] = useState([])

  const [selectedIdCountryOption, setSelectedIdCountryOption] = useState('')

  const [idNumberOption, setIdNumberOption] = useState([])

  const [selectedIdNumberOption, setSelectedIdNumberOption] = useState('')

  const [customSelectOptions, setCustomSelectOptions] = useState([]);

  const [selectedColumnOptions, setSelectedColumnOptions] = useState([]);

  const [showFilter, setShowFilter] = useState(true)



  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const fetchData = () => {
    setLoader(true)
    service.get(`${apiendpoints.PERSON}?search=${personSearch}&page=${currentPage}&per_page_items=${resultsPerPage}&id_type=${selectedIdTypesOption? selectedIdTypesOption.value : ''}&id_country=${selectedIdCountryOption? selectedIdCountryOption.value : ''}&id_number=${selectedIdNumberOption? selectedIdNumberOption.value : ''}&sort_by=${sorting}`).then((res) => {
      const { content, content : { count, current_page, next_page, previous_page, total_pages} } = res
      setContent(content);
      setLoader(false)
      setError(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)
    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }


  const fetchIdTypes = () => {
		dispatch(getUserIdType())
			.then(response => {
				const { content } = response
				const idTypes = content.map(idTypesObj => ({
					value: idTypesObj?.identification_type,
					label: idTypesObj?.identification_type,
				  }));
          setIdTypesOption(idTypes)
			})
			.catch(error => {
				console.error('Error fetching user countries:', error);
			});
	};


	const fetchIdCountry = () => {
		dispatch(getUserIdCountry())
			.then(response => {
				const { content } = response
				const idCountry = content.map(idCountryObj => ({
					value: idCountryObj?.identification_country,
					label: idCountryObj?.identification_country,
				  }));
          setIdCountryOption(idCountry)
			})
			.catch(error => {
				console.error('Error fetching user relationshipTypes:', error);
			});
	};


	const fetchIdNumber = () => {
		dispatch(getUserIdNumber())
			.then(response => {
				const { content } = response
				const idNumber = content.map(idNumberObj => ({
					value: idNumberObj?.identification_number,
					label: idNumberObj?.identification_number,
				  }));
          setIdNumberOption(idNumber)
			})
			.catch(error => {
				console.error('Error fetching user relationshipTypes:', error);
			});
	};


  const handleSortType = (data) => {
    setSorting(data)
  }


  useEffect(() => {
    fetchData();
  }, [personSearch, currentPage, resultsPerPage, sorting, selectedIdTypesOption, selectedIdCountryOption, selectedIdNumberOption]);

  useEffect(() => {
    fetchIdTypes()
		fetchIdCountry()
		fetchIdNumber()
  }, []);

  useEffect(() => {
    setCustomSelectOptions(columnsDataComplex)
    setSelectedColumnOptions(columnsDataComplex)
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTopPer');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height, showFilter]);

  return (
    <Box id={'paddingTopPer'} pt={{ base: "180px", md: "156px", xl: "156px" }}>
      <FixedHeaderTop
        showFilter={showFilter} setShowFilter={setShowFilter}
        personSearch={personSearch} setPersonSearch={setPersonSearch}
        idTypesOption={idTypesOption}
        selectedIdTypesOption={selectedIdTypesOption}
        setSelectedIdTypesOption={setSelectedIdTypesOption}
        idCountryOption={idCountryOption}
        selectedIdCountryOption={selectedIdCountryOption}
        setSelectedIdCountryOption={setSelectedIdCountryOption}
        idNumberOption={idNumberOption}
        selectedIdNumberOption={selectedIdNumberOption}
        setSelectedIdNumberOption={setSelectedIdNumberOption}
        selectedColumnOptions={selectedColumnOptions}
        customSelectOptions={customSelectOptions}
        setCustomSelectOptions={setCustomSelectOptions}
        setSelectedColumnOptions={setSelectedColumnOptions}

      />
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults /> :
              <>
                <MainTable handleSortType={handleSortType} columnsData={customSelectOptions} tableData={content?.persons ? content?.persons : []} selectedColumnOptions={selectedColumnOptions} />
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
    </Box>
  );
}
