export const customStyleSelected = {
    control: (styles, state) =>({
        ...styles,
        fontSize : "13px",
        fontWeight: '600',
        minHeight : '40px',
        minWidth:'150px',
        width:'100%',
        height:'40px',
        padding: ' 0px 8px',
        border: '1px solid transparent',
        borderRadius: '0.4375rem',
        cursor:'pointer',
        justifyContent: 'center', 
        background: 'linear-gradient(white, white) padding-box,linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box',
        boxShadow: state.menuIsOpen? '0 0 0 1px #00a2ad' : 'none',
        "&:hover": {
            border: '1px solid #00a2ad',
            outline: 'none !important'
        },   
        ...(state.isDisabled && {
            background: 'rgba(0, 0, 0, 0.07)',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            cursor:'not-allowed'
          }),  
    }),

    placeholder: styles =>({
        ...styles,
        color: "rgba(0, 0, 0, 0.9)",
        textAlign: 'left',
        whiteSpace:'nowrap'
      }),
      indicatorSeparator: styles =>({
        ...styles,
        display: 'none',
      }),
      singleValue: styles =>({
        ...styles,
        display: 'flex',
        alignItems: 'center',
      }),

    dropdownIndicator: (base, { isDisabled }) => ({
        ...base,
        padding:"0px 8px",
        cursor:"pointer",
        "svg":{
            width : "14px",
            height : "14px",
            fill: isDisabled ? 'rgba(0, 0, 0, 0.4)' : '#323232',
         }

      }),
    menu: base => ({
        ...base,
        border: 'solid 1px #e6e7e9',
        fontSize : "120px",
        boxShadow: '0px 0px 25px rgba(0,0,0,0.05), 0px 0px 5px rgba(0,0,0,0.05)',
        borderRadius: '0.4375rem',
    }),
    menuList: base => ({
        ...base,
        padding: '0px 2px',
        background: 'linear-gradient(white, white) padding-box,linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box',
        border: '1px solid transparent',
        borderRadius: '0.4375rem',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            backgroundColor: '#fff',
            color: "#000000",
            borderRadius: "2px",
            padding: '8px',
            cursor:'pointer',
            borderBottom: '1px solid rgba(0, 0, 0, 0.20)'
        };
    },

};