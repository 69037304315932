import { Portal, Box, useDisclosure, Text, Button, Link } from '@chakra-ui/react';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {routes} from 'routes.js';
import { localStorageKeys } from 'utils/localStorageKeys';
import { routesConstants } from 'utils/routeconstants';

// Custom Chakra theme
export default function Dashboard(props) {
	const { ...rest } = props;
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};

	document.documentElement.dir = 'ltr';

	return (
		<Box>
			<Box>
				<SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar}}>
					<Sidebar routes={routes} display='none' {...rest} />
					<Box className='box-right-section-layout'>
						{getRoute() ? (
							<Box mx='auto' p={{ base: '20px', md: '40px' }} pe='20px' minH='100vh' pt='50px'>
								<Switch>
									{getRoutes(routes)}
									<Redirect from='/' to='/applications' />
								</Switch>
							</Box>
						) : null}
					</Box>
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
