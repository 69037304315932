import React from 'react'
import {
  Box,
  useColorModeValue,
  SimpleGrid,
  Flex,
  Text,
  Select,
} from "@chakra-ui/react";

export default function Period(props) {

  const { months, decisionRunDate } = props;
  return (
    <Flex gap={'20px'}>
      <Text paddingRight={'20px'} borderRight={'1px solid #00A2AD'}>
        Period of Study | {months} Months
      </Text>
      <Text>
        Decision Run Date | {decisionRunDate}
      </Text>
    </Flex>
  )
}
