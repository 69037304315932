export const routesConstants = {
  SIGNIN :'/auth/sign-in',
  FORGOT_PASSWORD :'/auth/forgot-password',
  APPLICATIONS : '/applications',
  APPLICATION_PLAID :'/application/plaid',
  APPLICATION_TELLER : '/application/teller',
  BUSINESSES :'/businesses',
  DECISION_RUNS : '/decision-runs',
  DECISION_SUMMARY :'/decision/summary',
  DECISION_TRANSACTION :'/decision/transaction',
  PERSIONS : '/persons'
};