import React, { useEffect, useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Button, FormControl, FormLabel, Input, HStack, Box, useColorModeValue, Text, Flex, Icon, Textarea } from '@chakra-ui/react'
import { MdCheckCircleOutline, MdAddCircle, MdOutlineFileUpload, MdOutlineRemoveRedEye, MdRestartAlt } from "react-icons/md";
import { CgRemoveR } from "react-icons/cg";
import { el } from 'date-fns/locale';
import { BiSolidEdit } from "react-icons/bi";
import LazyLoader from 'components/lazyLoader/LazyLoader'
import PdfImg from 'assets/img/pdf-logo.png'

export default function CustomDebtPopup({ isEditing, setIsEditing,
    commentIsRequired, setCommentIsRequired, onSubmitFileComment, setFileCommentLoader, fileCommentLoader,deleteCustomDebtProofs, deleteParticularCustomDebtProof, updateParticularCustomDebtProof, fileDataArray, setFileDataArray ,setCustomDebtEditedValue,fileCommentArray, setFileCommentArray, comments, setComments, totalBasedUpdatedValue, setTotalBasedUpdatedValue, debtCustomInputValue,  codatBasedInputValue, codatBasedUpdatedValue, setCodatBasedUpdatedValue, setBankBasedUpdatedValue, bankBasedUpdatedValue, setDebtCustomInputValue, setCodatBasedInputValue, setBankBasedInputValue, bankBasedPopupData, codatBasedPopupData, bankBasedInputValue, isOpen, onClose }) {


    const initialRef = React.useRef(null)

    const finalRef = React.useRef(null)

    const textColor = useColorModeValue("#00A2AD", "#00A2AD")

    const [disabled, setDisabled] = useState(true)

    const [mainSubmitDisabled, setMainSubmitDisabled] = useState(false);

    const [totalTickDisabled,  setTotalTickDisabled] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');




    function isURL(str) {
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(str);
    }


    const handleFileChange = (event) => {
        const selectedImage = event.target.files[0];
        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

        if (selectedImage && allowedFileTypes.includes(selectedImage.type)) {
            const newId = Math.floor(Math.random() * 1000000000) + Date.now();
            const fileName = selectedImage?.name;
            const fileType = selectedImage?.type;
            setFileDataArray(prevFileDataArray => [...prevFileDataArray, { id: newId, file: selectedImage, fileName:fileName, fileType:fileType, comment: ''}]);
        } else {
            setErrorMessage('File type should be JPEG, PNG, or PDF.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    };

    const renderFilePreview = (file) => {
        const isPDF = file.fileType === "application/pdf" || file.fileName.toLowerCase().includes('.pdf');
        if (isPDF) {
            return <img src={PdfImg} alt="Uploaded PDF" width="50" height="50" style={{ objectFit: 'cover' }} />;
        } else {
            if (isURL(file.file)){
                return <img src={file.file} alt="Uploaded Image" width="50" height="50" style={{ objectFit: 'cover' }} />;
            }else{
                return <img src={URL.createObjectURL(file.file)} alt="Uploaded Image" width="50" height="50" style={{ objectFit: 'cover' }} />;
            }
        }
    };

    const onEditMode = (fileId) => {
        setIsEditing(prevState => ({...prevState,[fileId]: !prevState[fileId] }));
        const updatedFileCommentArray = fileCommentArray.filter(fileComment => fileComment.id !== fileId);
        setFileCommentArray(updatedFileCommentArray);
    }

    const addComment = (fileId, comment) => {
        const fileIndex = fileDataArray.findIndex(file => file.id === fileId);
        if (fileIndex !== -1) {
            const updatedFileDataArray = [...fileDataArray];
            updatedFileDataArray[fileIndex] = {
                ...updatedFileDataArray[fileIndex],
                comment: comment
            };
            setFileDataArray(updatedFileDataArray);
        }
        const updatedComments = [...comments];
        updatedComments[fileId] = comment;
        setComments(updatedComments);


    };



    const handleBankBasedInputChange = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 100) {
            setBankBasedInputValue(value);
            setBankBasedUpdatedValue('');
        }

    }
    const handleCodatBasedInputChange = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 100) {
        setCodatBasedInputValue(event.target.value);
        setCodatBasedUpdatedValue('')
    }
    }
    const handleDebtCustomInputChange = (event) => {
        setDebtCustomInputValue(event.target.value);
        // setTotalBasedUpdatedValue('')
    }


    const bankBasedCalculation = () => {
        const result = (Number(bankBasedPopupData) * Number(bankBasedInputValue)) / 100;
        if (result) {
            setBankBasedUpdatedValue(result);
        } else {
            setBankBasedUpdatedValue('');
        }
        // totalBasedCalculation();
    };

    const codatBasedCalculation = () => {
        const result = (Number(codatBasedPopupData) * Number(codatBasedInputValue)) / 100;
        if (result !== undefined) {
            setCodatBasedUpdatedValue(result);
        } else {
            setCodatBasedUpdatedValue('');
        }
    };

    // const totalBasedCalculation = () => {
    //     const result = Number(bankBasedUpdatedValue) + Number(codatBasedUpdatedValue) + Number(debtCustomInputValue);
    //     if (result !== undefined) {
    //         setTotalBasedUpdatedValue(result);
    //     } else {
    //         setTotalBasedUpdatedValue('');
    //     }
    // };


    const totalBasedCalculation = () => {
        const bankValue = !isNaN(Number(bankBasedUpdatedValue)) ? Number(bankBasedUpdatedValue) : 0;
        const codatValue = !isNaN(Number(codatBasedUpdatedValue)) ? Number(codatBasedUpdatedValue) : 0;
        const customValue = !isNaN(Number(debtCustomInputValue)) ? Number(debtCustomInputValue) : 0;
        const result = bankValue + codatValue + customValue;
        setTotalBasedUpdatedValue(result);
    };

    const onCancel = () => {
        setBankBasedUpdatedValue('')
        setCodatBasedUpdatedValue('')
        setTotalBasedUpdatedValue('')
        setBankBasedInputValue('')
        setCodatBasedInputValue('')
        setDebtCustomInputValue('')
        setFileDataArray([]);
        setFileCommentArray([]);
        onClose();
    }

    const onSubmitDebtPopup = () => {
        setCustomDebtEditedValue(totalBasedUpdatedValue)
        onClose();
    }

    const clearAllFilesComments = () => {
        setFileDataArray([]);
        setFileCommentArray([]);
        deleteCustomDebtProofs();
    }

    useEffect(() => {
        const hasValue = totalBasedUpdatedValue || debtCustomInputValue || bankBasedUpdatedValue || codatBasedUpdatedValue;
        const customAndFileCondition = !debtCustomInputValue || (fileDataArray.length > 0 && fileCommentArray.length === fileDataArray.length && fileCommentArray.every(file => file.comment));
        const fileAndCustomCondition = fileDataArray.length === 0 || (debtCustomInputValue);

        setMainSubmitDisabled(!(hasValue && customAndFileCondition && fileAndCustomCondition));
    }, [totalBasedUpdatedValue, debtCustomInputValue, fileDataArray, fileCommentArray, bankBasedUpdatedValue, codatBasedUpdatedValue]);

    // useEffect(() => {
    //     if (totalBasedUpdatedValue && debtCustomInputValue && fileDataArray.length > 0 && fileCommentArray.length > 0 && fileCommentArray.length === fileDataArray.length) {
    //         setMainSubmitDisabled(false);
    //     } else {
    //         setMainSubmitDisabled(true);
    //     }
    // }, [totalBasedUpdatedValue, debtCustomInputValue, fileDataArray, fileCommentArray]);


    useEffect(() => {
        if (debtCustomInputValue) {
            setTotalTickDisabled(false);
        } else {
            setTotalTickDisabled(true);
        }
    }, [debtCustomInputValue]);



    useEffect(() => {
        const defaultIsEditing = {};
        fileCommentArray.forEach(item => {
            defaultIsEditing[item.id] = true;
        });
        setIsEditing(defaultIsEditing);
    }, [fileCommentArray]);

    useEffect(() => {
        totalBasedCalculation();
    }, [bankBasedUpdatedValue, codatBasedUpdatedValue, debtCustomInputValue]);



    return (<>
        <Modal closeOnOverlayClick={false} initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'2xl'} className="custom-debt-popup-container" >
            <ModalOverlay />
            <ModalContent m={'auto'}>
                <ModalHeader color={textColor}>Set a custom debt estimate value</ModalHeader>
                <ModalBody pb={6}>

                    <Box border={'1px solid rgba(0, 0, 0, 0.07)'}>
                        <Flex borderBottom={'1px solid rgba(0, 0, 0, 0.07)'} h={'50px'}>
                            <Flex w={'24%'} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} alignItems={'center'} >
                                Bank Based
                            </Flex>
                            <Flex w={'48%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'}>

                                <Box color={'rgba(0, 0, 0, 1)'} position={'relative'} flex={3} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}>
                                <Text> {bankBasedPopupData ? ('$ ' + Math.round(bankBasedPopupData).toLocaleString("en-US") ) : ( '-' )}</Text>


                                    <Icon transform={'rotate(45deg)'} top={'30%'} right={'-10.5px'} bg={'#ffffff'} position={'absolute'} as={MdAddCircle} h={'20px'} w={'20px'} color={'rgba(204, 204, 204, 1)'} />
                                </Box>

                                <Box flex={3} p={3}>
                                    <FormControl w={'100%'} display='flex' gap={2} alignItems={'center'} justifyContent={'center'}>
                                        <Input min={1} max={100}  w={'75%'} height={'32px'} type='number' name='number' className='ecl-border-ui' value={bankBasedInputValue} placeholder='1-100' onChange={handleBankBasedInputChange} />
                                        <FormLabel htmlFor='number' m={0} color={textColor}>%</FormLabel>
                                    </FormControl>
                                </Box>
                            </Flex>
                            <Flex w={'8%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'} justify={'center'}>
                                <Icon id='tick-icon-bank' cursor={
                                    bankBasedInputValue === '' ? 'not-allowed' :
                                        (bankBasedUpdatedValue && bankBasedInputValue ? 'not-allowed' :
                                            'pointer')
                                } onClick={bankBasedCalculation} as={MdCheckCircleOutline} h={'22px'} w={'22px'} color={
                                    bankBasedInputValue === '' ?
                                        'rgba(0, 0, 0, 0.3)' :
                                        (bankBasedUpdatedValue && bankBasedInputValue ?
                                            'rgba(0, 0, 0, 0.3)' :
                                            '#00A2AD')
                                } />
                            </Flex>
                            <Flex w={'20%'} alignItems={'center'} p={3}>
                                {bankBasedUpdatedValue ? ('$ ' + Math.round(bankBasedUpdatedValue).toLocaleString("en-US") ) : ( '' )}
                            </Flex>
                        </Flex>
                        <Flex borderBottom={'1px solid rgba(0, 0, 0, 0.07)'} h={'50px'}>
                            <Flex w={'24%'} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} alignItems={'center'} >
                            Codat Based
                            </Flex>
                            <Flex w={'48%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'}>
                                <Box color={'rgba(0, 0, 0, 1)'} position={'relative'} flex={3} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'}><Text>{('$ ' + Math.round(codatBasedPopupData).toLocaleString("en-US"))}</Text>
                                    <Icon transform={'rotate(45deg)'} top={'30%'} right={'-10.5px'} bg={'#ffffff'} position={'absolute'} as={MdAddCircle} h={'20px'} w={'20px'} color={'rgba(204, 204, 204, 1)'} />
                                </Box>
                                <Box flex={3} p={3}>
                                    <FormControl  w={'100%'} display='flex' gap={2} alignItems={'center'} justifyContent={'center'}>
                                        <Input min={1} max={100}  w={'75%'} height={'32px'} type='number' name='number' className='ecl-border-ui' value={codatBasedInputValue} placeholder='1-100' onChange={handleCodatBasedInputChange} />
                                        <FormLabel htmlFor='number' m={0} color={textColor}>%</FormLabel>
                                    </FormControl>
                                </Box>
                            </Flex>
                            <Flex width={'8%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'} justifyContent={'center'} >
                                <Icon cursor={
                                    codatBasedInputValue === '' ? 'not-allowed' :
                                        (codatBasedUpdatedValue && codatBasedInputValue ? 'not-allowed' :
                                            'pointer')
                                } onClick={codatBasedCalculation} as={MdCheckCircleOutline} h={'22px'} w={'22px'} color={
                                    codatBasedInputValue === '' ?
                                        'rgba(0, 0, 0, 0.3)' :
                                        (codatBasedUpdatedValue && codatBasedInputValue ?
                                            'rgba(0, 0, 0, 0.3)' :
                                            '#00A2AD')
                                } />
                            </Flex>
                            <Flex w={'20%'} alignItems={'center'} p={3}>
                                {codatBasedUpdatedValue ? ( '$ ' + Math.round(codatBasedUpdatedValue).toLocaleString("en-US") ) : ('')}
                            </Flex>
                        </Flex>
                        <Flex borderBottom={'1px solid rgba(0, 0, 0, 0.07)'} h={'50px'}>
                            <Flex w={'24%'} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} alignItems={'center'} >

                                <FormControl isRequired>
                                <FormLabel m={0}> Custom</FormLabel>
                            </FormControl>
                            </Flex>
                            <Flex px={3} w={'48%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'}>
                                <Flex flex={3}>
                                    <FormControl isRequired>
                                        <Input   height={'32px'} type='number' name='number' className='ecl-border-ui' value={debtCustomInputValue} placeholder='custom value...' onChange={handleDebtCustomInputChange} />
                                    </FormControl>
                                </Flex>
                            </Flex>
                            {/* <Flex w={'8%'} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} h={'100%'} alignItems={'center'} justifyContent={'center'}>
                                <Icon color={
                                    debtCustomInputValue === '' ?
                                        'rgba(0, 0, 0, 0.3)' :
                                        (totalBasedUpdatedValue && debtCustomInputValue ?
                                            'rgba(0, 0, 0, 0.3)' :
                                            '#00A2AD')
                                } cursor={
                                    debtCustomInputValue === '' ? 'not-allowed' :
                                        (totalBasedUpdatedValue && debtCustomInputValue ? 'not-allowed' :
                                            'pointer')
                                } onClick={totalBasedCalculation} as={MdCheckCircleOutline} h={'22px'} w={'22px'} />
                            </Flex> */}
                            <Flex w={'20%'} alignItems={'center'}></Flex>
                        </Flex>
                        <Flex h={'50px'}>
                            <Flex w={'24%'} p={3} borderRight={'1px solid rgba(0, 0, 0, 0.07)'} color={'#00A2AD'} alignItems={'center'} >
                                Total (a + b + c)
                            </Flex>
                            <Flex w={'66%'} h={'100%'} alignItems={'center'} color={'#00A2AD'} fontWeight={'600'}>
                                {totalBasedUpdatedValue ? (
                                    <Text px={3}> {'$ ' + Math.round(totalBasedUpdatedValue).toLocaleString("en-US")}</Text>
                                ) : (
                                    <Text px={3}></Text>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                    <Box mt={5} bg={fileDataArray.length > 0 ? 'rgba(0, 0, 0, 0.03)' : ''} >
                        <Flex p={fileDataArray.length > 0 ? 4 : ""} justifyContent={'space-between'} alignItems={'center'} borderBottom={fileDataArray.length > 0 ? '1px solid rgba(0, 0, 0, 0.07)' : ''}>
                            <FormControl isRequired>
                                <FormLabel m={0} color={'#00A2AD'} fontWeight={'600'}>Select Jpeg/Png/PDF files to upload </FormLabel>
                                {fileDataArray.length < 1 && errorMessage && (
                                    <Text as={'span'} color="red" fontSize={'14px'}>
                                        {errorMessage}
                                    </Text>
                                )}
                                <Text as={'span'} color={'rgba(0, 0, 0, 0.3)'} fontSize={'14px'} >
                                    {fileDataArray.length > 0 ? `(Total ${fileDataArray.length === 1 ? 'file' : 'files'}: ${fileDataArray.length})` : ""}
                                    {fileDataArray.length >= 1 && errorMessage && (
                                    <Text ml={2} as={'span'} color="red" fontSize={'14px'}>
                                        {errorMessage}
                                    </Text>
                                )}
                                </Text>
                            </FormControl>
                            <Flex gap={1}>
                                {fileDataArray.length > 1 && <Button border={'none'} fontSize={'14px'} cursor={'pointer'} px={7} h={'35px'} type='button' bg={'transparent'} color={'#E04F4F'} onClick={clearAllFilesComments} as="span">Remove all</Button>}
                                <label htmlFor="file-upload">
                                    <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileChange}  accept=".png, .jpeg, .jpg, .pdf" />
                                    <Button cursor={'pointer'} px={8} fontSize={'14px'} className='green-btn' type='button' id='submit' isDisabled={false} h={'35px'} as="span"> {fileDataArray.length > 0 ? "Add Files" : "Browse"}</Button>
                                </label>
                            </Flex>
                        </Flex>

                        {fileCommentLoader ? (<LazyLoader view="lazy-file-comment" />) : (<>
                            {fileDataArray.length > 0 && (
                                <Box className='img-upload-container' maxH={'250px'} overflowY={'auto'}>
                                    {fileDataArray.map((fileComment) => (
                                        <Flex my={2} key={fileComment.id} direction={'column'} p={4}>
                                            <Flex gap={'20px'} py={3}>
                                                <Flex justifyContent={'center'} alignItems={'center'} h={'50px'} w={'50px'} boxShadow={'rgba(0, 0, 0, 0.1) 0px 4px 12px'}>
                                                {renderFilePreview(fileComment)}

                                                </Flex>
                                                <Flex flex={4} direction={'column'} justifyContent={'center'}>
                                                    {fileComment.file.name.includes('.') ? (
                                                        <Text>{fileComment.file.name}</Text>
                                                    ) : (
                                                        <Text>{fileComment.fileName}</Text>
                                                    )}
                                                </Flex>
                                                <Flex alignSelf={'flex-start'} gap={'5px'} alignItems={'center'}>
                                                    {isEditing[fileComment.id] ? <Box cursor="pointer" onClick={() => onEditMode(fileComment.id)}>
                                                        <Icon w={'24px'} h={'24px'} color={'#00A2AD'} as={BiSolidEdit} ></Icon>
                                                    </Box> :
                                                        <><Box cursor="pointer" onClick={() => onSubmitFileComment(fileComment?.id, fileComment?.riskId)}>
                                                            <Icon w={'22px'} h={'22px'} color={'#00A2AD'} as={MdCheckCircleOutline}></Icon>
                                                        </Box>
                                                            <Box cursor="pointer" onClick={(event) => updateParticularCustomDebtProof(fileComment?.riskId, fileComment.id, event)}>
                                                                <Icon w={'24px'} h={'24px'} color={'rgba(254, 198, 1, 1)'} as={MdRestartAlt}></Icon>
                                                            </Box>
                                                        </>}
                                                    <Box cursor="pointer" onClick={() => deleteParticularCustomDebtProof(fileComment?.riskId, fileComment?.id,)}>
                                                        <Icon w={'20px'} h={'20px'} color={'rgba(224, 79, 79, 1)'} as={CgRemoveR} ></Icon>
                                                    </Box>
                                                </Flex>
                                            </Flex>

                                            <FormControl isRequired>
                                                <FormLabel py={2} color={'#00A2AD'} fontSize={'14px'} fontWeight={'600'}>{isEditing[fileComment.id] ? "Comment" : "Add Comment"} </FormLabel>
                                                <Textarea
                                                    borderColor={commentIsRequired[fileComment.id]  && fileComment.comment === "" ? "red" : "rgba(0, 0, 0, 0.2)"}
                                                    _hover={'none'}
                                                    _focus={'none'}
                                                    fontSize={'14px'}
                                                    isRequired
                                                    bg={'rgba(255, 255, 255, 0.5)'}
                                                    isDisabled={isEditing[fileComment.id]}
                                                    onChange={(e) => addComment(fileComment.id, e.target.value)}
                                                    value={fileComment.comment ? fileComment.comment : comments[fileComment.id]}
                                                    maxH={'150px'}
                                                    placeholder='Type Here...'
                                                    borderStyle={'dotted'}
                                                />
                                            </FormControl>
                                        </Flex>
                                    ))}
                                </Box>
                            )}
                        </>)
                        }
                    </Box>
                    <Flex alignItems={'center'} justifyContent={'flex-end'} mt={5} gap={3}>
                        <Button px={10} className='red-border-btn' onClick={onCancel}>Cancel</Button>
                        <Button px={10} isDisabled={mainSubmitDisabled} className='green-btn' type='submit' id='submit' onClick={onSubmitDebtPopup}>Submit</Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}