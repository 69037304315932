import React from "react";
import { Image, Text, Flex } from "@chakra-ui/react";
import ErrorState from 'assets/img/states/error.svg'

export default function DisclaimerPage({disclaimerText, loanStatusText , failedStatusText}) {

    return (<>
        <Flex direction={'column'} alignItems={'center'} justify={'center'} bg={'white'} height={'calc(100vh - 295px)'} borderRadius={'15px'}>
            <Image src={ErrorState} w={'130px'} h={'100px'} />
            <Text fontSize={'1.5rem'} as={'strong'}>Decision Run {loanStatusText}!</Text>
            {/* {disclaimerText?  <Text fontSize={'1.5rem'}>{disclaimerText}</Text> : <Text fontSize={'1.5rem'}> {loanStatusText ==="Failed" ? failedStatusText : " Reason Not Available"}</Text>} */}
            {loanStatusText ==="Failed" && failedStatusText? <Text fontSize={'1.5rem'}> {failedStatusText}</Text> : <Text fontSize={'1.5rem'}>{disclaimerText}</Text>}
        </Flex>
    </>);
}
