import React, { useState, useLayoutEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, useColorModeValue, SimpleGrid, Flex, Text, Select, Image, Icon, Button, HStack, Menu, MenuButton, MenuList, MenuItem, Checkbox, MenuDivider } from "@chakra-ui/react";
import { MdBarChart, MdClose, MdCheck, MdCancel, MdKeyboardArrowDown } from "react-icons/md";
import { CheckCircleIcon } from '@chakra-ui/icons'
import FixedHeaderTop from "./components/fixedHeaderPlaidTransactions/FixedHeaderTop";
import MainTable from "./components/MainTable";
import { columnsDataComplex } from "views/admin/plaidTransactions/variables/columnsData";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Period from "components/period/Period";
import { useEffect } from "react";
import { mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import Pagination from "components/pagination/pagination";
import NoResults from "components/errorStates/NoResults";
import { getTransactionCategories, setActiveAppsRedux } from "redux/actions/infoAction";
import { TRANSACTION_DATE, pendingStatusOptions } from "utils/constants";
import { customStyleSelected } from "components/customSelect/CustomSelectStyles";
import CustomSelect from "components/customSelect/CustomSelect";
import { useLocation } from 'react-router-dom';
import moment from "moment";




export default function PlaidTransactions({ onPlaidValuesChange }) {

  const dispatch = useDispatch()

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { selectedApplications, transactionCategories, dateTypeFilter, activeAppsRedux, selectedDecisions } = useSelector(state => state.infoReducer)

  const [categoryOptions, setCateogoryOptions] = useState([])

  const [headerCategory, setHeaderCategory] = useState([])

  const [categoryHeaderOptions, setCategoryHeaderOptions] = useState([])

  const [choosenCatgeory, setChoosenCategory] = useState([])

  const [selected, setSelected] = useState(false);

  const [activeApps, setActiveApps] = useState("");

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [search, setSearch] = useState('');

  const resultsPerPageList = [25, 50, 100, 150, 200]

  const defaultResultsPerPage = 25

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(25);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [startAmount, setStartAmount] = useState("");

  const [endAmount, setEndAmount] = useState("");

  const [categoryFilter, setCategoryFilter] = useState("");

  const [selectedValues, setSelectedValues] = useState({ plaid: [], teller: [] });

  const [sorting, setSorting] = useState("")

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'all', label: "All" }, { value: 'plaid', label: "Plaid" }, { value: "teller", label: "Teller" }])

  const [dataSource, setDataSource] = useState([])

  const [pendingStatus, setPendingStatus] = useState([])

  const [selectedPendingStatus, setSelectedPendingStatus] = useState('')

  const [showFilter, setShowFilter] = useState(true)

  const [fromAmt, setFromAmt] = useState(null)

  const [toAmt, setToAmt] = useState(null)

  const [lastRefreshAt, setLastRefreshAt] = useState("")

  const [accountNoOptionsStore, setAccountNoOptionsStore] = useState([])

  const [accountNoOptions, setAccountNoOptions] = useState(accountNoOptionsStore)


  const [selectedAccountNo, setSelectedAccountNo] = useState([{ value: '', label: "All" }])

  const mapOptions = () => {
    const categorySelectOption = transactionCategories?.map((cat) => {
      return {
        label: cat?.category, value: cat?.category
      }
    })
    setCateogoryOptions(categorySelectOption)
  }
  const mapCategoryOptions = () => {
    const categoryHeaderSelectOption = content?.category && content?.category.length > 0 ? headerCategory.map((cat) => ({
      label: cat?.auto_category,
      value: cat?.auto_category
    })) : [{
      label: "No category",
      value: "No category"
    }];

    setCategoryHeaderOptions(categoryHeaderSelectOption);
  }

  const handleStatusSelectChange = (selectedOption) => {
    setSelectedPendingStatus(selectedOption);

  };

  const handleDataSourceSelectChange = (selectedOption) => {
    if (selectedOption.value !== dataSource.value) {
      setDataSource(selectedOption);

      if (selectedOption.value === "all") {

        setAccountNoOptions(accountNoOptionsStore);
      } else {
        const filteredAccounts = accountNoOptionsStore.filter(option => option.dataSource === selectedOption.value);
        const filteredOptionsWithAll = [{ label: "All", value: " " }, ...filteredAccounts];
        setAccountNoOptions(filteredOptionsWithAll);
      }
      if (selectedAccountNo.value !== " " && selectedAccountNo.value !== selectedOption.value) {
        setSelectedAccountNo({ label: "All", value: " " });
      }
    }
  };


  const handleAccountNoSelectChange = (selectedOption) => {
    if (selectedOption.value === 'all') {
      setSelectedAccountNo({ label: 'All', value: ' ' }); // Change value to ' '
      setDataSource({ label: 'All', value: 'all' });
    } else {
      setSelectedAccountNo(selectedOption);
    }
  };

  const handleCategoryClick = () => {
    setSelected(true);
  };

  const handleCategory = () => {
    setSelected(false);
    setSelectedValues({ plaid: [], teller: [] });
  };


  const handleSelectedValuesChange = (newSelectedValues) => {
    setSelectedValues(newSelectedValues);
  };

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case TRANSACTION_DATE:
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      default:
        setStartDate("");
        setEndDate("");
    }
  };

  const updateAmountFilterValues = (fromAmt, toAmt) => {
    setStartAmount(fromAmt)
    setEndAmount(toAmt)
  }

  const handleClearFilter = () => {
    setSearch("")
    setStartDate("")
    setEndDate("")
    setCategoryFilter("")
    setStartAmount("")
    setEndAmount("")
    setSelectedPendingStatus("")
    setFromAmt('')
    setToAmt('')
  }

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const fetchDetails = () => {
    setLoader(true)
    service.get(`${apiendpoints?.APPLICATIONS}/${activeApps?.globalRecordId}?page=${currentPage}&per_page_items=${resultsPerPage}&search=${search}&trans_date_start=${startDate}&trans_date_end=${endDate}&sort_by=${sorting}&category=${categoryFilter?.value ? categoryFilter?.value : ""}&start_amount=${startAmount}&end_amount=${endAmount}&data_source=${dataSource?.value ? dataSource?.value : ""}&pending_status=${selectedPendingStatus ? selectedPendingStatus.value : ""}&account_number=${selectedAccountNo.value ? selectedAccountNo.value : ""}&account_data_source=${selectedAccountNo.dataSource ? selectedAccountNo.dataSource : ""}`).then((res) => {
      const { content, content: { count, current_page, next_page, previous_page, total_pages } } = res
      setContent(content)
      setHeaderCategory(content?.category)
      setLastRefreshAt(moment.utc(content?.last_refreshed_date).format("DD MMM YYYY,  h:mm A"))
      setError(false)
      setLoader(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setPendingStatus(pendingStatusOptions)
      setCurrentPage(current_page)

      onPlaidValuesChange({
        activeApps: activeApps?.globalRecordId,
        search: search,
        startDate: startDate,
        endDate: endDate,
        category: categoryFilter?.value ? categoryFilter?.value : "",
        startAmount: startAmount,
        endAmount: endAmount,
        dataSource: dataSource?.value ? dataSource?.value : "",
        pendingStatus: selectedPendingStatus?.value ? selectedPendingStatus?.value : "",
      })
    }).catch(error => {
      setLoader(false)
      setError(true)
    })
  }

  const runDecision = () => {
    service.post(`${apiendpoints?.DECISIONS}/${activeApps?.globalRecordId}/run-decision`, { is_custom: false }).then((res) => {
      toast.success("Decision Run Requested. It may take up to 5 minutes to process the Decision Summary.", {
        toastId: "successId",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={CheckCircleIcon} width='20px' height='20px' color={'#00A2AD'} cursor={'pointer'} />
      });
    }).catch((error) => {
      toast.error("Error in Decision Run!", {
        toastId: "errorId",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={MdCancel} width='24px' height='24px' color={'rgba(224, 79, 79, 1)'} cursor={'pointer'} />
      });
    })
  }

  const changeCategory = () => {
    let obj = {
      "transactions": selectedValues,
      "custom_category": choosenCatgeory?.value
    }
    service.put(`${apiendpoints?.PUT_APPLICATIONS_TRANSACTION_CATEGORY}`, obj).then((res) => {
      fetchDetails();
      setSelected(false);
      setSelectedValues({ plaid: [], teller: [] });
      setChoosenCategory([])
      toast.success("Custom Category Updated", {
        toastId: "customCategoryChanged",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={CheckCircleIcon} width='20px' height='20px' color={'#00A2AD'} cursor={'pointer'} />
      });

    }).catch((error) => { 
      toast.error(error.response.data.message, {
        toastId: "errorIdforCatChange",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={MdCancel} width='24px' height='24px' color={'rgba(224, 79, 79, 1)'} cursor={'pointer'} />
      });
    })
  }


  const fetchAccounts = () => {

    service.get(`${apiendpoints?.APPLICATIONS}/${activeApps?.globalRecordId}/bank-accounts?data_source=all`).then((res) => {
      const { content } = res
      let accountList = []
      accountList.push({ label: "All", value: "all" });

      content && content.length > 0 && content.map((item) => {
        accountList.push({ label: item?.account_number, value: item?.account_number, dataSource: item?.source })
      })
      setAccountNoOptionsStore(accountList)

      setError(false)


    }).catch(error => {

      setError(true)
    })
  }





  useEffect(() => {
    mapOptions()
  }, [transactionCategories])


  useEffect(() => {
    mapCategoryOptions()
  }, [headerCategory])

  useEffect(() => {
    setDataSource(dataSourceOptions[0])

  }, [])


  useEffect(() => {
    if (activeApps) {
      fetchDetails()
    }
  }, [activeApps, search, currentPage, resultsPerPage, startDate, endDate, sorting, categoryFilter, startAmount, endAmount, dataSource, selectedPendingStatus, selectedAccountNo])

  useEffect(() => {
    dispatch(getTransactionCategories())
  }, [])

  useEffect(() => {
    var appsSelected = localStorage.getItem(localStorageKeys?.SELECTED_APPLICATIONS)


    if (appsSelected && (JSON.parse(appsSelected).length > 0)) {
      dispatch(mapSelectedApplications(JSON.parse(appsSelected)))
      setActiveApps(JSON.parse(appsSelected)[0])
      dispatch(setActiveAppsRedux(JSON.parse(appsSelected)[0]));
    }
  }, [])




  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height, showFilter]);


  useEffect(() => {
    if (dataSource && activeApps) {
      fetchAccounts();
    }
  }, [activeApps]);

  useEffect(() => {
    if (accountNoOptionsStore.length > 0) {
      setAccountNoOptions(accountNoOptionsStore)
    }

  }, [accountNoOptionsStore])




  return (
    <Box id={'paddingTop'} pt={"140px"}>
      <FixedHeaderTop categoryHeaderOptions={categoryHeaderOptions} fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} handleStatusSelectChange={handleStatusSelectChange} selectedPendingStatus={selectedPendingStatus} pendingStatus={pendingStatus} showFilter={showFilter} setShowFilter={setShowFilter} activeApps={activeApps} setActiveApps={setActiveApps} search={search} setSearch={setSearch} onDateRangeSelect={handleDateRangeSelect} handleClearFilter={handleClearFilter} categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} updateAmountFilterValues={updateAmountFilterValues} categoryOptions={categoryOptions} />

      <Flex justify={'space-between'} align={'center'} mb={'20px'} >
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}> Select Data Source </Text>
          <CustomSelect
            options={dataSourceOptions}
            value={dataSource}
            onChange={handleDataSourceSelectChange}
            placeholder="All"
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            isDisabled={selected}
          />
        </Flex>
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}> Selected Account No.  </Text>
          <CustomSelect
            options={accountNoOptions}
            value={selectedAccountNo}
            onChange={handleAccountNoSelectChange}
            placeholder="All"
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            isDisabled={selected}
          />
        </Flex>
        {
          selected ?
            <Flex gap={'10px'} justify={'flex-end'} alignItems={'center'}>
              <CustomSelect
                options={categoryOptions}
                value={choosenCatgeory}
                onChange={(data) => setChoosenCategory(data)}
                placeholder="Choose Category"
                isSearchable={false}
                isClearable={false}
                customStyles={customStyleSelected}
              />
              {choosenCatgeory?.value && selectedValues?.plaid.length > 0 ||  (selectedValues?.teller.length) > 0 ?
                (<>
                  <Icon onClick={() => changeCategory()} as={MdCheck} width='40px' height='40px' bg={'#00A2AD'} p={2.5} color={'white'} borderRadius={'7px'} cursor={'pointer'} border={'1px solid #00A2AD'} />
                </>)
                : (
                  <Icon as={MdCheck} width='40px' height='40px' bg={'rgba(0, 0, 0, 0.07)'} p={2.5} color={'rgba(0, 0, 0, 0.5)'} borderRadius={'7px'} border={'1px solid rgba(0, 0, 0, 0.07)'} cursor={'pointer'} pointerEvents={"none"} />
                )}
              <Icon as={MdClose} width='40px' height='40px' bg={'#E04F4F'} p={2.5} color={'white'} borderRadius={'7px'} cursor={'pointer'} border={'1px solid #E04F4F'} onClick={() => { handleCategory() }} />
            </Flex>
            : <Flex gap={'20px'} justify={'flex-end'}>
              <Box w={'180px'} className='selected-value-box-ui'>
                <Flex p={'7px 10px'} h={'40px'} align={'center'} justify={'space-between'} >
                  <Text flex={'1'} textAlign={'center'} as={'span'} cursor={'pointer'} onClick={() => { handleCategoryClick() }} >Edit Custom Category</Text>
                </Flex>
              </Box>
              <Box w={'180px'} className='selected-value-box-ui' onClick={() => runDecision()}>
                <Flex p={'7px 10px'} h={'40px'} align={'center'} justify={'space-between'} >
                  <Text flex={'1'} textAlign={'center'} as={'span'} cursor={'pointer'}>Run Decision</Text>
                </Flex>
              </Box>
            </Flex>
        }
      </Flex>


      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults dataSource={true} /> :
              <>
                <MainTable titleData={`Data Last Refreshed At: ${lastRefreshAt}`} columnsData={columnsDataComplex} tableData={content && content?.transactions && content?.transactions?.length > 0 ? content?.transactions : []} selected={selected} setSelected={setSelected} onSelectedValuesChange={handleSelectedValuesChange} selectedValues={selectedValues} setSelectedValues={setSelectedValues}
                  handleSortType={handleSortType} />
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
      <ToastContainer />
    </Box>
  );
}
