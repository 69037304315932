import React, {useState,useEffect } from "react";
import {Box,useColorModeValue,SimpleGrid,Flex} from "@chakra-ui/react";
import { useDispatch} from 'react-redux';
import { getUserCountry, getUserRelationshipType } from 'redux/actions/infoAction'
import FixedHeaderTop from "./components/fixedHeaderBusinesses/FixedHeaderTop";
import MainTable from "./components/MainTable";
import { columnsDataComplex} from "views/admin/businesses/variables/columnsData";
import LazyLoader from 'components/lazyLoader/LazyLoader'
import NoResults from 'components/errorStates/NoResults'
import SomethingWrong from "components/errorStates/SomethingWrong";
import { useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from 'utils/apiendpoints'
import Pagination from "components/pagination/pagination";
import { useLocation } from 'react-router-dom';


export default function Businesses() {

  const dispatch = useDispatch();

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { dateTypeFilter } = useSelector(state => state.infoReducer);

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [businessSearch, setBusinessSearch] = useState('');

  const [businessStartDate, setBusinessStartDate] = useState("");

  const [businessEndDate, setBusinessEndDate] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedrelationshipOption, setSelectedrelationshipOption] = useState(null);

  const [businessCountry, setBusinessCountry] = useState("");

  const [businessRelationshipType, setBusinessRelationshipType] = useState("");

  const resultsPerPageList = [10, 20, 30, 40, 50]

  const defaultResultsPerPage = 10

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(10);

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [countriesOption, setCountriesOption] = useState([])

	const [relationshipTypesOption, setRelationshipTypesOption] = useState([])

  const [sorting, setSorting] = useState("");

  const [mainTableDataArray, setMainTableDataArray] = useState([]);

  const [selectedColumnOptions, setSelectedColumnOptions] = useState([]);

  const [customSelectOptions, setCustomSelectOptions] = useState([]);

  const [showFilter, setShowFilter] = useState(true)


  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleDateRangeSelect = (startDate, endDate) => {
		switch (dateTypeFilter) {
		  case "Established Date":
			setBusinessStartDate(startDate);
			setBusinessEndDate(endDate);
			break;
		  default:
			setBusinessStartDate('');
			setBusinessEndDate('');
		}
	  };

    const handleSortType = (data) => {
      setSorting(data)
    }

  const fetchData = () => {
    setLoader(true)
    service.get(`${apiendpoints.BUSINESS}?search=${businessSearch}&start_date=${businessStartDate}&end_date=${businessEndDate}&page=${currentPage}&per_page_items=${resultsPerPage}&country=${selectedOption? selectedOption.value : ''}&relationship_type=${selectedrelationshipOption? selectedrelationshipOption.value : ''}&sort_by=${sorting}`).then((res) => {
      const { content, content : { count, current_page, next_page, previous_page, total_pages} } = res
      setContent(content);
      setLoader(false)
      setError(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)
      if (content?.business) {
        updateMainTableDataArray(content?.business);
      }
    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }

  const updateMainTableDataArray = (business) => {
    const data = business.map((app) => ({
      id: app.id,
      business_id: app.business_id,
      customer_name: app.customer_name,
      legal_name: app.legal_name,
      relationship_type: app.relationship_type,
      state_province: app.state_province,
      street_address_1: app.street_address_1,
      street_address_2: app.street_address_2,
      city: app.city,
      country: app.country,
      business_tax_id: app.business_tax_id,
      established_date: app.established_date,
      declared_revenue: app.declared_revenue,
      applications: app.applications,
      details_icons: app.applications,
    }));
    setMainTableDataArray(data);
  };

  const fetchCountries = () => {
		dispatch(getUserCountry())
			.then(response => {
				const { content } = response
				const countries = content.map(countryObj => ({
					value: countryObj?.country,
					label: countryObj?.country,
				  }));
				setCountriesOption(countries)
			})
			.catch(error => {
				console.error('Error fetching user countries:', error);
			});
	};

	const fetchRelationshipTypes = () => {
		dispatch(getUserRelationshipType())
			.then(response => {
				const { content } = response
				const relationshipTypes = content.map(relationshipTypesObj => ({
					value: relationshipTypesObj?.relationship_type,
					label: relationshipTypesObj?.relationship_type,
				  }));
				setRelationshipTypesOption(relationshipTypes)
			})
			.catch(error => {
				console.error('Error fetching user relationshipTypes:', error);
			});
	};


  useEffect(() => {
    fetchData();
  }, [businessSearch, businessStartDate, businessEndDate,  currentPage, resultsPerPage, selectedrelationshipOption, selectedOption, sorting, selectedColumnOptions]);

  useEffect(() => {
    fetchCountries()
		fetchRelationshipTypes()
  }, []);

  useEffect(() => {
    setCustomSelectOptions(columnsDataComplex)
    setSelectedColumnOptions(columnsDataComplex)
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTopBus');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height, showFilter]);


  return (
    <Box id={'paddingTopBus'} pt={{ base: "180px", md: "156px", xl: "156px" }}>
    <FixedHeaderTop businessSearch={businessSearch} setBusinessSearch={setBusinessSearch}
    businessStartDate={businessStartDate}
    setBusinessStartDate={setBusinessStartDate}
    businessEndDate={businessEndDate}
    setBusinessEndDate={setBusinessEndDate}
    onDateRangeSelect={handleDateRangeSelect}
    selectedOption={selectedOption} setSelectedOption={setSelectedOption}
    selectedrelationshipOption={selectedrelationshipOption} setSelectedrelationshipOption={setSelectedrelationshipOption}
    countriesOption={countriesOption}
    setCountriesOption={ setCountriesOption}
    relationshipTypesOption={relationshipTypesOption}
    setRelationshipTypesOption={setRelationshipTypesOption}
    selectedColumnOptions={selectedColumnOptions}
    customSelectOptions={customSelectOptions}
    setCustomSelectOptions={setCustomSelectOptions}
    setSelectedColumnOptions={setSelectedColumnOptions}
    showFilter={showFilter} setShowFilter={setShowFilter}
      />

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults /> :
              <>
              <MainTable handleSortType={handleSortType} columnsData={customSelectOptions} tableData={mainTableDataArray} selectedColumnOptions={selectedColumnOptions} />
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps}/>
                </Flex>
              </>
        }
      </SimpleGrid>
    </Box>
  );
}
