import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,} from "@chakra-ui/react";
  import React, {useMemo, useState} from "react";
  import { useGlobalFilter, usePagination, useSortBy,useTable,} from "react-table";
  import { TriangleUpIcon } from '@chakra-ui/icons'
  import moment from "moment";
  import Card from "components/card/Card";
  export default function MainTable(props) {
    const { columnsData, tableData, handleSortType, selectedColumnOptions, } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const [isHovered, setIsHovered] = useState(false);

    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState} = tableInstance;
    initialState.pageSize = 1000;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (<>
      <Card
        direction='column'
        w='100%'
        p='0px'
        overflow={{ sm: "auto", lg: "auto" }}>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  const isHeaderVisible = selectedColumnOptions.find((option) => option.accessor === column.id)?.isShow;
                  return isHeaderVisible ? (<Th
                    className="table-header"
                    key={index}
                    borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    <Flex className="table-header-text">{column.render("Header")}
                      {["","STREET ADDRESS 1","STREET ADDRESS 2","Created AT","MODIFIED AT"].includes(column.Header) ? null :
                        <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                          <Flex direction={'column'} pl={'0px'}>
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                          </Flex>
                        </span>}
                    </Flex>
                  </Th>
                  ):null;
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    const isLastCell = index === row.cells.length - 1;
                    const isCellVisible = selectedColumnOptions.find((option) => option.accessor === cell.column.id)?.isShow;
                    if (cell.column.Header === "EMAIL ID") {
                      data = (
                        <Flex align='center'>
                          <Text >
                          {cell.value == null ? "-" :cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "TYPE") {
                      data = (
                        <Flex align='center'>
                          <Text
                            me='10px'
                            color={textColor}
                            fontSize='sm'
                            fontWeight='700'>
                            {cell.value == null ? "-" :cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (["DATE OF BIRTH"].includes(cell.column.Header)) {
                      data = (
                        <Text textAlign={'center'} >
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY")}
                        </Text>
                      );
                    } else if (["Created AT","MODIFIED AT"].includes(cell.column.Header)) {
                      data = (
                        <Text textAlign={'center'} >
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY, h:mm A")}
                        </Text>
                      );
                    } else if (cell.column.Header === "POSTAL CODE") {
                      data = (
                        <Text >
                        {cell.value == (null || " " )? "-" :cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "ID Country") {
                      data = (
                        <Text >
                        {cell.value == null ? "-" :cell.value}
                        </Text>
                      );
                    }else if (cell.column.Header === "BUSINESS NAME") {
                      data = (
                        <Text >
                        {cell.value == null ? "-" :cell.value}
                        </Text>
                      );
                    } else{
                      data = (
                        <Text >
                        {cell.value == null ? "-" : cell.value == "" ? '-' : cell.value}
                        </Text>
                      );
                    }
                    return isCellVisible ? (
                      <Td
                        {...cell.getCellProps()}
                        className="table-cell"
                        key={index}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderRight= {isLastCell ? "transparent" : "1px solid"}>
                        {data}
                      </Td>
                    ):"";
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </>);
  }
