//USER 
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const USER_INFO = 'USER_INFO'


//INFO
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SELECTED_APPLICATIONS = 'SELECTED_APPLICATIONS'
export const SELECTED_DECISIONS = 'SELECTED_DECISIONS'
export const SET_ACTIVE_APPS = 'SET_ACTIVE_APPS'


//API_FILTER_VALUES
export const TRANSACTION_CATEGORIES = 'TRANSACTION_CATEGORIES';


