import React, { useState } from 'react';
import { decisionRoutes, decisionRunRoutes } from 'routes.js';
import { Portal, Box, Flex, useColorModeValue, Text, Icon, Button } from '@chakra-ui/react';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import { useSelector, useDispatch, } from 'react-redux';

function FixedHeaderTop(props) {

	const {
		setCustomData,
		setCustomRevenueDetails,
		setSelectedCustomRevenuePopupOptions,
		setInputValue,
		setUpdatedValue,
		setMinPercentage,
		setMaxPercentage,
		setBankBasedInputValue,
		setCodatBasedInputValue,
		setDebtCustomInputValue,
		setBankBasedUpdatedValue,
		setCodatBasedUpdatedValue,
		setTotalBasedUpdatedValue,
		customRevenueEditedValue, customDebtEditedValue, selectedValues, decisionId, setDecisionId, activeAppId, setActiveAppId, runDecision, showRunDecisionBtn } = props;

	let mainText = useColorModeValue('cbrand.500', 'white');

	const { selectedDecisions } = useSelector(state => state.infoReducer)

	const getActiveRoute = (routes) => {
		const activeRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].items && routes[i].items.length > 0) {
				for (let j = 0; j < routes[i].items.length; j++) {
					if (window.location.href.indexOf(routes[i].items[j].layout + routes[i].items[j].path) !== -1) {
						return routes[i].items[j].headerName;
					}
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	};

	return (
		<Portal>
			<Box id={'getHeight'} className='box-fixed-header-container' zIndex={'2'} gap={'20px'} borderBottom={'1px solid rgba(0, 0, 0, 0.20)'}>
				<Flex w='100%' flexDirection={{ sm: 'column', md: 'row' }} alignItems={{ xl: 'center' }} justifyContent={'space-between'}>
					<Box mb={{ sm: '8px', md: '0px' }}>
						<Box mb={{ sm: '8px', md: '0px' }} me={'auto'}>
							<Text as={'span'} color={mainText} bg='inherit' fontWeight='bold' fontSize='28px'  >
								{getActiveRoute(decisionRunRoutes)}
							</Text>

							{selectedDecisions && selectedDecisions.length > 0 && selectedDecisions.map((selectedDecision, index) => {
								if (selectedDecision.application_id === activeAppId) {
									return (
										<Flex gap={'10px'} align={'flex-end'} color={mainText} fontWeight={'bold'} fontSize={'14px'} pt={'5px'}>
											{/* <Text as={'span'}>{` ${selectedDecision.originalApp_id} `}</Text>
											<Text as={'span'} color={'gray.900'} fontSize={'20px'}>|</Text>
											<Text as={'span'}>{` ${selectedDecision.business_name} `}</Text> */}
											{selectedDecision && selectedDecision.originalApp_id && selectedDecision.originalApp_id !== 'null' && (
												<>
													<Text as={'span'}>{` ${selectedDecision.originalApp_id} `}</Text>
												</>
											)}
											{selectedDecision && selectedDecision.originalApp_id && selectedDecision.originalApp_id !== 'null' && selectedDecision.business_name && (
												<>
													<Text as={'span'} color={'gray.900'} fontSize={'20px'}>|</Text>
												</>
											)}
											{selectedDecision && selectedDecision.business_name && (
												<Text as={'span'}>{` ${selectedDecision.business_name} `}</Text>
											)}
										</Flex>
									);
								} else {
									return null;
								}
							})}
						</Box>
					</Box>
					{showRunDecisionBtn ?
						<Box w={'180px'}>
							<Button onClick={() => runDecision()}  isDisabled={customRevenueEditedValue == '-' && customDebtEditedValue == '-'} className='green-btn' px={'50px'} h={'40px'} fontSize={'14px'}>Run Decision</Button>
						</Box> : ""}
				</Flex>
				<FixedSubHeaderTop
				    setCustomData={setCustomData}
					setCustomRevenueDetails={setCustomRevenueDetails}
					setSelectedCustomRevenuePopupOptions={setSelectedCustomRevenuePopupOptions}
					setInputValue={setInputValue}
					setUpdatedValue={setUpdatedValue}
					setMinPercentage={setMinPercentage}
					setMaxPercentage={setMaxPercentage}
					setBankBasedInputValue={setBankBasedInputValue}
					setCodatBasedInputValue={setCodatBasedInputValue}
					setDebtCustomInputValue={setDebtCustomInputValue}
					setBankBasedUpdatedValue={setBankBasedUpdatedValue}
					setCodatBasedUpdatedValue={setCodatBasedUpdatedValue}
					setTotalBasedUpdatedValue={setTotalBasedUpdatedValue}
					decisionId={decisionId} setDecisionId={setDecisionId} activeAppId={activeAppId} setActiveAppId={setActiveAppId} selectedValues={selectedValues} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop