import {Flex,Table,Progress,Icon,Tbody, Td,Text,Th,Thead,Tr,useColorModeValue,Checkbox,} from "@chakra-ui/react";
import { TriangleUpIcon } from '@chakra-ui/icons'
import React, { useMemo, useState } from "react";
import { useGlobalFilter,usePagination,useSortBy,useTable,} from "react-table";
import Card from "components/card/Card";
import moment from "moment";
import { MdCheckCircle, MdCancel, MdOutlineError, MdPending, MdDoNotDisturbOn, MdSearch, MdDoNotDisturb } from "react-icons/md";
import Pagination from "components/pagination/pagination";

export default function MainTable(props) {

  const { columnsData, tableData, onSelectedValuesChange, selectedValues, setSelectedValues, setSorting, handleSortType } = props;

  const columns = useMemo(() => columnsData, [columnsData]);

  const [isHovered, setIsHovered] = useState(false);

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable( {columns, data,}, useGlobalFilter, useSortBy,usePagination);

  const {getTableProps, getTableBodyProps,headerGroups,page, prepareRow,initialState} = tableInstance;

  initialState.pageSize = 500;

  // const handleCheckboxChange = (e, value) => {
  //   const updatedSelectedValues = e.target.checked ? [...selectedValues, value] : selectedValues.filter((item) => item !== value);
  //   if (updatedSelectedValues.length > 3) {
  //     return;
  //   }
  //   setSelectedValues(updatedSelectedValues);
  //   onSelectedValuesChange(updatedSelectedValues);
  // };
  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    const { record_id, application_id, business_name } = row.original;

    let globalSelectedValues = [];

    if (checked) {
      globalSelectedValues = [
        ...selectedValues,
        {
          globalRecordId: record_id,
          globalApplicationId: application_id,
          globalBusinessName: business_name,
        }
      ];
    } else {
      globalSelectedValues = selectedValues.filter(item =>
        item.globalRecordId !== record_id ||
        item.globalApplicationId !== application_id ||
        item.globalBusinessName !== business_name
      );
    }

    setSelectedValues(globalSelectedValues);
    onSelectedValuesChange(globalSelectedValues);
  };






  return (<>
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflowX={{ sm: "scroll", lg: "auto" }}>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  // {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header"
                  key={index}
                  borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                   <Flex className="table-header-text">{column.render("Header")}
                      {[""].includes(column.Header) ? null :
                        <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                          <Flex direction={'column'} pl={'0px'}>
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                          </Flex>
                        </span>}
                    </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const isLastCell = index === row.cells.length - 1;
                  if (cell.column.Header === "APPLICATION ID") {
                    data = (
                      <Flex align='center'>
                        <Text>
                          {cell.value == null ? "-" : cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "MODIFIED AT") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY, h:mm A")}
                      </Text>
                    );
                  } else if (cell.column.Header === "") {
                    data = (
                      <Checkbox
                           isChecked={selectedValues?.some(value =>value.globalRecordId === row.original.record_id &&value.globalApplicationId === row.original.application_id &&value.globalBusinessName === row.original.business_name)}
                          colorScheme='teal'
                          me='0px'
                          // onChange={(e) => handleCheckboxChange(e, cell.value)}
                          onChange={(e) => handleCheckboxChange(e, row)}
                            isDisabled={selectedValues?.length >= 3 && !selectedValues.some(value => value.globalRecordId === row.original.record_id && value.globalApplicationId === row.original.application_id && value.globalBusinessName === row.original.business_name)}
                        />
                    );
                  } else if (cell.column.Header === "LAST DECISION RUN AT") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY, h:mm A")}
                      </Text>
                    );
                  } else if (cell.column.Header === "CREATED AT") {
                    data = (
                      <Text textAlign={'center'}>
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY, h:mm A")}
                      </Text>
                    );
                  } else if (cell.column.Header === "BUSINESS NAME") {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align='center' justify={'flex-start'} >
                        {cell.value ? (
                          <>
                            <Icon
                              w='24px'
                              h='24px'
                              me='5px'
                              p={cell.value === "Manual Review"?'2px' : ""}
                              m={cell.value === "Manual Review"?'2px 7px 2px 2px' : ""}
                              width={cell.value === "Manual Review"? "20px": null}
                              height={cell.value === "Manual Review"? "20px": null}
                               bg={cell.value === "Manual Review"?'rgba(0, 162, 173, 1)': null}
                               borderRadius={cell.value === "Manual Review"?'50%': null}
                              color={
                                cell.value === "Approved"
                                  ? "green.500"
                                  : cell.value === "Declined"
                                    ? "red.500"
                                    : cell.value === "In Progress"
                                      ? "orange.500"
                                      : cell.value === "None"
                                        ? "rgba(0, 0, 0, 0.2)"
                                        : cell.value === "Pending"
                                          ? "rgba(195, 183, 229, 1)"
                                          : cell.value === "Manual Review"
                                            ? "rgba(255,255,255)"
                                          : cell.value === "Failed"
                                            ? "rgba(224, 79, 79, 1)"
                                          : null
                              }
                              as={
                                cell.value === "Approved"
                                  ? MdCheckCircle
                                  : cell.value === "Declined"
                                    ? MdCancel
                                    : cell.value === "In Progress"
                                      ? MdOutlineError
                                      : cell.value === "Pending"
                                        ? MdPending
                                        : cell.value === "Manual Review"
                                        ? MdSearch
                                        : cell.value === "Failed"
                                          ? MdDoNotDisturb
                                        : cell.value === "None"
                                          ? MdDoNotDisturbOn
                                          : null
                              }
                            />
                            <Text >
                              {cell.value}
                            </Text>
                          </>
                        ) : (
                          <Text >
                            -
                          </Text>
                        )}
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text >
                        {cell.value == null ? "-" : cell.value == "" ? '-' : cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      className="table-cell"
                      maxH='30px !important'
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderRight={isLastCell ? "transparent" : "1px solid #E0E8f0"}>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  </>);
}