import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable, } from "react-table";
import Card from "components/card/Card";

export default function ColumnsTable(props) {

  const { titleData, columnsData, tableData, showThead, maxHeightTable, selectedCustomRevenuePopupOptionsIndicator, borderRadius, customFontWeight, customBgColor } = props;

  const title = useMemo(() => titleData, [titleData]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      title,
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState, } = tableInstance;

  initialState.pageSize = 100;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [heights, setHeights] = useState([]);

  const refs = useRef({});

  useEffect(() => {
    const newHeights = Object.keys(refs.current).map(key => {

      const element = refs.current[key];

      return element ? element.clientHeight : 0; 
    });

    setHeights(newHeights);
  }, [refs.current]); 

  return (
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflow={{ sm: "auto", lg: "auto" }}
      {...(borderRadius && { borderRadius })}
      className='decision-summary-table'>
      {title ? <Flex className="table-header" style={{ fontWeight: customFontWeight, backgroundColor: customBgColor }} borderBottom="1px solid">
        <Text className="table-header-text">{title.toUpperCase()} </Text></Flex> : ""}
      <Table {...getTableProps()} variant='simple' color='gray.500' mb={title === 'DUPLICATION RULES'? '0px' : '24px'}>

        {showThead && <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (<>
                <Th
                  key={index}
                  className="table-header"
                  backgroundColor={["nameC", "valueC", "nameD", "valueD"].includes(column.id) ? 'rgba(0, 162, 173, 0.1)' : ""}
                  color={["nameC", "valueC"].includes(column.id) ? 'rgba(0, 162, 173, 1)' : ""}
                  borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}>
                  <Flex className="table-header-text">{column.render("Header")}</Flex>
                </Th>
              </>))}
            </Tr>
          ))}
        </Thead>}
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = cell.value;
                  const isLastCell = index === row.cells.length - 1;
                  if (["CREDITS", "DEBITS", "SALES", "EXPENSES", "BURN", "FINAL", "VALUE", "LOAN CREDITS", "LOAN REPAYMENTS", "CC DEBT", "OD LIMIT", "ECL OUTSTANDING"].includes(cell.column.Header)) {
                    data = (
                      <Text textAlign={'right'}>
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "RULES") {
                    data = (
                      <Text w={"80%"} whiteSpace={"pre-wrap"}>
                        {cell?.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "VALUES" && cell.row.original.rule === 'Bank Account') {
                    data = (
                      <>
                        {cell.row.original.values.length > 0 ? (
                          cell.row.original.values.map((item, id) => (
                            <Flex key={id} align='center' style={{ height: heights[id] || 'auto' }} className="table-nested-row-style" borderBottom={id === cell.row.original.values.length - 1 ? '1px solid transparent' : ''} >
                              <Text key={id} me='10px' fontSize='sm' fontWeight='700'>
                                {item.institution_name} | {item.acc_number}
                              </Text>
                            </Flex>
                          ))
                        ) : (
                          <Text color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );

                  }else if (cell.column.Header === "MATCHING INSTANCES" && cell.row.original.rule === 'Bank Account') {
                    // Destructuring required properties from the original row data
                    const { db_hit_count: dbHitCount, matching_instances: matchingInstances } = cell.row.original;

                    // Initializing an array to store new matching instances
                    let newMatchingInstances = [];

                    // This is the index to track the current position in the matching instances array
                    let instanceIndex = 0;

                    // Iterating over each hit count to construct new matching instances
                    dbHitCount.forEach(hit => {

                      // If hit is 1, pushing a placeholder instance indicating "NA"
                      if (hit === 1) {
                        newMatchingInstances.push([{ business_id: "NA", full_name: "NA", customer_id: "NA" }]);
                      } else {

                        // For hits greater than 1, slice the matchingInstances to get the relevant items
                        const numberOfItems = hit > 1 ? hit - 1 : 0;
                        const items = matchingInstances.slice(instanceIndex, instanceIndex + numberOfItems);
                        instanceIndex += numberOfItems; // Updating the instance index
                        newMatchingInstances.push(items); // Adding the sliced items to newMatchingInstances
                      }
                    });
                    data = (
                      <>
                        {newMatchingInstances.length > 0 ? (
                          newMatchingInstances.map((items, id) => (
                            <Flex
                              key={id}
                              ref={el => (refs.current[`flex_${id}`] = el)}
                              direction="column"
                              align="flex-start"
                              className="table-nested-row-style"
                              borderBottom={id === newMatchingInstances.length - 1 ? "1px solid transparent" : ""}
                            >
                              {items.map((item, itemId) => (
                                <Text key={itemId} me="10px" fontSize="sm" fontWeight="700">
                                  {item.business_id === "NA" ? (
                                    <Text color={"rgba(0, 0, 0, 0.50)"}> NA </Text>
                                  ) : (
                                    <>
                                      {item.business_id} | {item.full_name} | {item.customer_id}
                                    </>
                                  )}
                                </Text>
                              ))}
                            </Flex>
                          ))
                        ) : (
                          <Text color={"rgba(0, 0, 0, 0.50)"} fontWeight="700">
                            NA
                          </Text>
                        )}
                      </>
                    );
                  }else if (cell.column.Header === "MATCHING INSTANCES" && cell.row.original.rule === 'Phone Number') {
                    data = (
                      <>
                        {cell.row.original.matching_instances.length > 0 ? (
                          cell.row.original.matching_instances.map((items, id) => (
                            <Flex key={id} align='center' className="table-nested-row-style" borderBottom={id === cell.row.original.matching_instances.length - 1 ? '1px solid transparent' : ''}>
                            <Flex key={id} align='center'>
                              {items.join(' | ')}
                            </Flex>
                              </Flex>
                          ))
                        ) : (
                          <Text fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );
                  }else if (cell.column.Header === "MATCHING INSTANCES" && cell.row.original.rule === 'Email ID') {
                    data = (
                      <>
                        {cell.row.original.matching_instances.length > 0 ? (
                          cell.row.original.matching_instances.map((items, id) => (
                            <Flex key={id} align='center' className="table-nested-row-style" borderBottom={id === cell.row.original.matching_instances.length - 1 ? '1px solid transparent' : ''}>
                              <Flex key={id} align='center'>
                                {items.join(' | ')}
                              </Flex>
                            </Flex>
                          ))
                        ) : (
                          <Text fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );
                  }else if (cell.column.Header === "MATCHING INSTANCES" && cell.row.original.rule === 'SSN') {

                    data = (
                      <>
                        {cell.row.original.matching_instances.length > 0 ? (
                          cell.row.original.matching_instances.map((items, id) => (
                            <Flex key={id} align='center' className="table-nested-row-style" borderBottom={id === cell.row.original.matching_instances.length - 1 ? '1px solid transparent' : ''}>
                            <Flex key={id} align='center'>
                              {items.join(' | ')}
                            </Flex>
                            </Flex>
                          ))
                        ) : (
                          <Text fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );
                  }else if (cell.column.Header === "MATCHING INSTANCES" && cell.row.original.rule === 'EIN') {
                    data = (
                      <>
                        {cell.row.original.matching_instances.length > 0 ? (
                          cell.row.original.matching_instances.map((items, id) => (
                            <Flex key={id} align='center' className="table-nested-row-style" borderBottom={id === cell.row.original.matching_instances.length - 1 ? '1px solid transparent' : ''}>
                              <Flex key={id} align='center'>
                                {items.join(' | ')}
                              </Flex>
                            </Flex>
                          ))
                        ) : (
                          <Text fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );
                  } else if (cell.column.Header === "DB HIT COUNT" && cell.row.original.rule === 'Bank Account') {

                    data = (
                      <>
                        {cell.row.original.db_hit_count.length > 0 ? (
                          cell.row.original.db_hit_count.map((item, id) => (
                            <Flex key={id} style={{ height: heights[id] || 'auto' }} align='center' className="table-nested-row-style" borderBottom={id === cell.row.original.db_hit_count.length - 1 ? '1px solid transparent' : ''}>
                              <Text key={id} me='10px' fontSize='sm' fontWeight='700'>
                                {item}
                              </Text>
                            </Flex>
                          ))
                        ) : (
                          <Text fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                            NA
                          </Text>
                        )}
                      </>
                    );
                  } else if (cell.column.Header === "ACTUAL VALUE" && cell.row.original.category=== 'Bank Ownership') {
                    data = (
                      <>
                        {Array.isArray(cell.row.original.actual_value) ? (
                          cell.row.original.actual_value?.map((item, id) => (
                            <Flex key={id} align='center'>
                              <Text key={id} me='10px' fontSize='sm' fontWeight='700'>
                                {item?.acc_number ? item.acc_number : <Text as={"span"} fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                                  NA
                                </Text>} | {item?.ownership_type ? item.ownership_type : <Text as={"span"} fontWeight='700' color={"rgba(0, 0, 0, 0.50)"}>
                                  NA
                                </Text>}
                              </Text>
                            </Flex>
                          ))
                        ) : (
                          <Text>
                            {cell.value}
                          </Text>
                        )}
                      </>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      className="table-cell"
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderRight={isLastCell ? "transparent" : "1px solid"}>
                      <Flex align='center'>
                        <Text color={data == "NA" ? "rgba(0, 0, 0, 0.50)" : " "}>{data} </Text>
                      </Flex>
                      {data === "Custom Revenue Estimate" ? <Text whiteSpace={"pre-wrap"} color="rgba(0, 162, 173, 1)">{selectedCustomRevenuePopupOptionsIndicator === 'Select' || selectedCustomRevenuePopupOptionsIndicator === '' ? '' : ('[Updated]  ' + selectedCustomRevenuePopupOptionsIndicator)}</Text> : ""}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
