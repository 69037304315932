import { SELECTED_APPLICATIONS, SET_DATE_FILTER, TRANSACTION_CATEGORIES, SELECTED_DECISIONS, SET_ACTIVE_APPS } from '../types/types'

const initialState = {
    dateTypeFilter: "",
    selectedApplications: [],
    selectedDecisions: [],
    transactionCategories: [],
    activeAppsRedux: [],
}

const infoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATE_FILTER:
            return {
                ...state,
                dateTypeFilter: action.payload,
            }
        case SELECTED_APPLICATIONS:
            return {
                ...state,
                selectedApplications: action.payload,
            }
        case SELECTED_DECISIONS:
            return {
                ...state,
                selectedDecisions: action.payload,
            }
        case TRANSACTION_CATEGORIES:
            return {
                ...state,
                transactionCategories: action.payload,
            }
        case SET_ACTIVE_APPS:
            return {
                ...state,
                activeAppsRedux: action.payload,
            };
        default:
            return state
    }
}

export {
    infoReducer
}

