import React, { useEffect, useState } from 'react';
import { applicationRoutes } from 'routes.js';
import { Portal, Box, useDisclosure, Text, Button, Link, useColorModeValue, Flex, Icon } from '@chakra-ui/react';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import SelectAmount from 'components/popUps/SelectAmount';
import EclCalenderRange from 'components/eclCalender/eclCalenderRange'
import { INVOICE_DUE_DATE, invoiceStatusOptions , invoiceCurrencyOptions} from 'utils/constants';
import { MdOutlineFilterAltOff, MdOutlineFilterAlt } from 'react-icons/md';
import { CgRemoveR } from "react-icons/cg";
import CustomSelect from 'components/customSelect/CustomSelect';
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles';


function FixedHeaderTop(props) {
	const {fromAmt, toAmt, setFromAmt, setToAmt, showFilter, setShowFilter, selectedCurrency, setSelectedCurrency, activeApps,selectedStatus, setSelectedStatus, setActiveApps, selectedValues, search, setSearch, onDateRangeSelect, handleClearFilter,  updateAmountFilterValues, categoryOptions } = props;

	const getActiveRoute = (routes) => {
		const activeRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].items && routes[i].items.length > 0) {
				for (let j = 0; j < routes[i].items.length; j++) {
					if (window.location.href.indexOf(routes[i].items[j].layout + routes[i].items[j].path) !== -1) {
						return routes[i].items[j].headerName;
					}
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	};

	let mainText = useColorModeValue('cbrand.500', 'white');

	const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }

    const handleSelectChange = (selected) => {
		setSelectedStatus(selected);
	};
    const handleCurrencyChange = (selected) => {
		setSelectedCurrency(selected);
	};


	return (
		<Portal>
			<Box id={'getHeight'} className='box-fixed-header-container'>
				<Flex justify={'space-between'} align={'center'} width={'100%'}>
					<Box>
						<Text as={'span'} color={mainText} bg='inherit' fontWeight='bold' fontSize='28px' pb={'5px'}>
							{getActiveRoute(applicationRoutes)}
						</Text>
						<Flex gap={'10px'} align={'flex-end'} color={mainText} fontWeight={'bold'} fontSize={'14px'}>
							{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null' && (
								<>
									<Text as={'span'}>{` ${activeApps.globalApplicationId} `}</Text>
								</>
							)}
							{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null' && activeApps.globalBusinessName && (
								<>
									<Text as={'span'} color={'gray.900'} fontSize={'20px'}>|</Text>
								</>
							)}
							{activeApps && activeApps.globalBusinessName && (
								<Text as={'span'}>{` ${activeApps.globalBusinessName} `}</Text>
							)}
						</Flex>
					</Box>
					<Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
				</Flex>
				{
					showFilter ?
						<Flex justify={'space-between'} gap={'10px'} width={'100%'}>
							<Box flex="2"><SearchBar search={search} setSearch={setSearch} /></Box>
							<Box flex="1"><SelectAmount btnText={'Total Amount'} updateAmountFilterValues={updateAmountFilterValues} handleClearFilter={handleClearFilter} fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt} /></Box>
							<Box flex="1"><EclCalenderRange onDateRangeSelect={onDateRangeSelect} btnText={INVOICE_DUE_DATE} /></Box>
							<Box flex="1">
								<CustomSelect
									options={invoiceStatusOptions}
									value={selectedStatus}
									onChange={handleSelectChange}
									placeholder="Status"
									isSearchable={false}
									isClearable={false}
									customStyles={customStyleSelected}
								/>
							</Box>
							<Box flex="1">
								<CustomSelect
									options={invoiceCurrencyOptions}
									value={selectedCurrency}
									onChange={handleCurrencyChange}
									placeholder="Currency"
									isSearchable={false}
									isClearable={false}
									customStyles={customStyleSelected}
								/></Box>

							<Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
						</Flex> : ""}
				<hr className='divider' />
				<FixedSubHeaderTop activeApps={activeApps} setActiveApps={setActiveApps} selectedValues={selectedValues} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop