import React, { useState, useLayoutEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Box, useColorModeValue, SimpleGrid, Flex, Text, Select, Image, Icon, Button, HStack, Menu, MenuButton, MenuList, MenuItem,Checkbox, MenuDivider} from "@chakra-ui/react";
import FixedHeaderTop from "views/admin/profit&lossStatement/components/FixedTopHeader/FixedHeaderTop";
import MainTable from "./components/MainTable";
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useEffect } from "react";
import { mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import NoResults from "components/errorStates/NoResults";
import { setActiveAppsRedux } from "redux/actions/infoAction";
import { TRANSACTION_DATE } from "utils/constants";
import { customStyleSelected } from "components/customSelect/CustomSelectStyles";
import CustomSelect from "components/customSelect/CustomSelect";
import { useLocation } from 'react-router-dom';
import { TestData } from "./variables/TestData";
import OnlyRangeCalender from 'components/eclCalender/onlyRangeCalender';
import { PL_STATEMENT_MONTH } from "utils/constants";


export default function ProfitLossStatement() {

  const dispatch = useDispatch()

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { selectedApplications,  dateTypeFilter, } = useSelector(state => state.infoReducer)

  const [activeApps, setActiveApps] = useState(""); 

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'codat', label: "Codat" }])

  const [dataSource, setDataSource] = useState([])

  const [showFilter, setShowFilter] = useState(true)

  const [tableTitle, setTableTitle] = useState("")

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case PL_STATEMENT_MONTH:
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      default:
        setStartDate("");
        setEndDate("");
    }
  };


  const handleClearFilter = () => {
    setStartDate("")
    setEndDate("")
  }

  const fetchDetails = () => {
    setLoader(true)
    service.get(`${apiendpoints?.APPLICATIONS}/${activeApps.globalRecordId}/codat/profitandloss?start_date=${startDate}&end_date=${endDate}`).then((res) => {
      const { content, content: { count } } = res
      setContent(content?.pnl_data)
      setTableTitle(content?.last_refreshed_date)
      setError(false)
      setLoader(false)
    }).catch(error => {
      setLoader(false)
      setError(true)
    })
  }

  useEffect(() => {
    setDataSource(dataSourceOptions[0])
  }, [])

  useEffect(() => {
    if (activeApps) {
      fetchDetails()
    }
  }, [activeApps, startDate, endDate])


  useEffect(() => {
    var appsSelected = localStorage.getItem(localStorageKeys?.SELECTED_APPLICATIONS)
    if (appsSelected && (JSON.parse(appsSelected).length > 0)) {
      dispatch(mapSelectedApplications(JSON.parse(appsSelected)))
      setActiveApps(JSON.parse(appsSelected)[0])
      dispatch(setActiveAppsRedux(JSON.parse(appsSelected)[0]));
    }
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height, showFilter]);


  return (
    <Box id={'paddingTop'} pt={"140px"}>
      <FixedHeaderTop showFilter={showFilter} setShowFilter={setShowFilter} activeApps={activeApps} setActiveApps={setActiveApps} onDateRangeSelect={handleDateRangeSelect} handleClearFilter={handleClearFilter} />
      <Flex align={'center'} mb={'20px'} gap={'20px'}>
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}> Select Data Source </Text>
          <CustomSelect
            options={dataSourceOptions}
            value={dataSource}
            onChange={(data) => setDataSource(data)}
            placeholder=""
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            isDisabled={false}
          />
        </Flex>
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}> Select Month Range </Text>
          <Box flex="1"><OnlyRangeCalender onDateRangeSelect={handleDateRangeSelect} btnText={PL_STATEMENT_MONTH} /></Box>
        </Flex>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.length == 0 ? <NoResults dataSource={true}/> :
              <>
                <PrimeReactProvider>
                  <MainTable nodes={content} setNodes={setContent} startDate={startDate} endDate={endDate} tableTitle={tableTitle} />
                </PrimeReactProvider>
              </>
        }
      </SimpleGrid>
    </Box>
  );
}
