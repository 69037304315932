import React from 'react';
import { applicationRoutes } from 'routes.js';
import { Portal, Box, } from '@chakra-ui/react';
import Navbar from 'components/navbar/NavbarPlaidTransaction.js';
import FixedSubHeaderTop from './FixedSubHeaderTop';

function FixedHeaderTop(props) {

	const { categoryHeaderOptions, fromAmt, toAmt, setFromAmt, setToAmt, handleStatusSelectChange, selectedPendingStatus, pendingStatus, showFilter, setShowFilter, activeApps, setActiveApps, selectedValues, search, setSearch, onDateRangeSelect, handleClearFilter, categoryFilter, setCategoryFilter, updateAmountFilterValues, categoryOptions } = props;

	const getActiveRoute = (routes) => {
		const activeRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].items && routes[i].items.length > 0) {
				for (let j = 0; j < routes[i].items.length; j++) {
					if (window.location.href.indexOf(routes[i].items[j].layout + routes[i].items[j].path) !== -1) {
						return routes[i].items[j].headerName;
					}
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	};


	return (
		<Portal>
			<Box id={'getHeight'} zIndex={'1'} className='box-fixed-header-container'>
				<Navbar
					brandText={getActiveRoute(applicationRoutes)}
					categoryOptions={categoryOptions}
					search={search} setSearch={setSearch}
					categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} updateAmountFilterValues={updateAmountFilterValues}
					onDateRangeSelect={onDateRangeSelect} handleClearFilter={handleClearFilter}
					showFilter={showFilter} setShowFilter={setShowFilter}
					handleStatusSelectChange={handleStatusSelectChange} selectedPendingStatus={selectedPendingStatus}
					pendingStatus={pendingStatus}
					fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt}
					categoryHeaderOptions={categoryHeaderOptions}
					activeApps={activeApps}
				/>
				<FixedSubHeaderTop activeApps={activeApps} setActiveApps={setActiveApps} selectedValues={selectedValues} categoryFilter={categoryFilter} />
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop