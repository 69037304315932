import {Box,Image,Text, Heading,Button,Icon} from "@chakra-ui/react";
import { MdKeyboardReturn } from "react-icons/md";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import MainBrandLogo from "assets/ECL-brand-logo.svg"
import Footer from "components/footer/FooterAdmin";
export default function ForgotPassword() {

  return (<>
    <Box p={5}>
      <Image src={MainBrandLogo} alt='brand logo' />
    </Box>
    <Box className="center-with-position-absolute" textAlign={'center'}>
      <Heading> <Text as={'span'} color={'#00A2AD'}> Forgot </Text>Your Password?</Heading>
      <Text fontSize={'1.5rem'} mt={5} mb={8}>Please contact your <Text as={'strong'}> admin </Text>to help reset your password.</Text>
      <NavLink to='/auth/sign-in'>
        <Button className="ecl-border-ui" w={'150px'} leftIcon=<Icon as={MdKeyboardReturn} width='20px' height='20px' color='teal' /> borderRadius={'7px'} borderColor='red' color={'black'} variant='outline'>Go Back
        </Button>
      </NavLink>
    </Box>
    {/* <Box bg={'#F6F6F6'} p={'0px 0px 20px 15px'}>
      <Footer />
    </Box> */}
  </>);
}
