import {Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Icon} from "@chakra-ui/react";
import { TriangleUpIcon } from '@chakra-ui/icons'
import React, { useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Card from "components/card/Card";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { MdKeyboardDoubleArrowRight} from "react-icons/md";
import { getCurrencyValue,} from "utils/helperFunctions/getCurrencyValue";
import { useDispatch, useSelector } from "react-redux";
import { mapSelectedApplications, setActiveAppsRedux } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";


export default function MainTable(props) {

  const { columnsData, tableData, handleSortType, selectedColumnOptions, } = props;

  const columns = useMemo(() => columnsData, [columnsData]);

  const [isHovered, setIsHovered] = useState(false);

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable({columns, data}, useGlobalFilter, useSortBy, usePagination);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState} = tableInstance;

  initialState.pageSize = 50;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const dispatch = useDispatch();

  const handleAppDetails = (record_id) => {
    dispatch(mapSelectedApplications([record_id]))
    localStorage.setItem(localStorageKeys.SELECTED_APPLICATIONS,JSON.stringify([record_id]))
  }

  return (<>
    <Card
      direction='column'
      w='100%'
      p='0px'
      overflowX={{ sm: "auto", lg: "auto" }}
    >
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => {
                const isHeaderVisible = selectedColumnOptions.find((option) => option.accessor === column.id)?.isShow;
                return isHeaderVisible ? (
                  <Th
                    className="table-header"
                    key={index}
                    borderRight={index === headerGroup?.headers?.length - 1 ? "transparent" : "1px solid"}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    <Flex className="table-header-text">{column.render("Header")}
                      {["", "STREET ADDRESS 1", "STREET ADDRESS 2", "DETAILS"].includes(column.Header) ? null :
                        <span style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                          <Flex direction={'column'} pl={'0px'}>
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_asc`)} />
                            <TriangleUpIcon w={2} h={2} color={'rgba(0, 0, 0, 0.2)'} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => handleSortType(`${column.id}_desc`)} />
                          </Flex>
                        </span>}
                    </Flex>
                  </Th>
                ) : null;
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const isLastCell = index === row.cells.length - 1;
                  const isCellVisible = selectedColumnOptions.find((option) => option.accessor === cell.column.id)?.isShow;
                   if (cell.column.Header === "RECORD ID/IDs") {
                    data = (
                      <Flex justifyContent={'space-between'} direction={'column'}>
                        {cell?.value.length > 0 ? (
                          cell?.value.map((appId, id) => (
                            <Text
                              key={id}
                              me='10px'
                              fontSize='sm'
                              fontWeight='700'>
                              {appId?.record_id === "null" ? "-" : appId?.record_id}
                            </Text>
                          ))
                        ) : (
                          <Text  >
                            -
                          </Text>
                        )}
                      </Flex>
                    );
                  } else if (cell.column.Header === "ESTABLISHED DATE") {
                    data = (
                      <Text textAlign={'center'}  >
                        {cell.value == null ? "-" : moment.utc(cell.value).format("DD MMM YYYY")}
                      </Text>
                    );
                  } else if (cell.column.Header === "CUSTOMER NAME") {
                    data = (
                      <Text>
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }else if (cell.column.Header === "DECLARED REVENUE") {
                    data = (
                      <Text textAlign={'right'}>
                      {cell?.value == null || isNaN(cell?.value) ? "-" : getCurrencyValue(Math.round((cell?.value)))}
                      </Text>
                    );
                  } else if (cell.column.Header === "DETAILS") {
                    data = (
                      <Flex align="center" direction={'column'}>
                        {cell?.value.map((appId, id) => (
                          <NavLink   key={id} to='/application/plaid'>
                            {appId?.record_id === "null" ? "-" : <Icon _hover={{ bg: "rgba(0, 162, 173, 0.1)" }}
                              onClick={() => handleAppDetails(appId?.record_id)}
                              as={MdKeyboardDoubleArrowRight}
                              width="20px"
                              height="20px"
                              color="rgba(0, 162, 173, 1)"
                              style={{ cursor: "pointer", me: "5px" }}
                            />}
                          </NavLink>
                        ))}
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text  >
                        {cell.value == null ? "-" : cell.value}
                      </Text>
                    );
                  }
                  return isCellVisible ? (
                    <Td
                      {...cell.getCellProps()}
                      className="table-cell"
                      key={index}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderRight={isLastCell ? "transparent" : "1px solid"}>
                      {data}
                    </Td>
                  ) : "";
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  </>);
}
