import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Box, useColorModeValue, Flex } from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import SignIn from "views/auth/signIn";
import ForgotPassword from "views/auth/forgotPassword";
import { localStorageKeys } from "utils/localStorageKeys";
import { routesConstants } from "utils/routeconstants";


export default function Auth() {

  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoute = () => {
    return window.location.pathname !== "/auth/full-screen-maps";
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const authBg = useColorModeValue("#F7F7F7", "navy.900");
  document.documentElement.dir = "ltr";

  useEffect(() => {
		const token = localStorage.getItem(localStorageKeys.ACCESS_TOKEN)
		if(token){
			window.location.pathname = routesConstants.APPLICATIONS
		}
	},[])

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Box
          bg={authBg}
          float='right'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          {getRoute() ? (
            <Flex mx='auto' minH='100vh' direction={'column'} justify={'space-between'}>
              <Switch>
                <Route path={"/auth" + "/sign-in"}  component={SignIn} />
                <Route path={"/auth" + "/forgot-password"}  component={ForgotPassword} />
                <Redirect from='/auth' to='/sign-in'/>
              </Switch>
            </Flex>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
