import React, { useEffect, useState } from 'react';
import { applicationRoutes } from 'routes.js';
import { Portal, Box, useDisclosure, Text, Button, Link, useColorModeValue, Divider, Flex } from '@chakra-ui/react';
import FixedSubHeaderTop from './FixedSubHeaderTop';
import { useSelector } from 'react-redux';

function FixedHeaderTop(props) {

	const {showFilter, setShowFilter, activeApps, setActiveApps, selectedValues, onDateRangeSelect, handleClearFilter} = props;

	const getActiveRoute = (routes) => {
		const activeRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].items && routes[i].items.length > 0) {
				for (let j = 0; j < routes[i].items.length; j++) {
					if (window.location.href.indexOf(routes[i].items[j].layout + routes[i].items[j].path) !== -1) {
						return routes[i].items[j].headerName;
					}
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	};

	let mainText = useColorModeValue('cbrand.500', 'white');

	return (
		<Portal>
			<Box id={'getHeight'} zIndex={'1'} className='box-fixed-header-container'>
				<Flex justify={'space-between'} align={'center'} width={'100%'}>
					<Box>
						<Text color={mainText} bg='inherit' fontWeight='bold' fontSize='28px' pb={'5px'}>
							{getActiveRoute(applicationRoutes)}
						</Text>
						<Flex gap={'10px'} align={'flex-end'} color={mainText} fontWeight={'bold'} fontSize={'14px'}>
							{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null' && (
								<>
									<Text as={'span'}>{` ${activeApps.globalApplicationId} `}</Text>
								</>
							)}
							{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null' && activeApps.globalBusinessName && (
								<>
									<Text as={'span'} color={'gray.900'} fontSize={'20px'}>|</Text>
								</>
							)}
							{activeApps && activeApps.globalBusinessName && (
								<Text as={'span'}>{` ${activeApps.globalBusinessName} `}</Text>
							)}
						</Flex>
					</Box>

				</Flex>
				<hr className='divider' />
				<FixedSubHeaderTop activeApps={activeApps} setActiveApps={setActiveApps} selectedValues={selectedValues}/>
			</Box>
		</Portal>
	)
}

export default FixedHeaderTop