import React from 'react'
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'assets/css/customStyle/EclCalender.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch } from 'react-redux';
import { MdKeyboardArrowDown,} from "react-icons/md";
import { Menu, MenuButton, MenuList, MenuItem, Button, HStack, Box, Text, Flex, Icon} from '@chakra-ui/react'
import {setDateFilter} from 'redux/actions/infoAction'
import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, startOfYear, endOfYear, addYears, format} from 'date-fns';
import { useState } from 'react';

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOfLastSixtyDay: startOfDay(addDays(new Date(), -60)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: 'Or Choose',
            range: () => (""),
        },
        {
            label: 'Today',
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last 7 Days',
            range: () => ({
                startDate: defineds.startOfLastSevenDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: defineds.startOfLastThirtyDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Last 90 Days',
            range: () => ({
                startDate: defineds.startOfLastSixtyDay,
                endDate: defineds.endOfToday
            })
        }
 
    ];
    return customDateObjects;
};


export default function EclCalenderRange({btnText, onDateRangeSelect}) {

    const dispatch = useDispatch() 

    const handleDateFilterType = () =>{dispatch(setDateFilter(btnText))}

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
            color: 'rgba(0, 0, 0, 0.07)'
        }
    ]);

    const sideBar = sideBarOptions();

    const staticRanges = [ ...createStaticRanges(sideBar)];

    const selectedStartDate = format(state[0].startDate, 'd MMM yyyy');

    const selectedEndDate = format(state[0].endDate, 'd MMM yyyy');

    const handleApplyFilter = () => {
        const selectedStartDate = format(state[0].startDate, 'yyyy-MM-dd');
        const selectedEndDate = format(state[0].endDate, 'yyyy-MM-dd');
        onDateRangeSelect(selectedStartDate, selectedEndDate);
      };


    return (<>
        <Menu flip={false} placement="bottom-end">
            <MenuButton textAlign={'left'} w={'100%'} fontSize='13px' fontWeight='600' color='rgba(0, 0, 0, 0.9)' as={Button} rightIcon={<Icon as={MdKeyboardArrowDown} width="17.5px" height="17.5px"/>} className='ecl-border-ui' variant='outline' onClick={handleDateFilterType} >
                {btnText}
            </MenuButton>
            <MenuList p={5}>
                <Box>
                    <Text color='#00A2AD' fontSize='14px' fontWeight={'700'}>Select Date Range</Text>
                    <DateRangePicker className='date-range-picker-container'
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        staticRanges={staticRanges}
                        showDateDisplay={false} />
                    <Flex align={'center'} justify={'space-between'} pt={'2'}>
                        <Text color='rgba(0, 0, 0, 0.4)' fontWeight={'600'} fontSize={'14px'}>Date Range Selected: {selectedStartDate} - {selectedEndDate}</Text>
                        <HStack justifyContent={'flex-end'} gap={3}>
                            {/* <Button minW={'150px'} className='red-border-btn'>Close</Button> */}
                            <MenuItem minW={'150px'} justifyContent={'center'} className='green-btn' onClick={handleApplyFilter}>Apply Filter</MenuItem>
                        </HStack>
                    </Flex>
                </Box>
            </MenuList>
        </Menu>
    </>
    );
}

