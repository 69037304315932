// Chakra imports
import { Box, Flex, Icon, Text, Image, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import FullBrandLogo from "assets/logo-ecl-full.svg"

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;

  const textColor = useColorModeValue("#00A2AD", "rgba(0, 162, 173, 1)");

  return (
    <Flex h='max-content'>
      <Flex w='100%' mx='auto' pt={{ sm: "50px", md: "0px" }} px={{ lg: "30px", xl: "0px" }} p={{ xl: "40px" }} justifyContent='start' direction='column'>
        <Flex direction={'column'} position={'absolute'} top={'55%'} left={'50%'} transform={'translate(-50%, -50%)'} >
          <Box mb='3'> <Image marginLeft={'160px'} w='40' src={FullBrandLogo} alt='ecl brand logo' /> </Box>
          <Flex justify={'space-around'} gap={'50px'}>
            {children}
            <Box display={{ base: "none", md: "block" }} w={{ lg: "50vw", "2xl": "44vw" }} right='0px'>
              <Text fontSize={'50px'} align={'center'}><Text as={'span'} color={textColor}>Risk </Text>Decision Engine</Text>
              <Image height={'75%'} src={illustrationBackground} alt='sign in illustration' />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
