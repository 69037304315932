import React from 'react'
import { Button, Box, Breadcrumb, Select, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
// import { CloseIcon } from '@chakra-ui/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-red.svg';
import {useDispatch, useSelector } from 'react-redux';
import { mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";

export default function FixedSubHeaderTop({ activeApps, setActiveApps }) {

    const dispatch = useDispatch()

    const { selectedApplications } = useSelector(state => state.infoReducer)

    const handleAppClick = (app) => {
            setActiveApps(app);
      };

    const removeApp = (appToRemove) => {
        const updatedApps = selectedApplications.filter((selectedApp) => selectedApp.globalRecordId !== appToRemove.globalRecordId);

        let newActiveApps = "";
        if (activeApps.globalRecordId === appToRemove.globalRecordId) {
            newActiveApps = updatedApps.length >= 1 ? updatedApps[0] : selectedApplications[0];
        } else {
            newActiveApps = activeApps;
        }

        dispatch(mapSelectedApplications(updatedApps));
        setActiveApps(newActiveApps);
        localStorage.setItem(localStorageKeys.SELECTED_APPLICATIONS, JSON.stringify(updatedApps));
      };

    return (
        <>
            <Flex justify={'space-between'} width={'100%'} align={'center'}>
                {selectedApplications && selectedApplications?.length <= 0 ?
                    <Flex>
                        <Text as={'span'} color={'gray.500'}>
                            Select any 3 Applications to View Details
                        </Text>
                    </Flex> :
                    <Flex align={'center'} gap={'10px'}>
                        {
                            selectedApplications && selectedApplications?.map((selectedValue, index) => {
                                return <Box key={index} className={selectedValue.globalRecordId === activeApps.globalRecordId  ? 'selected-value-box-ui' : 'not-selected-value-box-ui'} onClick={() => handleAppClick(selectedValue)}>
                                    <Flex h={'40px'}   p={'4px 10px'} gap={'5px'} align={'center'} justify={'space-between'} >
                                        <Text flex={'1'} textAlign={'center'} as={'span'}>
                                        {selectedValue.globalRecordId}
                                        </Text>
                                        {selectedApplications.length > 1 && (
                                            <CloseIcon cursor={'pointer'} width={'15px'} height={'15px'} onClick={(e) => { e.stopPropagation(); removeApp(selectedValue)}}/>
                                        )}
                                    </Flex>
                                </Box>
                            })
                        }
                    </Flex>}
            </Flex>
        </>
    )
}
