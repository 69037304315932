import React, { Component } from 'react';
import { localStorageKeys } from '../localStorageKeys';


export default function(ComposedComponent) {
    class Authentication extends Component {     
        componentDidMount() {
            var authToken = localStorage.getItem(localStorageKeys.ACCESS_TOKEN)
            if(!authToken){
                this.props.history.push("/auth/sign-in");
            }
        }
        render() {
            return <ComposedComponent {...this.props} />
        }
    }
    
   return (Authentication);

}