import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Select, Icon, Image, Button} from "@chakra-ui/react";
import { MdNavigateBefore, MdNavigateNext, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowDown} from "react-icons/md";
import React, { useMemo } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";

export default function Pagination({
    paginationProps
}) {

    const { totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
        previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage } = paginationProps

    const handleResultsPerPageChange = (event) => {
        const newResultsPerPage = parseInt(event.target.value);
        setResultsPerPage(newResultsPerPage);
        setCurrentPage(1);

    };

    const handleFirstPageClick = () => {
        setCurrentPage(1);
    };

    const handleLastPageClick = () => {
        setCurrentPage(totalPage);
    };

    const handlePreviousPageClick = () => {
        if (currentPage > 1) {
            setCurrentPage(previousPage);
        }
    };
    const handleNextPageClick = () => {
        if (currentPage < totalPage) {
            setCurrentPage(nextPage);
        }
    };


    return (
        <Flex className="pagination-container" alignItems={'center'} gap={'20px'} px="0px" my="16px" whiteSpace={'nowrap'}>
            <Flex alignItems={'center'} gap={'10px'}>
                <Text>Results per Page</Text>
                <Select icon={<MdKeyboardArrowDown/>} _hover={{border: '1px solid #00a2ad'}}  _focus={{boxShadow: '0 0 0 1px #00A2AD'}} iconSize={'17.5px'} fontSize={'13px'} fontWeight={'600'}  className="ecl-border-ui" h={'40px'} value={resultsPerPage} onChange={handleResultsPerPageChange} >
                    {resultsPerPageList && resultsPerPageList.map(pageSize => (
                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                    ))}
                </Select>
            </Flex>
            <Flex align={'center'} gap={'20px'} bg={'white'} h={'40px'} p={'5px 15px'} borderRadius={'0.375rem'} className="ecl-border-ui">
                <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleFirstPageClick} className={`button ${currentPage === 1 ? 'disabled' : ''}`}><Icon as={MdKeyboardDoubleArrowLeft} width='22px' height='22px' color='rgba(0, 0, 0, 0.20)' /> </button><span className="vertical-divider-line">&#124;</span>

                <button style={{ display: 'flex', alignItems: 'center' }} onClick={handlePreviousPageClick} className={`button ${previousPage == null ? 'disabled' : ''}`}> <Icon as={MdNavigateBefore} width='22px' height='22px' color='rgba(0, 0, 0, 0.20)' /> </button><span className="vertical-divider-line">&#124;</span>

                <Text fontWeight={'600'} fontSize={'13px'} as={'span'}>{currentPage} of {totalPage}</Text><span className="vertical-divider-line">&#124;</span>

                <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleNextPageClick} className={`button ${nextPage === null ? 'disabled' : ''}`}> <Icon as={MdNavigateNext} width='22px' height='22px' color='rgba(0, 0, 0, 0.20)' />  </button><span className="vertical-divider-line">&#124;</span>

                <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleLastPageClick} className={`button ${currentPage === totalPage ? 'disabled' : ''}`}> <Icon as={MdKeyboardDoubleArrowRight} width='22px' height='22px' color='rgba(0, 0, 0, 0.20)' /> </button>
            </Flex>
        </Flex>
    );
}

