import { useState, useEffect } from 'react';
import { Box, Flex, Stack, Button, Icon, Text } from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import Brand from "components/sidebarSubMenuOne/components/Brand";
import Links from "components/sidebarSubMenuOne/components/Links";
import {ReactComponent as Logout } from "assets/icons/log-out.svg"
import React from "react";
import {MdKeyboardReturn, MdOutlineFileDownload, MdCancel} from "react-icons/md";
import Footer from "components/footer/FooterAdmin";
import { handleLogout } from "utils/helperFunctions/logout";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { mapSelectedDecisions, mapSelectedApplications } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import {MdOutlineLogout} from "react-icons/md";
import { apiendpoints } from "utils/apiendpoints";
import service from "utils/service";
import { ToastContainer, toast } from 'react-toastify';
import { CheckCircleIcon } from '@chakra-ui/icons'

function SidebarContent(props) {

  const { routes, decisionTransaction } = props;

  const history = useHistory()

  const dispatch = useDispatch()

  const location = useLocation();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [visitedPagesSearchParameter, setVisitedPagesSearchParameter] = useState([]);

  useEffect(() => {
    setVisitedPagesSearchParameter((prevPages) => [...prevPages, location.search]);
  }, [location.search]);

  // const path =
  // location.pathname === '/decision/summary' && location.search === '?type=plaid' ? '/application/plaid' :
  // location.pathname === '/decision/summary' ? '/decision-runs' :
  // location.pathname === '/decision/transaction' ? '/decision-runs' :'/applications';

  const path =
  visitedPagesSearchParameter.includes('?type=plaid') ? '/application/plaid' :
  location.pathname === '/decision/summary' && location.search === '?type=plaid' ? '/application/plaid' :
  location.pathname === '/decision/summary' ? '/decision-runs' :
  location.pathname === '/decision/transaction' ? '/decision-runs' :
  location.pathname === '/decision/accounting/balance-sheet' ? '/decision-runs' :
  location.pathname === '/decision/accounting/pl-statement' ? '/decision-runs' :
  location.pathname === '/decision/accounting/invoices' ? '/decision-runs' :'/applications';



const clickGoBack = (bybrowser = false) => {
  dispatch(mapSelectedDecisions([]));
  localStorage.setItem(localStorageKeys.SELECTED_DECISIONS, JSON.stringify([]));
  const pageKey = location.pathname;
  localStorage.removeItem(`subMenuStates-${pageKey}`);

  if (bybrowser) {
    history.push(path);
  }
};


const fetchDetails = () => {
  service.get(`${apiendpoints?.DECISIONS_TRANSACTION}/${decisionTransaction.decisionId}/export/transactions?search=${decisionTransaction.search}&category=${decisionTransaction.category ? decisionTransaction.category : ''}&trans_type=${decisionTransaction.transType ? decisionTransaction.transType : ''}&pending_status=${decisionTransaction.pendingStatus ? decisionTransaction.pendingStatus : ""}&start_amount=${decisionTransaction.startAmount}&end_amount=${decisionTransaction.endAmount}&trans_date_start=${decisionTransaction.transDateStart}&trans_date_end=${decisionTransaction.transDateEnd}&data_source=${decisionTransaction.dataSource ? decisionTransaction.dataSource : ""}`).then((res) => {
    const { message } = res

    toast.success(`${message}`, {
      toastId: "successInDecisionTransactionsDownload",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      icon: <Icon as={CheckCircleIcon} width='20px' height='20px' color={'#00A2AD'} cursor={'pointer'} />
    });

  }).catch(error => {

    toast.error("Error Notification !", {
      toastId: "errorInDecisionTransactionsDownload",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      icon: <Icon as={MdCancel} width='24px' height='24px' color={'rgba(224, 79, 79, 1)'} cursor={'pointer'} />
    });

  })
}


useEffect(() => {
  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

useEffect(() => {
}, [visitedPagesSearchParameter]);



useEffect(() => {
  let url = window.location.pathname + window.location.search
  window.history.pushState(null, null, url);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    clickGoBack(true);
  };
  window.addEventListener('popstate', onBackButtonEvent);
  return () => {
    window.removeEventListener('popstate', onBackButtonEvent);
  };
}, []);

  return (
    <Box height={'calc(100vh - 0px)'} bg={'white'} borderBottomRightRadius='15px'>
      <Flex direction='column' height={'calc(100% - 30px)'} pt='25px' ps="15px" pe={'20px'}>
        <Brand />
        <Stack direction='column' mb='auto' mt='8px' h={'100%'} justify={'space-between'}>
          <Box ps='0px' maxH={screenHeight < 700 ? '240px' : ''} overflow={'auto'}>
            <Text pl={'5px'} pb={'10px'} textDecoration={'underline'} fontWeight={'700'} fontSize={'1rem'} color={'rgba(0, 162, 173, 1)'}>Decision Run</Text>
            <Links routes={routes} />
          </Box>
          <Flex direction={'column'} gap={'20px'} justify={'flex-end'}>
            <Button isDisabled ={location.pathname !== '/decision/transaction' } className={`ecl-border-ui download-btn`}  w={'100%'} leftIcon=<Icon as={MdOutlineFileDownload} width='20px' height='20px' color='rgba(0, 162, 173, 1)' /> onClick={() => fetchDetails()} >Download Data
            </Button>
            <Flex alignItems={'center'} gap={'8px'} justifyContent={'space-between'}>
              <NavLink to={path} style={{ flex: '1' }}>
                <Button className="ecl-border-ui go-back-btn" w={'100%'} leftIcon=<Icon as={MdKeyboardReturn} width='20px' height='20px' color='rgba(0, 162, 173, 1)' /> onClick={() => clickGoBack()} >Back
                </Button>
              </NavLink>
              <NavLink to='/auth/sign-in' style={{ flex: '1' }}>
                <Button className={'logout-btn'} onClick={() => handleLogout(history, dispatch)} w={'100%'} leftIcon={<Icon as={MdOutlineLogout} width="20px" height="20px" color="rgba(224, 79, 79, 1)" />} _hover={'nohover'}>Log Out
                </Button>
              </NavLink>
            </Flex>
          </Flex>
        </Stack>
      </Flex>
      {/* <ToastContainer/> */}
    </Box>
  );
}

export default SidebarContent;
