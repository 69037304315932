export const valueChecksWithDollar = (value) => {
    switch (true) {
        case value === 0:
            return '$0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '$0' :  withDollarWithoutDecimal(value);
    }
}
export const valueChecksWithoutDollar = (value) => {
    switch (true) {
        case value === 0:
            return '0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '0' :  withoutDollarWithoutDecimal(value);
    }
}
export const valueChecksWithoutDollarWithoutDecimal = (value) => {
    switch (true) {
        case value === 0:
            return '0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '0' :  withoutDollarWithoutDecimal(value);
    }
}
export const valueChecksWithoutDollarWithDecimal = (value) => {
    switch (true) {
        case value === 0:
            return '0';
        case value === null || isNaN(value):
            return '-';
        default:
            const roundedValue = Math.round(value);
            return Math.abs(roundedValue) < 1e-10 ? '0' :  withoutDollarWithDecimal(value);
    }
}


export const withDollarWithDecimal = (data) => {
    return data ? "$ " + data.toLocaleString("en-US") : ""
}


export const withDollarWithoutDecimal = (data) => {
    return data ? "$ " + Math.round(data).toLocaleString("en-US") : ""
}


export const withoutDollarWithDecimal = (data) => {
    return data ? data.toLocaleString("en-US") : ""
}


export const withoutDollarWithoutDecimal = (data) => {
    return data ? Math.round(data).toLocaleString("en-US") : ""
}


export const withPercentWithDecimal = (data) => {
    return data ?  data + " %" : ""
}


export const withPercentWithoutDecimal = (data) => {
    return data ?  Math.round(data) + " %" : ""
}