// Chakra Imports
import { Button, Box, Breadcrumb, Select, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue, Icon, Divider } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import SelectAmount from 'components/popUps/SelectAmount';
import { pendingStatus } from 'utils/constants'
import EclCalenderRange from 'components/eclCalender/eclCalenderRange'
import { TRANSACTION_DATE } from 'utils/constants';
import { MdOutlineFilterAltOff, MdOutlineFilterAlt } from 'react-icons/md';
import { CgRemoveR } from "react-icons/cg";
import { useSelector } from 'react-redux';
import CustomSelect from 'components/customSelect/CustomSelect';
import { customStyleSelected } from 'components/customSelect/CustomSelectStyles';


export default function NavbarPlaidTransaction(props) {

	const [scrolled, setScrolled,cate] = useState(false);

	const { activeApps, categoryHeaderOptions, fromAmt, toAmt, setFromAmt, setToAmt, handleStatusSelectChange, selectedPendingStatus, pendingStatus, showFilter, setShowFilter, secondary, message, brandText, search, setSearch, handleClearFilter, onDateRangeSelect, categoryFilter, setCategoryFilter, updateAmountFilterValues, categoryOptions } = props;

	let mainText = useColorModeValue('cbrand.500', 'white');


	const showHideToggle = () => {
		setShowFilter(!showFilter);
	  }

	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const handleSelectChange = (data) => {
		setCategoryFilter(data)
	}


	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});


	return (<>
		<Flex justify={'space-between'} align={'center'} width={'100%'}>
			<Box mb={{ sm: '8px', md: '0px' }}>
				<Link color={mainText} href='#' bg='inherit' borderRadius='inherit' fontWeight='bold' fontSize='28px' _hover={{ color: { mainText } }} _active={{ bg: 'inherit', transform: 'none', borderColor: 'transparent' }} _focus={{ boxShadow: 'none' }}  pb={'5px'}>
					{brandText}
				</Link>
				<Flex gap={'10px'} align={'flex-end'} color={mainText} fontWeight={'bold'} fontSize={'14px'}>
					{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null' && (
						<>
							<Text as={'span'}>{` ${activeApps.globalApplicationId} `}</Text>
						</>
					)}
					{activeApps && activeApps.globalApplicationId && activeApps.globalApplicationId !== 'null'  && activeApps.globalBusinessName && (
						<>
							<Text as={'span'} color={'gray.900'} fontSize={'20px'}>|</Text>
						</>
					)}
					{activeApps && activeApps.globalBusinessName && (
						<Text as={'span'}>{` ${activeApps.globalBusinessName} `}</Text>
					)}
				</Flex>
			</Box>
			<Box w={'126px'}><Button onClick={showHideToggle} fontSize={'13px'} w={'100%'} color="rgba(0, 162, 173, 1)" className='ecl-border-ui' leftIcon={showFilter ? <Icon as={MdOutlineFilterAltOff} width="18px" height="18px" /> : <Icon as={MdOutlineFilterAlt} width="18px" height="18px" />}> {showFilter ? "Hide Filters" : "Show Filters"} </Button></Box>
		</Flex>

		{
			showFilter ? <Flex justify={'space-between'} gap={'10px'} width={'100%'}>
			<Box flex="2"><SearchBar search={search} setSearch={setSearch} mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} /></Box>
			<Box flex="1"><SelectAmount btnText={'Amount'} updateAmountFilterValues={updateAmountFilterValues} fromAmt={fromAmt} toAmt={toAmt} setFromAmt={setFromAmt} setToAmt={setToAmt}  /></Box>
			<Box flex="1">
				<CustomSelect
					options={categoryHeaderOptions}
					value={categoryFilter}
					onChange={handleSelectChange}
					placeholder="Category"
					isSearchable={false}
					isClearable={false}
					customStyles={customStyleSelected}
				/>
			</Box>
			<Box flex="1">   <CustomSelect
                options={pendingStatus}
                value={selectedPendingStatus}
                onChange={handleStatusSelectChange}
                placeholder="Pending Status"
                isSearchable={false}
                isClearable={false}
                customStyles={customStyleSelected}
              /></Box>
			<Box flex="1"><EclCalenderRange onDateRangeSelect={onDateRangeSelect} btnText={TRANSACTION_DATE} /></Box>
			<Box w={'126px'}><Button onClick={handleClearFilter} fontSize={'13px'} w={'100%'} color="rgba(224, 79, 79, 1)" className='ecl-border-ui' leftIcon={<Icon as={CgRemoveR} width="15px" height="15px" />}>Clear Filters</Button></Box>
		</Flex> : ""
		}

		<hr className='divider' />
	</>);
}

NavbarPlaidTransaction.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
