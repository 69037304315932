import React, { useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Box, useColorModeValue, SimpleGrid, Flex, Text} from "@chakra-ui/react";
// import FixedHeaderTop from "views/admin/balanceSheet/components/fixedHeaderBalanceSheet/FixedHeaderTop";
import FixedHeaderTop from "components/fixedHeaderDecisionExtend/FixedHeaderTop";
import MainTable from "./components/MainTable";
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useEffect } from "react";
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useDispatch, useSelector } from "react-redux";
import service from "utils/service";
import { apiendpoints } from "utils/apiendpoints";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import NoResults from "components/errorStates/NoResults";
import { setActiveAppsRedux } from "redux/actions/infoAction";
import { customStyleSelected } from "components/customSelect/CustomSelectStyles";
import CustomSelect from "components/customSelect/CustomSelect";
import { useLocation } from 'react-router-dom';
import { TestData } from "./variables/TestData";
import OnlyRangeCalender from 'components/eclCalender/onlyRangeCalender';
import { BALANCESHEET_MONTH } from "utils/constants";
import Period from "components/period/Period";
import moment from "moment";


export default function BalanceSheet() {

  const dispatch = useDispatch()

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const { selectedDecisions, transactionCategories, dateTypeFilter } = useSelector(state => state.infoReducer)

  const [activeApps, setActiveApps] = useState("");

  const [decisionId, setDecisionId] = useState("");

  const [activeAppId, setActiveAppId] = useState("");

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [dataSourceOptions, setDataSourceOptions] = useState([{ value: 'codat', label: "Codat" }])

  const [dataSource, setDataSource] = useState([])

  const [showFilter, setShowFilter] = useState(true)

  const [months, setMonths] = useState('')

  const [decisionRunDate, setDecisionRunDate] = useState('')

  const [tableTitle, setTableTitle] = useState("")


  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case BALANCESHEET_MONTH:
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      default:
        setStartDate("");
        setEndDate("");
    }
  };


  const handleClearFilter = () => {
    setStartDate("")
    setEndDate("")
  }

  const fetchDetails = () => {
    setLoader(true)
    // service.get(`${apiendpoints?.DECISIONS}/${decisionId}/codat/balance-sheet?bs_start_date=${startDate}&bs_end_date=${endDate}`).then((res) => {
    service.get(`${apiendpoints?.DECISIONS}/${decisionId}/codat/balance-sheet?bs_start_date=${startDate}&bs_end_date=${endDate}`).then((res) => {
      const { content, content: { count } } = res

      let periodMonths = content?.period_of_study
      setMonths(periodMonths)
      let decRunDate = content?.decision_run_date == null ? "-" : content?.decision_run_date
      setDecisionRunDate(moment.utc(decRunDate).format("DD MMM YYYY,  h:mm A"))
      setContent(content?.balance_sheet_data)
      setTableTitle(content?.last_refreshed_date)
      setError(false)
      setLoader(false)
    }).catch(error => {
      setLoader(false)
      setError(true)
    })
  }


  useEffect(() => {
    setDataSource(dataSourceOptions[0])
  }, [])

  useEffect(() => {
    if (activeAppId && decisionId) {
      fetchDetails()
    }
  }, [startDate, endDate, decisionId, activeAppId ])


  useEffect(() => {
    setActiveAppId(selectedDecisions && selectedDecisions[0]?.application_id)
    setDecisionId(selectedDecisions[0]?.decision_ids[0])
  }, [])

  useEffect(() => {
    var decisionSelected = localStorage.getItem(localStorageKeys?.SELECTED_DECISIONS)
    if (decisionSelected && (JSON.parse(decisionSelected).length > 0)) {
      dispatch(mapSelectedDecisions(JSON.parse(decisionSelected)))
      setActiveAppId(JSON.parse(decisionSelected)[0]?.application_id)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, selectedDecisions.length]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height]);

  return (<>
    <Box id="paddingTop" pt={"140px"}>
      <FixedHeaderTop decisionId={decisionId} setDecisionId={setDecisionId} activeAppId={activeAppId} setActiveAppId={setActiveAppId} showFilter={showFilter} setShowFilter={setShowFilter} activeApps={activeApps} setActiveApps={setActiveApps} onDateRangeSelect={handleDateRangeSelect} handleClearFilter={handleClearFilter}/>
      <Box my={'28px'}> <Period  months={months} decisionRunDate={decisionRunDate} /></Box>
      <Flex align={'center'} my={'20px'} gap={'20px'}>
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}> Select Data Source </Text>
          <CustomSelect
            options={dataSourceOptions}
            value={dataSource}
            onChange={(data) => setDataSource(data)}
            placeholder=""
            isSearchable={false}
            isClearable={false}
            customStyles={customStyleSelected}
            isDisabled={false}
          />
        </Flex>
        <Flex align={'center'} gap={'20px'} >
          <Text as={'span'} className={'label-select'}>Select Month Range</Text>
          <Box flex="1"><OnlyRangeCalender onDateRangeSelect={handleDateRangeSelect} btnText={BALANCESHEET_MONTH} /></Box>
        </Flex>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.length == 0 || decisionId == undefined ?  <NoResults dataSource={true}/> :
              <>
                <PrimeReactProvider>
                  <MainTable nodes={content} setNodes={setContent} startDate={startDate} endDate={endDate} tableTitle={tableTitle}  />
                </PrimeReactProvider>
              </>
        }
      </SimpleGrid>
    </Box>
    </>);
}
