export const columnsDataComplex = [
  {
    Header: "CUSTOMER ID",
    accessor: "customer_id",
    isShow: true
  },
  {
    Header: "CUSTOMER NAME",
    accessor: "full_name",
    isShow: true
  },
  {
    Header: "BUSINESS NAME",
    accessor: "business_name",
    isShow: true
  },
  {
    Header: "EMAIL ID",
    accessor: "email",
    isShow: true
  },
  {
    Header: "TYPE",
    accessor: "person_type",
    isShow: true
  },
  {
    Header: "DATE OF BIRTH",
    accessor: "date_of_birth",
    isShow: true
  },
  {
    Header: "STREET ADDRESS 1",
    accessor: "street_address_1",
    isShow: true
  },
  {
    Header: "STREET ADDRESS 2",
    accessor: "street_address_2",
    isShow: true
  },
  {
    Header: "POSTAL CODE",
    accessor: "postal_code",
    isShow: true
  },
  {
    Header: "CITY",
    accessor: "city",
    isShow: true
  },
  {
    Header: "State/Province",
    accessor: "state_province",
    isShow: true
  },
  {
    Header: "Country",
    accessor: "country",
    isShow: true
  },
  {
    Header: "ID Type",
    accessor: "identification_type",
    isShow: true
  },
  {
    Header: "ID Number",
    accessor: "identification_number",
    isShow: true
  },
  {
    Header: "ID Country",
    accessor: "identification_country",
    isShow: true
  },
  {
    Header: "Created AT",
    accessor: "created_at",
    isShow: true
  },
  {
    Header: "MODIFIED AT",
    accessor: "modified_at",
    isShow: true
  },
];
